import { Box } from '@mui/system'
import { Filter } from '../../../types/Filter'
import { FilterCriteria } from '../../../types/FilterCriteria'
import FilterCriteriaListItem from './FilterCriteriaListItem'

export type FilterCriteriaListProps = {
  filter: Filter
  handleEdit: (criteria: FilterCriteria) => void
  handleDelete: (criteria: FilterCriteria) => void
  loading: boolean
  editable?: boolean
}

const FilterCriteriaList = ({
  filter,
  handleEdit,
  handleDelete,
  loading,
  editable = true
}: FilterCriteriaListProps) => {
  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      {filter?.criteria?.map((crit: FilterCriteria) => (
        <Box
          key={crit.id}
          sx={{
            borderRadius: '10px',
            boxShadow: 4
          }}
        >
          <FilterCriteriaListItem
            criteria={crit}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            loading={loading}
            filter_type={filter.type}
            editable={editable}
          />
        </Box>
      ))}
    </Box>
  )
}

export default FilterCriteriaList
