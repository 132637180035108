import {
  SubscribeOptions,
  SubscriptionHandler
} from 'dcrf-client/lib/interface'
import { SubscriptionPromise } from 'dcrf-client/lib/subscriptions'
import { merge } from 'lodash'
import { useState } from 'react'
import { useWebsockets } from '../providers/websockets'

export type EntityPaginateParamsPager = {
  page: number
  pageSize: number
  count?: number
  hasNext?: boolean
  hasPrevioues?: boolean
}

export type EntityPaginateParams = {
  pager?: EntityPaginateParamsPager
  order?: string[]
  filters?: any
  search?: any
}

export type EntityPaginateResult<T> = {
  pager: EntityPaginateParamsPager
  list: T[]
}

export type EntityOperations = {
  loading: boolean
  isReady: boolean
  request: <ArgType extends unknown>(
    action: string,
    params?: any
  ) => Promise<ArgType | undefined> | undefined
  retrieve: <ArgType extends unknown>(
    id: number
  ) => Promise<ArgType | undefined> | undefined
  delete: (id: number) => Promise<boolean> | boolean
  update: <ArgType extends unknown>(
    id: number,
    data: ArgType
  ) => Promise<ArgType | undefined>
  create: <ArgType extends unknown>(
    data: ArgType
  ) => Promise<ArgType | undefined>
  save: <ArgType extends unknown>(data: ArgType) => Promise<ArgType | undefined>
  list: <ArgType extends unknown>() =>
    | Promise<ArgType[] | undefined>
    | undefined
  paginate: <ArgType extends unknown>(
    params: EntityPaginateParams
  ) => Promise<EntityPaginateResult<ArgType> | undefined>
  subscribe: (
    id: number,
    handler: SubscriptionHandler,
    options?: SubscribeOptions
  ) => SubscriptionPromise<object> | undefined
}

const formatSubmitErrors = (error: any) => {
  let submitErrors = {}
  if (error.errors && Array.isArray(error.errors)) {
    let { errors, ...rest } = error

    for (let key of Object.keys(errors[0])) {
      submitErrors = {
        ...submitErrors,
        [key]: errors[0][key]
      }
    }

    submitErrors = {
      ...rest,
      fields: submitErrors
    }
  } else {
    submitErrors = error
  }

  return submitErrors
}

export const useEntityOperations = (entity: string): EntityOperations => {
  const { client } = useWebsockets()
  const [loading, setLoading] = useState(false)

  const request = <ArgType extends unknown>(
    action: string,
    params?: any
  ): Promise<ArgType | undefined> | undefined => {
    if (!client) return undefined

    setLoading(true)

    return client
      .request(entity, {
        action,
        data: params
      })
      .catch((error) => {
        console.debug({ error, entity })
        throw formatSubmitErrors(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const retrieve = <ArgType extends unknown>(
    id: number
  ): Promise<ArgType | undefined> | undefined => {
    if (!client) return undefined

    setLoading(true)

    return client
      .retrieve(entity, id)
      .catch((error) => {
        console.debug({ error, entity })
        throw formatSubmitErrors(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const subscribe = (
    id: number,
    handler: SubscriptionHandler,
    options?: SubscribeOptions
  ): SubscriptionPromise<object> | undefined => {
    if (!client) return undefined
    return client.subscribe(entity, id, handler, options)
  }

  const del = (id: number): Promise<boolean> | boolean => {
    if (!client) return false

    setLoading(true)

    return client
      .delete(entity, id)
      .catch((error) => {
        console.debug({ error, entity })
        throw formatSubmitErrors(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = <ArgType extends unknown>(
    id: number,
    data: ArgType
  ): Promise<ArgType | undefined> => {
    if (!client) return new Promise((resolve) => resolve(undefined))

    setLoading(true)
    return client
      .patch(entity, id, data as any)
      .catch((error) => {
        console.debug({ error, data, entity })
        throw formatSubmitErrors(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const create = <ArgType extends unknown>(
    data: ArgType
  ): Promise<ArgType | undefined> => {
    if (!client) return new Promise((resolve) => resolve(undefined))

    setLoading(true)

    return client
      .create(entity, data as any)
      .catch((error) => {
        console.debug({ error, data, entity })
        throw formatSubmitErrors(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const save = <ArgType extends unknown>(
    data: ArgType
  ): Promise<ArgType | undefined> => {
    if ((data as any).id) {
      return update((data as any).id, data)
    } else {
      return create(data)
    }
  }

  const list = <ArgType extends unknown>():
    | Promise<ArgType[] | undefined>
    | undefined => {
    if (!client) return undefined

    setLoading(true)

    return client
      .list(entity)
      .catch((error) => {
        console.debug({ error, entity })
        throw formatSubmitErrors(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const paginate = <ArgType extends unknown>(
    params: EntityPaginateParams
  ): Promise<EntityPaginateResult<ArgType> | undefined> => {
    if (!client) return new Promise((resolve) => resolve(undefined))

    const defaultParams = {
      pager: {
        page: 1,
        pageSize: 10
      },
      filters: {},
      search: {},
      order: []
    }

    setLoading(true)

    return client
      .request(entity, {
        action: 'paginate',
        data: merge({}, defaultParams, params)
      })
      .catch((error) => {
        console.debug({ error, entity })
        throw formatSubmitErrors(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    loading,
    isReady: !!client,
    request,
    retrieve,
    delete: del,
    update,
    create,
    save,
    list,
    paginate,
    subscribe
  }
}
