import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useUser } from "../../../providers/users/useUser";
import Layout from "../Layout";
import { useEntityOperations } from "../../../hooks/useEntityOperations";
import { User } from "../../../types/User";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export default function TermsAndConditions() {
  const { user, terms } = useUser();
  const { loading, update: acceptTermsAndConditions } =
    useEntityOperations("user.accept_terms");

  const [canConfirm, setCanConfirm] = useState(false);

  const scrollableRef = useRef<any>();

  const handleScroll = (event: any) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;

    if (bottom) {
      setCanConfirm(true);
    }
  };

  const handleAcceptTermsAndConditions = async () => {
    if (!!user?.id) {
      const ip_request = await axios.get(API_URL + "/whats_my_ip_address");
      const terms_and_conditions_acceptance_ip_address =
        ip_request?.data?.ip_address;

      return acceptTermsAndConditions<User>(user?.id, {
        ...user,
        terms_and_conditions_acceptance_ip_address,
        terms_and_conditions_accepted_at: new Date(),
      })
        .then((saved) => {
          // setPasswordChangeSuccess(true)
          window.location.href = "/";
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  };

  useEffect(() => {
    if (!user) return;
    if (!!user?.terms_and_conditions_accepted_at) {
      window.location.href = "/";
      return;
    }

    const scrollableRefElement = scrollableRef.current;

    if (scrollableRefElement) {
      window.setTimeout(() => {
        console.log("defining scroll event listener");
        scrollableRefElement.addEventListener("scroll", handleScroll);
      }, 1000);
    }
    return () =>
      !!scrollableRefElement &&
      scrollableRefElement.removeEventListener("scroll", handleScroll);
  }, [user, scrollableRef]);

  if (!terms) {
    return <>'Carregando...'</>;
  }

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        height="calc(100vh - 300px)"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          boxShadow={3}
          width="100%"
          padding="20px"
          borderRadius="10px"
          marginBottom="20px"
          sx={{
            backgroundColor: "#ccc",
          }}
        >
          <Typography fontSize="1.5rem">
            <strong>{user?.first_name}</strong>, antes de prosseguir, por favor
            leia atentamente aos termos e condições de uso do nosso sistema e,
            caso esteja de acordo, clique em <strong>estou de acordo</strong>.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          boxShadow={3}
          width="100%"
          padding="20px"
          borderRadius="10px"
          marginBottom="5px"
        >
          <Box
            ref={scrollableRef}
            key="terms_contents_wrapper"
            style={{
              width: "100%",
              border: "none",
              color: "#000",
              overflowX: "hidden",
              overflowY: "auto",
              height: "500px",
              padding: "1rem",
            }}
            // dangerouslySetInnerHTML={{ __html: terms }}
          >
            <Box
              key="terms_contents"
              dangerouslySetInnerHTML={{ __html: terms }}
            ></Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleAcceptTermsAndConditions}
            // disabled={loading || !canConfirm}
            sx={{
              marginTop: "20px",
            }}
          >
            Li e estou de acordo com os termos e condições
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}
