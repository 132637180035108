import {
  Checkbox,
  InputAdornment,
  ListItem,
  MenuItem,
  Stack,
  Typography,
  createFilterOptions
} from '@mui/material'
import { Box } from '@mui/system'
import sanitize from 'diacritic'
import { Autocomplete, Select, Switches, TextField } from 'mui-rff'
import { Form, FormSpy } from 'react-final-form'
import { FilterType } from '../../../types/Filter'
import {
  NAMES_MATCH_STRATEGIES,
  PersonalDetailsFilterCriteria,
  PersonalDetailsFilterCriteriaGender,
  PersonalDetailsFilterCriteriaNamesMatchStrategy,
  STATUSES
} from '../../../types/PersonalDetailsFilterCriteria'

import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import _ from 'lodash'
import { makeValidate } from 'mui-rff'
import { useCallback, useEffect, useMemo, useState } from 'react'
import createPersistedState from 'use-persisted-state'
import * as Yup from 'yup'
import { useEntityOperations } from '../../../hooks/useEntityOperations'
import { CBO } from '../../../types/CBO'
import { CNAE } from '../../../types/CNAE'
import { CompanySize } from '../../../types/CompanySize'
import { LegalNature } from '../../../types/LegalNature'
import { LegalPersonRevenueRange } from '../../../types/LegalPersonRevenueRange'
import { TaxRegime } from '../../../types/TaxRegime'
import Fieldset from '../Fieldset'
const useCBOState = createPersistedState('cbos')

export type EditPersonalDetailsFilterCriteriaProps = {
  criteria: PersonalDetailsFilterCriteria
  onChange: (changed: PersonalDetailsFilterCriteria, isValid: boolean) => void
  filter_type: FilterType
  editable?: boolean
}

Yup.addMethod(Yup.object, 'atLeastOneOf', function () {
  return this.test({
    name: 'atLeastOneOf',
    message:
      'Informe ao menos um filtro dentre as opções disponíveis no formulário',
    exclusive: true,
    test: (value) => {
      return (
        value.names.length > 0 ||
        value.age_range.length > 0 ||
        value.cnaes.length > 0 ||
        value.cbos.length > 0 ||
        value.statuses.length > 0
      )
    }
  })
})

const EditPersonalDetailsFilterCriteria = ({
  criteria,
  onChange,
  filter_type,
  editable = true
}: EditPersonalDetailsFilterCriteriaProps) => {
  const { list: listCNAEs } = useEntityOperations('contact.cnaes')
  const { list: listCBOs } = useEntityOperations('contact.cbos')
  const [cnaes, setCNAEs] = useState<CNAE[]>([])
  const [cbos, setCBOS] = useCBOState([])
  const [legalRevenueRanges, setLegalRevenueRanges] = useState<
    LegalPersonRevenueRange[]
  >([])

  const { list: listLegalNatures } = useEntityOperations(
    'contact.legal_natures'
  )
  const { list: listTaxRegimes } = useEntityOperations('contact.tax_regimes')
  const { list: listCompanySizes } = useEntityOperations(
    'contact.company_sizes'
  )

  const { list: listLegalRevenueRanges } = useEntityOperations(
    'contact.legal_person_revenue_ranges'
  )

  const [legalNatures, setLegalNatures] = useState<LegalNature[]>([])
  const [taxRegimes, setTaxRegimes] = useState<TaxRegime[]>([])
  const [companySizes, setCompanySizes] = useState<CompanySize[]>([])

  let filteredCBOOptions: Option[] = []
  let filteredCNAEOptions: CNAE[] = []

  useEffect(() => {
    const loadDependencies = async () => {
      const entries = await listCNAEs<CNAE>()
      !!entries && setCNAEs(entries)
    }

    !cnaes.length && loadDependencies()
  }, [listCNAEs, cnaes])

  useEffect(() => {
    const loadDependencies = async () => {
      const entries = await listCBOs<CBO>()
      !!entries && setCBOS(entries)
    }

    ;(!cbos || !(cbos as CBO[]).length) && loadDependencies()
  }, [listCBOs, cbos])

  useEffect(() => {
    const loadDependencies = async () => {
      const entries = await listLegalNatures<LegalNature>()
      !!entries && setLegalNatures(entries)
    }

    !legalNatures.length && loadDependencies()
  }, [listLegalNatures, legalNatures])

  useEffect(() => {
    const loadDependencies = async () => {
      const entries = await listTaxRegimes<TaxRegime>()
      !!entries && setTaxRegimes(entries)
    }

    !taxRegimes.length && loadDependencies()
  }, [listTaxRegimes, taxRegimes])

  useEffect(() => {
    const loadDependencies = async () => {
      const entries = await listCompanySizes<CompanySize>()
      !!entries && setCompanySizes(entries)
    }

    !companySizes.length && loadDependencies()
  }, [listCompanySizes, companySizes])

  useEffect(() => {
    const loadDependencies = async () => {
      const entries = await listLegalRevenueRanges<LegalPersonRevenueRange>()
      !!entries && setLegalRevenueRanges(entries)
    }

    !legalRevenueRanges.length && loadDependencies()
  }, [listLegalRevenueRanges, legalRevenueRanges])

  interface Option {
    id: number
    code: string
    description: string
    depth: number
    parent: CBO | LegalPersonRevenueRange | null
    matchTerms: string[]
  }

  const toOptions = useCallback(
    (
      entry: CBO | LegalPersonRevenueRange,
      depth: number = 0,
      parent: CBO | LegalPersonRevenueRange | null = null
    ): Option[] => {
      const { id, code, description, children = [] } = entry
      const children_map = children.flatMap((child) =>
        toOptions(child, depth + 1, entry)
      )
      const option = {
        id,
        code,
        description,
        depth,
        parent,
        matchTerms: [code, description].concat(
          children.map((obj) => obj.description)
        )
      }
      return [option].concat(children_map)
    },
    []
  )

  const cboOptions: Option[] = useMemo(() => {
    const entries = !cbos ? [] : (cbos as CBO[])
    return entries.flatMap((cbo: CBO) => toOptions(cbo))
  }, [cbos, toOptions])

  const cnaeOptions: Option[] = useMemo(() => {
    const entries = !cnaes ? [] : cnaes
    return entries.flatMap((cnae: CNAE) => toOptions(cnae))
  }, [cnaes, toOptions])

  const onSubmit = async (values: any) => {}

  // We define our schema based on the same keys as our form:
  const schema = Yup.object().shape({
    names: Yup.array().max(100, 'Informe no máximo 100 nomes'),
    age_range: Yup.array().test({
      name: 'age_range_incomplete',
      message: 'Adicione um intervalo de idades válido entre 18 e 120 anos',
      test: (value: any, { createError }) => {
        if (value.length > 0 && value.length < 2) {
          return createError({
            path: 'age_range',
            message:
              'Adicione um intervalo de idades válido entre 18 e 120 anos'
          })
        } else if (value.length === 2) {
          let initial = value[0]
          let final = value[1]

          if (initial == null || final == null) {
            return createError({
              path: 'age_range',
              message:
                'Adicione um intervalo de idades válido entre 18 e 120 anos'
            })
          }

          if (Number.parseInt(initial) >= Number.parseInt(final)) {
            return createError({
              path: 'age_range',
              message: 'A idade inicial deve ser menor que o Idade final'
            })
          }

          if (filter_type === FilterType.NATURAL_PERSONS) {
            if (Number.parseInt(initial) < 18) {
              return createError({
                path: 'age_range',
                message: 'A idade inicial deve ser maior ou igual a 18 anos'
              })
            }

            if (Number.parseInt(final) > 120) {
              return createError({
                path: 'age_range',
                message: 'A idade final deve ser menor ou igual a 120 anos'
              })
            }
          }

          return true
        } else {
          return true
        }
      }
    }),
    presumed_revenue_range: Yup.array().test({
      name: 'presumed_revenue_range_incomplete',
      message: 'Adicione um intervalo de idades válido entre 18 e 120 anos',
      test: (value: any, { createError }) => {
        if (filter_type === FilterType.NATURAL_PERSONS) {
          if (value.length > 0 && value.length < 2) {
            return createError({
              path: 'presumed_revenue_range',
              message: 'Adicione um intervalo de renda presumida válido.'
            })
          } else if (value.length === 2) {
            let initial = value[0]
            let final = value[1]

            if (initial == null || final == null) {
              return createError({
                path: 'presumed_revenue_range',
                message: 'Adicione um intervalo de renda presumida válido.'
              })
            }

            if (Number.parseInt(initial) >= Number.parseInt(final)) {
              return createError({
                path: 'presumed_revenue_range',
                message: 'A renda inicial deve ser menor que a renda final'
              })
            }

            if (Number.parseInt(initial) < 0) {
              return createError({
                path: 'presumed_revenue_range',
                message: 'A renda inicial deve ser maior ou igual a 0'
              })
            }
          }

          return true
        } else {
          return true
        }
      }
    })
  })

  const validate = makeValidate(schema)

  return (
    <Box p="30px">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...criteria,
          age_range: criteria.age_range ?? [],
          presumed_revenue_range: criteria.presumed_revenue_range ?? [],
          names: criteria.names ?? [],
          names_match_strategy:
            criteria.names_match_strategy ??
            PersonalDetailsFilterCriteriaNamesMatchStrategy.NAMES_MATCH_STRATEGY_EXACT,
          cnaes: criteria.cnaes ?? [],
          presumed_revenue_ranges: criteria.presumed_revenue_ranges ?? [],
          cbos: criteria.cbos ?? [],
          legal_natures: criteria.legal_natures ?? [],
          tax_regimes: criteria.tax_regimes ?? [],
          company_sizes: criteria.company_sizes ?? []
        }}
        validate={validate}
        validateOnBlur
        render={({ handleSubmit, values, form, errors }: any) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box
              sx={{
                marginBottom: '10px',
                marginTop: '20px'
              }}
            >
              {!!errors['undefined'] && (
                <Typography
                  sx={{
                    fontSize: '14px',
                    marginLeft: 0,
                    marginTop: '5px',
                    marginBottom: '20px',
                    color: 'red'
                  }}
                >
                  {errors['undefined']}
                </Typography>
              )}
              <TextField
                disabled={!editable}
                variant="outlined"
                label={`Lista de ${
                  filter_type === FilterType.NATURAL_PERSONS
                    ? 'Nomes'
                    : 'Razões Sociais'
                } (Opcional)`}
                name="names"
                helperText={`Adicione neste campo uma lista de ${
                  filter_type === FilterType.NATURAL_PERSONS
                    ? 'nomes completos'
                    : 'razões sociais'
                } separados por vírgula. Ex.: ${
                  filter_type === FilterType.NATURAL_PERSONS
                    ? 'JOAO DA SILVA, ANTONIO MACEDO'
                    : 'ACME ACESSORIOS LTDA, ANDALUZIA MATERIAS DE CONTRUCAO ME'
                }`}
                multiline
                rows={7}
                spellCheck={false}
                onBlur={(e) => {
                  form.change(
                    'names',
                    values.names.filter((name: any) => !!name)
                  )
                }}
                onPasteCapture={(e) => {
                  let pasted = e.clipboardData.getData('text')
                  pasted = pasted.replace(/\n/g, ',')
                  const parsed = pasted
                    .split(',')
                    .map((name: string, index: number, self: any): string => {
                      name = sanitize.clean(name).toUpperCase()
                      if (index < self.length - 1 || !name.trim()) {
                        name = name.trim()
                      }

                      if (
                        index === self.length - 1 &&
                        name.trim().length >= 150
                      ) {
                        return ''
                      }

                      return name
                    })
                    .filter(
                      (name: any, index: number, self: any) =>
                        self.indexOf(name) === index
                    )
                    .filter((name: any) => !!name)

                  form.change('names', parsed)
                  e.preventDefault()
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '1rem !important'
                  }
                }}
                InputProps={{
                  sx: {
                    '& legend': {
                      fontSize: '13px'
                    }
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      variant="filled"
                      sx={{
                        position: 'absolute',
                        bottom: '-15px',
                        right: '0',
                        fontWeight: 'bold'
                      }}
                    >
                      {!!values?.names ? values?.names.length : 0} nome(s)
                      válido(s)
                    </InputAdornment>
                  )
                }}
                FormHelperTextProps={{
                  sx: {
                    fontSize: '12px',
                    marginLeft: 0,
                    marginTop: '5px',
                    marginBottom: '10px'
                  }
                }}
                fieldProps={{
                  format: (value) => {
                    let commafied = value
                    if (Array.isArray(commafied)) {
                      commafied = commafied.join(',')
                    }
                    commafied = !!commafied ? commafied.replace(/\n/g, ',') : ''
                    return sanitize.clean(commafied).toUpperCase()
                  },
                  parse: (value) => {
                    let parsed = value
                    if (typeof parsed === 'string') {
                      parsed = parsed.replace(/\n/g, ',')
                      parsed = parsed
                        .split(',')
                        .map((name: string, index: number, self: any) => {
                          name = sanitize.clean(name).toUpperCase()
                          if (index < self.length - 1 || !name.trim()) {
                            name = name.trim()
                          }

                          if (
                            index === self.length - 1 &&
                            name.trim().length >= 150
                          ) {
                            return null
                          }

                          return name
                        })
                        .filter(
                          (name: any, index: number, self: any) =>
                            self.indexOf(name) === index
                        )

                      if (parsed.length === 1) {
                        parsed = parsed.filter((name: any) => !!name)
                      }

                      return parsed
                    }
                  }
                }}
              />
              <Box marginBottom="20px" marginTop="20px">
                <Select
                  disabled={!editable || criteria.names.length <= 0}
                  variant="outlined"
                  label={
                    filter_type === FilterType.NATURAL_PERSONS
                      ? 'Estratégia de busca de nomes'
                      : 'Estratégia de busca de razões sociais'
                  }
                  name="names_match_strategy"
                  helperText={
                    filter_type === FilterType.NATURAL_PERSONS
                      ? 'Selecione a estratégia de busca de nomes que deseja utilizar'
                      : 'Selecione a estratégia de busca de razões sociais deseja utilizar'
                  }
                  fullWidth
                  inputLabelProps={{
                    sx: {
                      fontSize: '1rem !important'
                    }
                  }}
                  sx={{
                    '& legend': {
                      fontSize: '13px !important'
                    }
                  }}
                  formHelperTextProps={{
                    sx: {
                      fontSize: '12px',
                      marginLeft: 0,
                      marginTop: '5px'
                    }
                  }}
                >
                  {NAMES_MATCH_STRATEGIES.map((strategy) => (
                    <MenuItem key={strategy.value} value={strategy.value}>
                      {strategy.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              {filter_type === FilterType.NATURAL_PERSONS && (
                <Box marginBottom="20px">
                  <Switches
                    // label="Apenas pessoas que possuem ao menos um eendereço de e-mail"
                    name="has_email"
                    required={true}
                    data={{
                      label:
                        'Apenas pessoas que possuem ao menos um endereço de e-mail',
                      value: true
                    }}
                    sx={{
                      fontSize: '1rem !important'
                    }}
                  />
                </Box>
              )}

              {filter_type === FilterType.NATURAL_PERSONS && (
                <Box marginBottom="20px">
                  <Select
                    disabled={!editable}
                    variant="outlined"
                    label="Gênero (Opcional)"
                    name="gender"
                    helperText="Selecione o gênero que dejesa filtrar"
                    inputLabelProps={{
                      sx: {
                        fontSize: '1rem !important'
                      }
                    }}
                    sx={{
                      '& legend': {
                        fontSize: '13px !important'
                      }
                    }}
                    formHelperTextProps={{
                      sx: {
                        fontSize: '12px',
                        marginLeft: 0,
                        marginTop: '5px'
                      }
                    }}
                  >
                    <MenuItem
                      value={PersonalDetailsFilterCriteriaGender.GENDER_FEMALE}
                    >
                      Feminino
                    </MenuItem>
                    <MenuItem
                      value={PersonalDetailsFilterCriteriaGender.GENDER_MALE}
                    >
                      Masculino
                    </MenuItem>
                  </Select>
                </Box>
              )}

              {filter_type === FilterType.LEGAL_PERSONS ? (
                <>
                  <Autocomplete
                    disabled={!editable}
                    sx={{
                      '& label': {
                        color: 'rgba(0, 0, 0, 0.6) !important',
                        fontSize: '1rem !important'
                      },
                      '& legend': {
                        fontSize: '13px'
                      },
                      marginY: 3
                    }}
                    name="cnaes"
                    label="Lista de CNAE's"
                    options={cnaeOptions}
                    fullWidth
                    multiple
                    filterSelectedOptions
                    aria-autocomplete="none"
                    textFieldProps={{
                      FormHelperTextProps: {
                        sx: {
                          fontSize: '12px',
                          marginLeft: 0,
                          marginTop: '5px'
                        }
                      },
                      'aria-autocomplete': 'none',
                      autoComplete: 'none',
                      InputProps: {
                        'aria-autocomplete': 'none',
                        autoComplete: 'none'
                      },
                      helperText: (
                        <>
                          (Opcional) - Selecione as CNAE's pelas quais deseja
                          filtrar. Para exemplos, acesse:{' '}
                          <a
                            href="https://cnae.ibge.gov.br/?view=estrutura"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              fontWeight: 'bold',
                              textDecoration: 'underline !important'
                            }}
                          >
                            Concla IBGE
                          </a>
                        </>
                      )
                    }}
                    onChange={(event, newValue, reason) => {
                      if (reason === 'clear') {
                        form.change('cnaes', [])
                      } else {
                        if (newValue.find((option) => option.code === 'all')) {
                          form.change('cnaes', [
                            ...values.cnaes,
                            ...filteredCNAEOptions
                          ])
                        } else {
                          form.change('cnaes', [...newValue])
                        }
                      }
                      filteredCNAEOptions = []
                    }}
                    getOptionLabel={(option) => {
                      return `${option.code.replace(
                        /([0-9]{4})([0-9]{1})([0-9]{2})/gi,
                        '$1-$2/$3'
                      )} - ${option.description}`
                    }}
                    renderOption={(props, option, { selected, inputValue }) => {
                      const matches = match(
                        option.code + ' - ' + option.description,
                        inputValue,
                        {
                          insideWords: true,
                          findAllOccurrences: /^\d/.test(inputValue)
                            ? false
                            : true
                        }
                      )
                      const parts = parse(
                        option.code + ' - ' + option.description,
                        matches
                      )
                      return (
                        <ListItem
                          {...props}
                          sx={{
                            backgroundColor:
                              option.code === 'all' ? '#e6e5e4' : 'transparent',
                            '&:hover': {
                              backgroundColor: '#e6e5e4 !important'
                            }
                          }}
                        >
                          <Checkbox
                            checked={selected}
                            sx={{ ml: 2 * option.depth }}
                          />
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight:
                                    option.code === 'all'
                                      ? '700'
                                      : part.highlight
                                      ? 700
                                      : 400
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </ListItem>
                      )
                    }}
                    filterOptions={(options, params) => {
                      let filter

                      // if inputValue starts with a number, we assume it's a CNAE code
                      if (/^\d/.test(params.inputValue)) {
                        filter = createFilterOptions({
                          stringify: (option: Option) =>
                            option.matchTerms.join('//'),
                          limit: 100,
                          ignoreCase: true,
                          matchFrom: 'start'
                        })
                      } else {
                        filter = createFilterOptions({
                          stringify: (option: Option) =>
                            option.matchTerms.join('//'),
                          limit: 100,
                          ignoreCase: true,
                          matchFrom: 'any'
                        })
                      }

                      const filtered = _.uniqBy(filter(options, params), 'code')
                      filteredCNAEOptions = filtered

                      if (params.inputValue.length > 0) {
                        return [
                          { description: 'SELECIONAR TODOS...', code: 'all' },
                          ...filtered
                        ]
                      } else {
                        return filtered
                      }
                    }}
                    value={criteria.cnaes || []}
                  />

                  <Autocomplete
                    disabled={!editable}
                    sx={{
                      marginBottom: '20px',
                      '& label': {
                        color: 'rgba(0, 0, 0, 0.6) !important',
                        fontSize: '1rem !important'
                      },
                      '& legend': {
                        fontSize: '13px'
                      }
                    }}
                    name="legal_natures"
                    label="Lista de Naturezas Jurídicas"
                    options={legalNatures}
                    fullWidth
                    multiple
                    aria-autocomplete="none"
                    textFieldProps={{
                      FormHelperTextProps: {
                        sx: {
                          fontSize: '12px',
                          marginLeft: 0,
                          marginTop: '5px'
                        }
                      },
                      'aria-autocomplete': 'none',
                      autoComplete: 'none',
                      InputProps: {
                        'aria-autocomplete': 'none',
                        autoComplete: 'none'
                      },
                      helperText: (
                        <>
                          (Opcional) - Selecione as naturezas jurídicas pelas
                          quais deseja filtrar
                        </>
                      )
                    }}
                    getOptionLabel={(option) => {
                      return `${option.code} - ${option.description}`
                    }}
                    value={criteria.legal_natures || []}
                  />

                  <Autocomplete
                    disabled={!editable}
                    sx={{
                      marginBottom: '20px',
                      '& label': {
                        color: 'rgba(0, 0, 0, 0.6) !important',
                        fontSize: '1rem !important'
                      },
                      '& legend': {
                        fontSize: '13px'
                      }
                    }}
                    name="tax_regimes"
                    label="Lista de Regimes Tributários"
                    options={taxRegimes}
                    fullWidth
                    multiple
                    aria-autocomplete="none"
                    textFieldProps={{
                      FormHelperTextProps: {
                        sx: {
                          fontSize: '12px',
                          marginLeft: 0,
                          marginTop: '5px'
                        }
                      },
                      'aria-autocomplete': 'none',
                      autoComplete: 'none',
                      InputProps: {
                        'aria-autocomplete': 'none',
                        autoComplete: 'none'
                      },
                      helperText: (
                        <>
                          (Opcional) - Selecione os regimes tributários pelas
                          quais deseja filtrar
                        </>
                      )
                    }}
                    getOptionLabel={(option) => {
                      return `${option.code} - ${option.description}`
                    }}
                    value={criteria.tax_regimes || []}
                  />

                  <Autocomplete
                    disabled={!editable}
                    sx={{
                      marginBottom: '20px',
                      '& label': {
                        color: 'rgba(0, 0, 0, 0.6) !important',
                        fontSize: '1rem !important'
                      },
                      '& legend': {
                        fontSize: '13px'
                      }
                    }}
                    name="company_sizes"
                    label="Lista de Portes de Empresa"
                    options={companySizes}
                    fullWidth
                    multiple
                    aria-autocomplete="none"
                    textFieldProps={{
                      FormHelperTextProps: {
                        sx: {
                          fontSize: '12px',
                          marginLeft: 0,
                          marginTop: '5px'
                        }
                      },
                      'aria-autocomplete': 'none',
                      autoComplete: 'none',
                      InputProps: {
                        'aria-autocomplete': 'none',
                        autoComplete: 'none'
                      },
                      helperText: (
                        <>
                          (Opcional) - Selecione os portes de empresa pelos
                          quais deseja filtrar
                        </>
                      )
                    }}
                    getOptionLabel={(option) => {
                      return `${option.code} - ${option.description}`
                    }}
                    value={criteria.company_sizes || []}
                  />
                  <Box marginBottom="20px">
                    <Autocomplete
                      disabled={!editable}
                      sx={{
                        marginBottom: '20px',
                        '& label': {
                          color: 'rgba(0, 0, 0, 0.6) !important',
                          fontSize: '1rem !important'
                        },
                        '& legend': {
                          fontSize: '13px'
                        }
                      }}
                      name="presumed_revenue_ranges"
                      label="Faixas de Faturamento Presumido (Opcional)"
                      options={legalRevenueRanges}
                      fullWidth
                      multiple
                      aria-autocomplete="none"
                      textFieldProps={{
                        FormHelperTextProps: {
                          sx: {
                            fontSize: '12px',
                            marginLeft: 0,
                            marginTop: '5px'
                          }
                        },
                        'aria-autocomplete': 'none',
                        autoComplete: 'none',
                        InputProps: {
                          'aria-autocomplete': 'none',
                          autoComplete: 'none'
                        },
                        helperText: (
                          <>
                            (Opcional) - Selecione as faixas de faturamento
                            presumido pelas quais deseja filtrar
                          </>
                        )
                      }}
                      getOptionLabel={(option) => {
                        return option.description
                      }}
                      value={criteria.presumed_revenue_ranges || []}
                    />
                  </Box>
                  <Box marginBottom="20px">
                    <Autocomplete
                      disabled={!editable}
                      sx={{
                        marginTop: '20px',
                        '& label': {
                          color: 'rgba(0, 0, 0, 0.6) !important',
                          fontSize: '1rem !important'
                        },
                        '& legend': {
                          fontSize: '13px'
                        }
                      }}
                      name="statuses"
                      label="Situação Cadastral (Opcional)"
                      options={STATUSES}
                      helperText="Selecione as situações cadastrais pelas quais deseja filtrar"
                      fullWidth
                      multiple
                      textFieldProps={{
                        FormHelperTextProps: {
                          sx: {
                            fontSize: '12px',
                            marginLeft: 0,
                            marginTop: '5px'
                          }
                        }
                      }}
                      getOptionLabel={(option: any) => {
                        if (typeof option === 'string') {
                          return STATUSES.find(
                            (status) => status.value === option
                          )?.label
                        }
                        return option.label
                      }}
                      value={criteria.statuses || []}
                    />
                  </Box>

                  <Box marginBottom="20px">
                    <Switches
                      name="is_mei"
                      required={true}
                      data={{
                        label:
                          'Apenas empresas que possuem o MEI (Microempreendedor Individual) ativo',
                        value: true
                      }}
                      sx={{
                        fontSize: '1rem !important'
                      }}
                    />
                  </Box>

                  <Box marginBottom="20px">
                    <Switches
                      name="is_simples"
                      required={true}
                      data={{
                        label:
                          'Apenas empresas que possuem o Simples Nacional ativo',
                        value: true
                      }}
                      sx={{
                        fontSize: '1rem !important'
                      }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Fieldset legend={`Renda Presumida (Opcional)`}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TextField
                        disabled={!editable}
                        variant="filled"
                        name="presumed_revenue_range[0]"
                        FormHelperTextProps={{
                          sx: {
                            display: 'none'
                          }
                        }}
                        type="number"
                        InputProps={{
                          sx: {
                            '& legend': {
                              fontSize: '13px'
                            },
                            '& input': {
                              paddingTop: '10px !important',
                              margin: '0 10px !important'
                            }
                          },
                          disableUnderline: true,
                          autoComplete: 'none',
                          startAdornment: (
                            <span
                              style={{
                                backgroundColor: '#e6e5e4',
                                padding: '10px',
                                marginLeft: '-10px'
                              }}
                            >
                              entre
                            </span>
                          ),
                          endAdornment: (
                            <span
                              style={{
                                backgroundColor: '#e6e5e4',
                                padding: '10px',
                                marginRight: '-10px'
                              }}
                            >
                              R$
                            </span>
                          )
                        }}
                        onBlur={(e) => {
                          form.change(
                            'presumed_revenue_range',
                            values.presumed_revenue_range.filter(
                              (entry: any) => !!entry
                            )
                          )
                        }}
                      />
                      <TextField
                        disabled={!editable}
                        variant="filled"
                        name="presumed_revenue_range[1]"
                        type="number"
                        error={!!errors['presumed_revenue_range']}
                        FormHelperTextProps={{
                          sx: {
                            display: 'none'
                          }
                        }}
                        onBlur={(e) => {
                          form.change(
                            'presumed_revenue_range',
                            values.presumed_revenue_range.filter(
                              (entry: any) => !!entry
                            )
                          )
                        }}
                        InputProps={{
                          sx: {
                            '& legend': {
                              fontSize: '13px'
                            },
                            '& input': {
                              paddingTop: '10px !important',
                              margin: '0 10px !important'
                            }
                          },
                          disableUnderline: true,
                          autoComplete: 'none',
                          startAdornment: (
                            <span
                              style={{
                                backgroundColor: '#e6e5e4',
                                padding: '10px',
                                marginLeft: '-10px'
                              }}
                            >
                              e
                            </span>
                          ),
                          endAdornment: (
                            <span
                              style={{
                                backgroundColor: '#e6e5e4',
                                padding: '10px',
                                marginRight: '-10px'
                              }}
                            >
                              R$
                            </span>
                          )
                        }}
                      />
                    </Stack>
                  </Fieldset>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      marginLeft: 0,
                      marginTop: '5px',
                      color: !!errors['presumed_revenue_range']
                        ? 'red'
                        : 'rgba(0, 0, 0, 0.6) !important'
                    }}
                  >
                    {!!errors['presumed_revenue_range'] ? (
                      <>{errors['presumed_revenue_range']}</>
                    ) : (
                      <></>
                    )}
                  </Typography>

                  <Autocomplete
                    disabled={!editable}
                    sx={{
                      '& label': {
                        color: 'rgba(0, 0, 0, 0.6) !important',
                        fontSize: '1rem !important'
                      },
                      '& legend': {
                        fontSize: '13px'
                      },
                      marginY: 3
                    }}
                    name="cbos"
                    label="Lista de CBO's"
                    options={cboOptions}
                    fullWidth
                    multiple
                    filterSelectedOptions
                    aria-autocomplete="none"
                    textFieldProps={{
                      FormHelperTextProps: {
                        sx: {
                          fontSize: '12px',
                          marginLeft: 0,
                          marginTop: '5px'
                        }
                      },
                      'aria-autocomplete': 'none',
                      autoComplete: 'none',
                      InputProps: {
                        'aria-autocomplete': 'none',
                        autoComplete: 'none'
                      },
                      helperText: (
                        <>
                          (Opcional) - Selecione os CBO's pelos quais deseja
                          filtrar. Para exemplos, acesse:{' '}
                          <a
                            href="https://www.ocupacoes.com.br"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              fontWeight: 'bold',
                              textDecoration: 'underline !important'
                            }}
                          >
                            Classificação Brasileira De Ocupação
                          </a>
                        </>
                      )
                    }}
                    onChange={(event, newValue, reason) => {
                      if (reason === 'clear') {
                        form.change('cbos', [])
                      } else {
                        if (newValue.find((option) => option.code === 'all')) {
                          form.change('cbos', [
                            ...values.cbos,
                            ...filteredCBOOptions
                          ])
                        } else {
                          form.change('cbos', [...newValue])
                        }
                      }
                      filteredCBOOptions = []
                    }}
                    getOptionLabel={(option) => {
                      return `${option.code.replace(
                        /([0-9]{4})([0-9]{1})([0-9]{2})/gi,
                        '$1-$2/$3'
                      )} - ${option.description}`
                    }}
                    renderOption={(props, option, { selected, inputValue }) => {
                      const matches = match(
                        option.code + ' - ' + option.description,
                        inputValue,
                        {
                          insideWords: true,
                          findAllOccurrences: /^\d/.test(inputValue)
                            ? false
                            : true
                        }
                      )
                      const parts = parse(
                        option.code + ' - ' + option.description,
                        matches
                      )
                      return (
                        <ListItem
                          {...props}
                          sx={{
                            backgroundColor:
                              option.code === 'all' ? '#e6e5e4' : 'transparent',
                            '&:hover': {
                              backgroundColor: '#e6e5e4 !important'
                            }
                          }}
                        >
                          <Checkbox
                            checked={selected}
                            sx={{ ml: 2 * option.depth }}
                          />
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight:
                                    option.code === 'all'
                                      ? '700'
                                      : part.highlight
                                      ? 700
                                      : 400
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </ListItem>
                      )
                    }}
                    filterOptions={(options, params) => {
                      let filter

                      // if inputValue starts with a number, we assume it's a CBO code
                      if (/^\d/.test(params.inputValue)) {
                        filter = createFilterOptions({
                          stringify: (option: Option) =>
                            option.matchTerms.join('//'),
                          limit: 100,
                          ignoreCase: true,
                          matchFrom: 'start'
                        })
                      } else {
                        filter = createFilterOptions({
                          stringify: (option: Option) =>
                            option.matchTerms.join('//'),
                          limit: 100,
                          ignoreCase: true,
                          matchFrom: 'any'
                        })
                      }

                      const filtered = _.uniqBy(filter(options, params), 'code')
                      filteredCBOOptions = filtered

                      if (params.inputValue.length > 0) {
                        return [
                          { description: 'SELECIONAR TODOS...', code: 'all' },
                          ...filtered
                        ]
                      } else {
                        return filtered
                      }
                    }}
                    value={criteria.cbos || []}
                  />
                </>
              )}

              <Fieldset
                legend={`${
                  filter_type === FilterType.NATURAL_PERSONS
                    ? 'Idade'
                    : 'Tempo de Atividade'
                } (Opcional)`}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <TextField
                    disabled={!editable}
                    variant="filled"
                    name="age_range[0]"
                    FormHelperTextProps={{
                      sx: {
                        display: 'none'
                      }
                    }}
                    type="number"
                    InputProps={{
                      sx: {
                        '& legend': {
                          fontSize: '13px'
                        },
                        '& input': {
                          paddingTop: '10px !important',
                          margin: '0 10px !important'
                        }
                      },
                      disableUnderline: true,
                      autoComplete: 'none',
                      startAdornment: (
                        <span
                          style={{
                            backgroundColor: '#e6e5e4',
                            padding: '10px',
                            marginLeft: '-10px'
                          }}
                        >
                          entre
                        </span>
                      ),
                      endAdornment: (
                        <span
                          style={{
                            backgroundColor: '#e6e5e4',
                            padding: '10px',
                            marginRight: '-10px'
                          }}
                        >
                          anos
                        </span>
                      )
                    }}
                    onBlur={(e) => {
                      form.change(
                        'age_range',
                        values.age_range.filter((entry: any) => !!entry)
                      )
                    }}
                  />
                  <TextField
                    disabled={!editable}
                    variant="filled"
                    name="age_range[1]"
                    type="number"
                    error={!!errors['age_range']}
                    FormHelperTextProps={{
                      sx: {
                        display: 'none'
                      }
                    }}
                    onBlur={(e) => {
                      form.change(
                        'age_range',
                        values.age_range.filter((entry: any) => !!entry)
                      )
                    }}
                    InputProps={{
                      sx: {
                        '& legend': {
                          fontSize: '13px'
                        },
                        '& input': {
                          paddingTop: '10px !important',
                          margin: '0 10px !important'
                        }
                      },
                      disableUnderline: true,
                      autoComplete: 'none',
                      startAdornment: (
                        <span
                          style={{
                            backgroundColor: '#e6e5e4',
                            padding: '10px',
                            marginLeft: '-10px'
                          }}
                        >
                          e
                        </span>
                      ),
                      endAdornment: (
                        <span
                          style={{
                            backgroundColor: '#e6e5e4',
                            padding: '10px',
                            marginRight: '-10px'
                          }}
                        >
                          anos
                        </span>
                      )
                    }}
                  />
                </Stack>
              </Fieldset>
              <Typography
                sx={{
                  fontSize: '12px',
                  marginLeft: 0,
                  marginTop: '5px',
                  color: !!errors['age_range']
                    ? 'red'
                    : 'rgba(0, 0, 0, 0.6) !important'
                }}
              >
                {!!errors['age_range'] ? <>{errors['age_range']}</> : <></>}
              </Typography>
            </Box>
            {/* <pre>{JSON.stringify({ errors, values }, undefined, 2)}</pre> */}
            <FormSpy
              subscription={{ values: true, hasValidationErrors: true }}
              onChange={({ values, hasValidationErrors }) => {
                values.statuses = !!values.statuses
                  ? values.statuses.map((entry: any) =>
                      typeof entry === 'string' ? entry : entry.value
                    )
                  : []

                values.names_match_strategy =
                  typeof values.names_match_strategy === 'string'
                    ? values.names_match_strategy
                    : values.names_match_strategy.value

                values.statuses_join = values.statuses.join(',')

                values.cnaes_ids = (
                  values as PersonalDetailsFilterCriteria
                ).cnaes.map((entry) => entry.id)

                values.cnaes_codes = (
                  values as PersonalDetailsFilterCriteria
                ).cnaes.map((entry) => entry.code)

                values.presumed_revenue_ranges_ids = (
                  values as PersonalDetailsFilterCriteria
                ).presumed_revenue_ranges.map((entry) => entry.id)

                values.presumed_revenue_ranges_codes = (
                  values as PersonalDetailsFilterCriteria
                ).presumed_revenue_ranges.map((entry) => entry.code)

                values.cbos_ids = (
                  values as PersonalDetailsFilterCriteria
                ).cbos.map((entry) => entry.id)

                values.cbos_codes = (
                  values as PersonalDetailsFilterCriteria
                ).cbos.map((entry) => entry.code)

                values.legal_natures_ids = (
                  values as PersonalDetailsFilterCriteria
                ).legal_natures.map((entry) => entry.id)

                values.legal_natures_codes = (
                  values as PersonalDetailsFilterCriteria
                ).legal_natures.map((entry) => entry.code)

                values.tax_regimes_ids = (
                  values as PersonalDetailsFilterCriteria
                ).tax_regimes.map((entry) => entry.id)

                values.tax_regimes_codes = (
                  values as PersonalDetailsFilterCriteria
                ).tax_regimes.map((entry) => entry.code)

                values.company_sizes_ids = (
                  values as PersonalDetailsFilterCriteria
                ).company_sizes.map((entry) => entry.id)

                values.company_sizes_codes = (
                  values as PersonalDetailsFilterCriteria
                ).company_sizes.map((entry) => entry.code)

                onChange(
                  values as PersonalDetailsFilterCriteria,
                  !hasValidationErrors
                )
              }}
            />
          </form>
        )}
      />
    </Box>
  )
}

export default EditPersonalDetailsFilterCriteria
