import { InfoOutlined } from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyIcon from "@mui/icons-material/Key";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Logout from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TransitionProps } from "@mui/material/transitions";
import { makeValidate, TextField } from "mui-rff";
import * as React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useEntityOperations } from "../../../hooks/useEntityOperations";
import { useAuthentication } from "../../../providers/authentication/useAuthentication";
import { useUser } from "../../../providers/users/useUser";
import { User } from "../../../types/User";
import Tooltip from "../../mui/Tooltip";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// We define our schema based on the same keys as our form:
const schema = Yup.object().shape({
  old_password: Yup.string()
    .required("Informe a sua senha atual")
    .min(8, "Senha atual inválida (muito curta)")
    .max(16, "Senha atual inválida (muito longa)"),
  password: Yup.string()
    .required("Informe a sua nova senha")
    .min(8, "Nova senha inválida (muito curta)")
    .max(16, "Nova senha inválida (muito longa)"),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password")],
    "A nova senha e a confirmação de senha devem ser iguais"
  ),
});

const validate = makeValidate(schema);

export default function BalanceDisplay() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useUser();
  const { loading: loadingUpdatePassword, save: updatePassword } =
    useEntityOperations("user.change_password");

  const { logout } = useAuthentication();

  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    React.useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation((show) => !show);

  const [openPasswordChange, setOpenPasswordChange] = React.useState(false);

  const [passwordChangeSuccess, setPasswordChangeSuccess] =
    React.useState(false);

  const [initialPasswordChangeValue, setInitialPasswordChangeValue] =
    React.useState({});

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChangePassword = (values: any) => {
    if (!!user?.id) {
      return updatePassword<User>({
        ...user,
        ...values,
      })
        .then((saved) => {
          setPasswordChangeSuccess(true);
        })
        .catch((error) => {
          const setSubmitErrors = (fields: any): any => {
            let submitErrors: any = {};
            Object.keys(fields).forEach((key: string) => {
              submitErrors[key] = (
                <span style={{ display: "flex", flexDirection: "column" }}>
                  {fields[key].map((msg: string, index: number) => (
                    <span key={index}>{msg}</span>
                  ))}
                </span>
              );
            });

            return submitErrors;
          };

          return !!error.fields ? setSubmitErrors(error.fields) : undefined;
        });
    }
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  React.useEffect(() => {
    setPasswordChangeSuccess(false);
    setShowOldPassword(false);
    setShowPassword(false);
    setShowPasswordConfirmation(false);
    setInitialPasswordChangeValue({});
  }, [openPasswordChange]);

  return (
    <React.Fragment>
      {openPasswordChange && (
        <Form
          onSubmit={handleChangePassword}
          validate={validate}
          validateOnBlur
          initialValues={initialPasswordChangeValue}
          render={({
            handleSubmit,
            hasValidationErrors,
            hasSubmitErrors,
            dirtySinceLastSubmit,
          }) => (
            <form noValidate>
              <Dialog
                maxWidth="md"
                fullWidth
                open
                TransitionComponent={Transition}
                PaperProps={{
                  sx: { height: "auto", minHeight: "300px" },
                }}
              >
                <DialogTitle sx={{ padding: 0 }}>
                  <AppBar
                    sx={{
                      position: "relative",
                      boxShadow: 0,
                    }}
                  >
                    <Toolbar>
                      <KeyIcon fontSize="large" />

                      <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h6"
                        component="div"
                      >
                        Alteração de senha
                      </Typography>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenPasswordChange(false)}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                </DialogTitle>

                <DialogContent dividers sx={{ p: "30px 30px" }}>
                  {passwordChangeSuccess ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        minHeight: "300px",
                        opacity: 0.6,
                      }}
                    >
                      <Typography
                        variant="h5"
                        color="green"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          marginBottom: "10px",
                        }}
                      >
                        <InfoOutlined
                          sx={{
                            fontSize: "6rem",
                            marginBottom: "10px",
                            fontWeight: "notmal",
                          }}
                        />{" "}
                        Sua senha foi alterada com sucesso!
                      </Typography>
                      <Typography
                        variant="h6"
                        color="green"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Agora você já pode fechar esta janela
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        marginBottom: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Informe sua senha atual"
                        name="old_password"
                        required
                        type={showOldPassword ? "text" : "password"}
                        helperText=""
                        sx={{
                          marginBottom: "30px",
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: "1rem !important",
                          },
                        }}
                        InputProps={{
                          sx: {
                            "& legend": {
                              fontSize: "13px",
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showOldPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          sx: {
                            fontSize: "12px",
                            marginLeft: 0,
                            marginTop: "5px",
                          },
                        }}
                      />

                      <TextField
                        variant="outlined"
                        label="Informe a sua nova senha"
                        name="password"
                        required
                        type={showPassword ? "text" : "password"}
                        helperText=""
                        sx={{
                          marginBottom: "20px",
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: "1rem !important",
                          },
                        }}
                        InputProps={{
                          sx: {
                            "& legend": {
                              fontSize: "13px",
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          sx: {
                            fontSize: "12px",
                            marginLeft: 0,
                            marginTop: "5px",
                          },
                        }}
                      />

                      <TextField
                        variant="outlined"
                        label="Confirme a sua nova senha"
                        name="password_confirmation"
                        required
                        type={showPasswordConfirmation ? "text" : "password"}
                        helperText=""
                        sx={{
                          marginBottom: "10px",
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: "1rem !important",
                          },
                        }}
                        InputProps={{
                          sx: {
                            "& legend": {
                              fontSize: "13px",
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordConfirmation}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPasswordConfirmation ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        FormHelperTextProps={{
                          sx: {
                            fontSize: "12px",
                            marginLeft: 0,
                            marginTop: "5px",
                          },
                        }}
                      />
                    </Box>
                  )}
                </DialogContent>
                {!passwordChangeSuccess && (
                  <DialogActions
                    sx={{
                      backgroundColor: "#cccccc1c",
                      padding: "20px",
                    }}
                  >
                    <Button
                      autoFocus
                      color="success"
                      type="submit"
                      disabled={
                        hasValidationErrors ||
                        (!dirtySinceLastSubmit && hasSubmitErrors) ||
                        loadingUpdatePassword ||
                        !user
                      }
                      variant="contained"
                      size="large"
                      onClick={handleSubmit}
                    >
                      CONFIRMAR ALTERAÇÃO
                    </Button>
                  </DialogActions>
                )}
              </Dialog>
            </form>
          )}
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "left",
          color: "black",
          width: "180px",
          height: "40px",
          margin: "0 20px",
        }}
      >
        <Typography
          marginRight="10px"
          fontSize="0.65rem"
          textAlign="right"
          color='#5b5454'
        >
          créditos disponíveis
        </Typography>
        <Tooltip
          title={
            user?.is_trial
              ? "Periodo de testes"
              : "Este é seu saldo atual de créditos"
          }
        >
          <Box
            sx={{
              backgroundColor: "#fff !important",
              fontSize: "15px",
              width: "100%",
              textAlign: "center",
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              color: '#716d6d',
              fontWeight: 'bold'
            }}
          >
            {user?.is_trial ? (
              <>TESTES</>
            ) : (
              <>
                {new Intl.NumberFormat("pt-BR").format(
                  user?.available_quota || 0
                )}
              </>
            )}
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to="/subscriptions" reloadDocument>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          Minha assinatura
        </MenuItem>
        <MenuItem component={Link} to="/" reloadDocument>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          Minhas extrações
        </MenuItem>
        <MenuItem component={Link} to="/filter" reloadDocument>
          <ListItemIcon>
            <FilterAltIcon />
          </ListItemIcon>
          {window.location.hostname.includes("localize")
            ? "Nova Busca"
            : "Nova Extração"}
        </MenuItem>
        <MenuItem onClick={() => setOpenPasswordChange(true)}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          Alterar senha
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>

      <Avatar
        {...stringAvatar("Sandro Salles")}
        sx={{ width: 32, height: 32, marginRight: 2, cursor: "pointer" }}
        onClick={handleClick}
      />
    </React.Fragment>
  );
}
