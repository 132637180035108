import AutofpsSelectIcon from '@mui/icons-material/AutofpsSelect'
import { Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Ellipsis, EllipsisMode } from 'react-simple-ellipsis'
import { FilterType } from '../../../types/Filter'
import { NameListFilterCriteria } from '../../../types/NameListFilterCriteria'

export type NameListFilterCriteriaListItemProps = {
  criteria: NameListFilterCriteria
  filter_type: FilterType
}

const NameListFilterCriteriaListItem = ({
  criteria,
  filter_type
}: NameListFilterCriteriaListItemProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        minWidth="260px"
        sx={{
          backgroundColor:
            filter_type === FilterType.NATURAL_PERSONS ? '#1976d2' : '#9c27b0',
          minHeight: '50px',
          padding: '10px 20px 10px 10px',
          borderRadius: '10px 0 0 10px',
          borderRight: '1px solid #b4b4b4'
        }}
      >
        <AutofpsSelectIcon fontSize="large" htmlColor="#fff" />
        <Typography fontWeight="bold" textTransform="uppercase" color="#fff">
          {`Lista de ${
            filter_type === FilterType.NATURAL_PERSONS
              ? 'Nomes'
              : 'Razões Sociais'
          }`}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="center"
          sx={{
            flexWrap: 'wrap',
            paddingY: '8px'
          }}
        >
          {criteria.names.slice(0, 1).map((name) => (
            <Typography key={name}>
              <Ellipsis
                ellipsis="..."
                label=""
                id={name}
                text={name}
                limit={200}
                class="more"
                mode={EllipsisMode.After}
              />
            </Typography>
          ))}
          {criteria.names.length > 1 && (
            <Chip
              label={`+ ${criteria.names.length - 1}`}
              variant="filled"
              sx={{
                fontWeight: 'bold',
                marginLeft: '10px'
              }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default NameListFilterCriteriaListItem
