import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Chip, Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { AddressDetailsFilterCriteria } from '../../../types/AddressDetailsFilterCriteria'
import { City } from '../../../types/City'
import { FilterType } from '../../../types/Filter'
import { Neighborhood } from '../../../types/Neighborhood'
import { State } from '../../../types/State'

export type AddressDetailsFilterCriteriaListItemProps = {
  criteria: AddressDetailsFilterCriteria
  filter_type: FilterType
}

const AddressDetailsFilterCriteriaListItem = ({
  criteria,
  filter_type
}: AddressDetailsFilterCriteriaListItemProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        minWidth="260px"
        sx={{
          backgroundColor:
            filter_type === FilterType.NATURAL_PERSONS ? '#1976d2' : '#9c27b0',
          minHeight: '50px',
          padding: '10px 20px 10px 10px',
          borderRadius: '10px 0 0 10px',
          borderRight: '1px solid #b4b4b4'
        }}
      >
        <LocationOnIcon htmlColor="#fff" fontSize="large" />
        <Typography fontWeight="bold" textTransform="uppercase" color="#fff">
          Filtro de Endereço
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        {criteria.states.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold">UF's:</Typography>
            {criteria.states
              .slice(0, 3)
              .map((entry: State, index: number, self: any) => {
                return (
                  <Typography key={entry.code}>
                    {entry.code}
                    {index < self.length - 1 && <>, </>}
                  </Typography>
                )
              })}
            {criteria.states.length > 3 && (
              <Chip
                label={`+ ${criteria.states.length - 3}`}
                variant="filled"
                sx={{
                  fontWeight: 'bold',
                  marginLeft: '10px'
                }}
              />
            )}
          </>
        )}
        {criteria.cities.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold">Cidades:</Typography>
            {criteria.cities
              .slice(0, 1)
              .map((entry: City, index: number, self: any) => {
                return (
                  <Typography key={entry.id}>
                    {entry.state.code} - {entry.name}
                    {index < self.length - 1 && <>, </>}
                  </Typography>
                )
              })}
            {criteria.cities.length > 1 && (
              <Chip
                label={`+ ${criteria.cities.length - 1}`}
                variant="filled"
                sx={{
                  fontWeight: 'bold',
                  marginLeft: '10px'
                }}
              />
            )}
          </>
        )}
        {criteria.neighborhoods.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold">Bairros:</Typography>
            {criteria.neighborhoods
              .slice(0, 1)
              .map((entry: Neighborhood, index: number, self: any) => {
                return (
                  <Typography key={entry.id}>
                    {entry.city.state.code} - {entry.city.name} - {entry.name}
                    {index < self.length - 1 && <>, </>}
                  </Typography>
                )
              })}
            {criteria.neighborhoods.length > 1 && (
              <Chip
                label={`+ ${criteria.neighborhoods.length - 1}`}
                variant="filled"
                sx={{
                  fontWeight: 'bold',
                  marginLeft: '10px'
                }}
              />
            )}
          </>
        )}
        {criteria.zipcodes?.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold">Lista de CEP's:</Typography>
            {criteria.zipcodes
              .slice(0, 2)
              .map((entry: string, index: number, self: any) => {
                return (
                  <Typography key={entry}>
                    {entry.replace(/(\d{5})(\d{3})/, '$1-$2')}
                    {index < self.length - 1 && <>, </>}
                  </Typography>
                )
              })}
            {criteria.zipcodes.length > 2 && (
              <Chip
                label={`+ ${criteria.zipcodes.length - 2}`}
                variant="filled"
                sx={{
                  fontWeight: 'bold',
                  marginLeft: '10px'
                }}
              />
            )}
          </>
        )}
        {criteria.zipcode_range?.length > 1 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold">Intervalo de CEP's:</Typography>
            <Typography>
              {String(criteria.zipcode_range[0])
                .padStart(8, '0')
                .replace(/(\d{5})(\d{3})/, '$1-$2')}
              <span style={{ margin: '0 10px 0 10px' }}>/</span>
              {String(criteria.zipcode_range[1])
                .padStart(8, '0')
                .replace(/(\d{5})(\d{3})/, '$1-$2')}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default AddressDetailsFilterCriteriaListItem
