import { FilterCriteria } from './FilterCriteria'

export enum LocalizeFilterCriteriaInfoType {
    DOCUMENTS = 'documents',
    EMAILS = 'emails',
    MOBILES = 'mobiles',
    LANDLINES = 'landlines',
    NAMES = 'names'
}

export const INFO_TYPES = [
  { value: LocalizeFilterCriteriaInfoType.DOCUMENTS, label: 'Documentos' },
    { value: LocalizeFilterCriteriaInfoType.EMAILS, label: 'E-mails' },
    { value: LocalizeFilterCriteriaInfoType.MOBILES, label: 'Celulares' },
    { value: LocalizeFilterCriteriaInfoType.LANDLINES, label: 'Telefones fixos' },
    { value: LocalizeFilterCriteriaInfoType.NAMES, label: 'Nomes' }
]

export type LocalizeFilterCriteria = FilterCriteria & {
  names: string[]
  documents: string[];
  emails: string[];
  mobiles: string[];
  landlines: string[];
  info_type: LocalizeFilterCriteriaInfoType
}
