import BadgeIcon from '@mui/icons-material/Badge'
import { Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { DocumentListFilterCriteria } from '../../../types/DocumentListFilterCriteria'

import { FilterType } from '../../../types/Filter'

export type DocumentListFilterCriteriaListItemProps = {
  criteria: DocumentListFilterCriteria
  filter_type: FilterType
}

const DocumentListFilterCriteriaListItem = ({
  criteria,
  filter_type
}: DocumentListFilterCriteriaListItemProps) => {
  const validator = filter_type === FilterType.NATURAL_PERSONS ? cpf : cnpj

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        minWidth="260px"
        sx={{
          backgroundColor:
            filter_type === FilterType.NATURAL_PERSONS ? '#1976d2' : '#9c27b0',
          minHeight: '50px',
          padding: '10px 20px 10px 10px',
          borderRadius: '10px 0 0 10px',
          borderRight: '1px solid #b4b4b4'
        }}
      >
        <BadgeIcon htmlColor="#fff" fontSize="large" />
        <Typography fontWeight="bold" textTransform="uppercase" color="#fff">
          {`Lista de ${
            filter_type === FilterType.NATURAL_PERSONS ? "CPF's" : "CNPJ's"
          }`}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        {criteria.documents
          .slice(0, 5)
          .map((document: string, index: number, self: any) => {
            return (
              <Typography key={document}>
                {validator.format(document)}
                {index < self.length - 1 && <>, </>}
              </Typography>
            )
          })}
        {criteria.documents.length > 5 && (
          <Chip
            label={`+ ${criteria.documents.length - 1}`}
            variant="filled"
            sx={{
              fontWeight: 'bold',
              marginLeft: '10px'
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default DocumentListFilterCriteriaListItem
