import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useEntityOperations } from "../../hooks/useEntityOperations";
import { User } from "../../types/User";
import { useWebsockets } from "../websockets";

export type UserProviderContext = {
  user?: User;
  terms?: string;
  loading: boolean;
};

const defaultContext = {
  user: undefined,
  loading: true,
};

export const UserContext = createContext<UserProviderContext>(defaultContext);

const UserProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useWebsockets();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const { subscribe } = useEntityOperations("user");

  const [terms, setTerms] = useState<string | undefined>();

  const subscription = useRef<any>();

  useAsyncEffect(async () => {
    if (!client) return;

    setLoading(true);

    client
      .retrieve("user", 0)
      .then((user) => {
        setUser(user);
        if (subscription && user?.id) {
          subscribe(user.id, (changed: any, action: string) => {
            if (action === "update") {
              console.info(`User ${user.id} was updated:`, changed);
              !!changed && setUser(changed);
            }
          })?.then((subc: any) => {
            subscription.current = subc;
          });
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [client, subscription]);

  useAsyncEffect(async () => {
    if (!client) return;
    if (!user) return;
    if (!!user?.terms_and_conditions_accepted_at) return;
    if (user?.is_trial) return;

    setLoading(true);

    client
      .retrieve("site.terms", 0)
      .then((terms) => {
        setTerms(terms?.terms_and_conditions);
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [client, user]);

  useEffect(() => {
    console.log({user, terms})
    if (
      !!user &&
      !user.monthly_subscription &&
      !window.location.href.includes("/subscriptions")
    ) {
      window.location.href = "/subscriptions";
    } else if (
      !!user &&
      !user.terms_and_conditions_accepted_at &&
      !user.is_trial &&
      !!terms &&
      !window.location.href.includes("/terms")
    ) {

      console.log("Deveria redirecionar para os termos de uso");
      window.location.href = "/terms";
    }
  }, [user, terms]);

    
  return (
    <UserContext.Provider
      value={{
        user,
        terms,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
