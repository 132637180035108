import { ContentCopy } from '@mui/icons-material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Alert,
  IconButton,
  Input,
  InputAdornment,
  Snackbar
} from '@mui/material'
import { useState } from 'react'
import Tooltip from '../../../mui/Tooltip'

export type OutputFilePasswordProps = {
  value: string
}

const OutputFilePassword = ({ value }: OutputFilePasswordProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(value)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false)
  }

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          sx={{ width: '100%' }}
          variant="filled"
        >
          A senha foi copiada para a área de transferência!
        </Alert>
      </Snackbar>
      <Input
        id="standard-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={value}
        readOnly
        sx={{
          '&::before': {
            borderBottom: ' transparent none !important'
          },
          fontSize: 'inherit',
          width: '100%'
        }}
        onClick={handleCopyClick}
        startAdornment={
          <InputAdornment position="start">
            <Tooltip title="Clique para copiar a senha... ">
              <IconButton edge="start" onClick={handleCopyClick}>
                <ContentCopy color="primary" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <Tooltip
              title={`Clique para ${
                showPassword ? 'esconder' : 'exibir'
              } a senha... `}
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOff />
                ) : (
                  <Visibility htmlColor="black" />
                )}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </>
  )
}

export default OutputFilePassword
