import { Box } from '@mui/material'
import * as React from 'react'

export type FieldsetProps = {
  legend: string
  children: React.ReactElement
}
export default function Fieldset({ legend, children }: FieldsetProps) {
  return (
    <fieldset
      style={{
        borderRadius: '5px',
        borderColor: 'rgb(255 255 255 / 30%)',
        margin: '0'
      }}
      className="extractor-fieldset"
    >
      <legend
        style={{
          fontSize: '1rem',
          color: 'rgba(0, 0, 0, 0.6) !important',
          padding: '0px 5px',
          marginLeft: '-5px'
        }}
      >
        {legend}
      </legend>
      <Box padding="10px 10px 15px 10px">{children}</Box>
    </fieldset>
  )
}
