import { InputAdornment } from '@mui/material'
import { Box } from '@mui/system'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { TextField } from 'mui-rff'
import { Form, FormSpy } from 'react-final-form'
import { DocumentListFilterCriteria } from '../../../types/DocumentListFilterCriteria'
import { FilterType } from '../../../types/Filter'

import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'

export type EditDocumentListFilterCriteriaProps = {
  criteria: DocumentListFilterCriteria
  onChange: (changed: DocumentListFilterCriteria, isValid: boolean) => void
  filter_type: FilterType
  editable?: boolean
}

// We define our schema based on the same keys as our form:
const schema = Yup.object().shape({
  documents: Yup.array()
    .min(1, 'Informe ao menos um documento válido')
    .max(5000, 'Informe no máximo 5000 documentos')
    .required('Informe uma lista de documentos válidos')
})

const validate = makeValidate(schema)

const EditDocumentListFilterCriteria = ({
  criteria,
  onChange,
  filter_type,
  editable = true
}: EditDocumentListFilterCriteriaProps) => {
  const onSubmit = async (values: any) => {}

  const validator = filter_type === FilterType.NATURAL_PERSONS ? cpf : cnpj
  const num_characters = filter_type === FilterType.NATURAL_PERSONS ? 11 : 14

  return (
    <Box p="30px">
      <Form
        onSubmit={onSubmit}
        initialValues={criteria}
        validate={validate}
        validateOnBlur
        render={({ handleSubmit, values, form }: any) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box
              sx={{
                marginBottom: '10px',
                marginTop: '20px'
              }}
            >
              <TextField
                disabled={!editable}
                variant="outlined"
                label={`Lista de ${
                  filter_type === FilterType.NATURAL_PERSONS ? 'CPF' : 'CNPJ'
                }s`}
                name="documents"
                required={true}
                helperText={`Adicione neste campo uma lista de ${
                  filter_type === FilterType.NATURAL_PERSONS ? 'CPF' : 'CNPJ'
                }s válidos separados por vírgula. Ex.: ${
                  filter_type === FilterType.NATURAL_PERSONS
                    ? '999.999.999-99, 000.000.000-00'
                    : '99.999.999/9999-99, 00.000.000/0000-00'
                }`}
                multiline
                rows={10}
                spellCheck={false}
                onBlur={(e) => {
                  form.change(
                    'documents',
                    values.documents.filter((doc: any) => !!doc)
                  )
                }}
                onPasteCapture={(e) => {
                  let pasted = e.clipboardData.getData('text')
                  pasted = pasted.replace(/\n/g, ',')
                  const parsed: string[] = pasted
                    .split(',')
                    .map((doc): string => {
                      let clean = doc.replace(/[^0-9]/g, '')
                      clean = clean.padStart(num_characters, '0')
                      if (validator.isValid(clean)) {
                        return clean
                      } else {
                        return ''
                      }
                    })
                    .filter((doc: any) => !!doc)

                  form.change('documents', parsed)
                  e.preventDefault()
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '1rem !important'
                  }
                }}
                InputProps={{
                  sx: {
                    '& legend': {
                      fontSize: '13px'
                    }
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      variant="filled"
                      sx={{
                        position: 'absolute',
                        bottom: '-15px',
                        right: '0',
                        fontWeight: 'bold'
                      }}
                    >
                      {values?.documents.length} documento(s) válido(s)
                    </InputAdornment>
                  )
                }}
                FormHelperTextProps={{
                  sx: {
                    fontSize: '12px',
                    marginLeft: 0,
                    marginTop: '5px'
                  }
                }}
                fieldProps={{
                  format: (value) => {
                    let commafied = value
                    if (typeof commafied === 'string') {
                      commafied = commafied.replace(/\n/g, ',')
                      commafied = commafied.split(',')
                    }

                    commafied = commafied
                      .map((doc: string) => {
                        let clean = doc.replace(/[^0-9]/g, '')
                        if (clean.length >= num_characters) {
                          if (validator.isValid(clean)) {
                            return validator.format(clean)
                          } else {
                            return null
                          }
                        } else {
                          return doc
                        }
                      })
                      .filter(
                        (doc: any, index: number, self: any) =>
                          self.indexOf(doc) === index
                      )
                      .join(',')
                    return commafied
                  },
                  parse: (value) => {
                    let parsed = value
                    if (typeof parsed === 'string') {
                      parsed = parsed.replace(/\n/g, ',')
                      parsed = parsed
                        .split(',')
                        .map((doc: string) => doc.replace(/[^0-9]/g, ''))
                        .filter(
                          (doc: any, index: number, self: any) =>
                            self.indexOf(doc) === index
                        )

                      if (parsed.length === 1) {
                        parsed = parsed.filter((doc: any) => !!doc)
                      }
                    }

                    return parsed
                  }
                }}
              />
            </Box>
            {/* <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
            <FormSpy
              subscription={{ values: true, hasValidationErrors: true }}
              onChange={({ values, hasValidationErrors }) => {
                onChange(
                  values as DocumentListFilterCriteria,
                  !hasValidationErrors
                )
              }}
            />
          </form>
        )}
      />
    </Box>
  )
}

export default EditDocumentListFilterCriteria
