import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useWebsockets } from "../../../providers/websockets";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import cp_logo_localize from "../../../cp_logo_localize_roxo.svg";
import cp_logo_geramailing from "../../../cp_logo_geramailing_roxo.svg";
import cp_logo_full_colorido from "../../../cp_logo_full_colorido.svg";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
});

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.contactpro.com.br/">
        Contact Pró
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const { client } = useWebsockets();
  const [hasErrors, setHasErrors] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!client) return;

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    client
      .request("token", {
        action: "obtain",
        data: {
          email: data.get("email"),
          password: data.get("password"),
        },
      })
      .then((result) => {
        localStorage.setItem("access_token", result.data.access);
        localStorage.setItem("refresh_token", result.data.refresh);
        setHasErrors(false);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error({ error });
        setHasErrors(true);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?contacts,marketing,customers,callcenter)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "static",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 2,
              cursor: "pointer",
              display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            }}
          >
            <img
              src={cp_logo_full_colorido}
              className="CP-logo"
              alt="logo"
              height="90"
              style={{ opacity: 0.5 }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={
                window.location.hostname.includes("localize")
                  ? cp_logo_localize
                  : cp_logo_geramailing
              }
              className="App-logo"
              alt="logo"
              height={window.location.hostname.includes("localize") ? "120" : "90"}
            />

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              {hasErrors && (
                <Alert severity="error">E-mail e/ou senha inválidos</Alert>
              )}

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={() => setHasErrors(false)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={() => setHasErrors(false)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Esqueci minha senha
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
