import * as React from "react";
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #dadde9",
    boxShadow: 6,
  },
}));

export default Tooltip;
