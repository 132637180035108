import { Person } from '@mui/icons-material'
import { Chip, Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Ellipsis, EllipsisMode } from 'react-simple-ellipsis'
import { CBO } from '../../../types/CBO'
import { CNAE } from '../../../types/CNAE'
import { CompanySize } from '../../../types/CompanySize'
import { FilterType } from '../../../types/Filter'
import { LegalNature } from '../../../types/LegalNature'
import { LegalPersonRevenueRange } from '../../../types/LegalPersonRevenueRange'
import {
  NAMES_MATCH_STRATEGIES,
  PersonalDetailsFilterCriteria,
  STATUSES
} from '../../../types/PersonalDetailsFilterCriteria'
import { TaxRegime } from '../../../types/TaxRegime'

export type PersonalDetailsFilterCriteriaListItemProps = {
  criteria: PersonalDetailsFilterCriteria
  filter_type: FilterType
}

const PersonalDetailsFilterCriteriaListItem = ({
  criteria,
  filter_type
}: PersonalDetailsFilterCriteriaListItemProps) => {
  console.log({ criteria })
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        minWidth="260px"
        sx={{
          backgroundColor:
            filter_type === FilterType.NATURAL_PERSONS ? '#1976d2' : '#9c27b0',
          minHeight: '50px',
          padding: '10px 20px 10px 10px',
          borderRadius: '10px 0 0 10px',
          borderRight: '1px solid #b4b4b4'
        }}
      >
        <Person fontSize="large" htmlColor="#fff" />
        <Typography fontWeight="bold" textTransform="uppercase" color="#fff">
          {`Filtro de Dados ${
            filter_type === FilterType.NATURAL_PERSONS
              ? 'Pessoais'
              : 'Cadastrais'
          }`}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        {criteria?.names?.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold" marginRight="10px">
              {filter_type === FilterType.NATURAL_PERSONS
                ? 'Nomes'
                : 'Razões Sociais'}
            </Typography>
            {criteria.names.slice(0, 1).map((name) => (
              <Typography key={name}>
                <Ellipsis
                  ellipsis="..."
                  label=""
                  id={name}
                  text={name}
                  limit={200}
                  class="more"
                  mode={EllipsisMode.After}
                />
              </Typography>
            ))}
            {criteria.names.length > 1 && (
              <Chip
                label={`+ ${criteria.names.length - 1}`}
                variant="filled"
                sx={{
                  fontWeight: 'bold',
                  marginLeft: '10px'
                }}
              />
            )}
            <Typography
              key={criteria.names_match_strategy}
              fontWeight="bold"
              fontStyle="italic"
            >
              (
              {
                NAMES_MATCH_STRATEGIES.find(
                  (s) => s.value === criteria.names_match_strategy
                )?.label
              }
              )
            </Typography>
          </>
        )}

        {filter_type === FilterType.NATURAL_PERSONS &&
          criteria?.has_email === true && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                E-mail obrigatório:
              </Typography>
              <Typography>SIM</Typography>
            </>
          )}

        {filter_type === FilterType.LEGAL_PERSONS &&
          (!!criteria?.cnaes && criteria?.cnaes.length) > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                CNAE's:
              </Typography>
              {criteria.cnaes
                .slice(0, 1)
                .map((cnae: CNAE, index: number, self: any) => {
                  return (
                    <Typography key={cnae.code}>
                      {cnae.code.replace(
                        /([0-9]{4})([0-9]{1})([0-9]{2})/gi,
                        '$1-$2/$3'
                      )}
                      {index < self.length - 1 && <>, </>}
                    </Typography>
                  )
                })}
              {criteria.cnaes.length > 1 && (
                <Chip
                  label={`+ ${criteria.cnaes.length - 1}`}
                  variant="filled"
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: '10px'
                  }}
                />
              )}
            </>
          )}

        {filter_type === FilterType.LEGAL_PERSONS &&
          (!!criteria?.presumed_revenue_ranges &&
            criteria?.presumed_revenue_ranges.length) > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                Faixas de Faturamento:
              </Typography>
              {criteria.presumed_revenue_ranges
                .slice(0, 1)
                .map(
                  (
                    revenue_range: LegalPersonRevenueRange,
                    index: number,
                    self: any
                  ) => {
                    return (
                      <Typography key={revenue_range.id}>
                        {revenue_range.description}
                        {index < self.length - 1 && <>, </>}
                      </Typography>
                    )
                  }
                )}
              {criteria.presumed_revenue_ranges.length > 1 && (
                <Chip
                  label={`+ ${criteria.presumed_revenue_ranges.length - 1}`}
                  variant="filled"
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: '10px'
                  }}
                />
              )}
            </>
          )}

        {filter_type === FilterType.LEGAL_PERSONS &&
          (!!criteria?.legal_natures && criteria?.legal_natures.length) > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                Naturezas Jurídicas:
              </Typography>
              {criteria.legal_natures
                .slice(0, 1)
                .map((legal_nature: LegalNature, index: number, self: any) => {
                  return (
                    <Typography key={legal_nature.code}>
                      {legal_nature.description}
                      {index < self.length - 1 && <>, </>}
                    </Typography>
                  )
                })}
              {criteria.legal_natures.length > 1 && (
                <Chip
                  label={`+ ${criteria.legal_natures.length - 1}`}
                  variant="filled"
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: '10px'
                  }}
                />
              )}
            </>
          )}

        {filter_type === FilterType.LEGAL_PERSONS &&
          (!!criteria?.tax_regimes && criteria?.tax_regimes.length) > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                Regimes Tributários:
              </Typography>
              {criteria.tax_regimes
                .slice(0, 1)
                .map((tax_regime: TaxRegime, index: number, self: any) => {
                  return (
                    <Typography key={tax_regime.code}>
                      {tax_regime.description}
                      {index < self.length - 1 && <>, </>}
                    </Typography>
                  )
                })}
              {criteria.tax_regimes.length > 1 && (
                <Chip
                  label={`+ ${criteria.tax_regimes.length - 1}`}
                  variant="filled"
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: '10px'
                  }}
                />
              )}
            </>
          )}

        {filter_type === FilterType.LEGAL_PERSONS &&
          (!!criteria?.company_sizes && criteria?.company_sizes.length) > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                Porte da Empresa:
              </Typography>
              {criteria.company_sizes
                .slice(0, 1)
                .map((company_size: CompanySize, index: number, self: any) => {
                  return (
                    <Typography key={company_size.code}>
                      {company_size.description}
                      {index < self.length - 1 && <>, </>}
                    </Typography>
                  )
                })}
              {criteria.company_sizes.length > 1 && (
                <Chip
                  label={`+ ${criteria.company_sizes.length - 1}`}
                  variant="filled"
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: '10px'
                  }}
                />
              )}
            </>
          )}

        {filter_type === FilterType.LEGAL_PERSONS && !!criteria?.is_mei && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold" marginRight="10px">
              Apenas MEI's
            </Typography>
            <Typography>SIM</Typography>
          </>
        )}

        {filter_type === FilterType.LEGAL_PERSONS && !!criteria?.is_simples && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold" marginRight="10px">
              Apenas Optantes pelo Simples Nacional
            </Typography>
            <Typography>SIM</Typography>
          </>
        )}

        {filter_type === FilterType.NATURAL_PERSONS &&
          (!!criteria?.presumed_revenue_range &&
            criteria?.presumed_revenue_range.length) > 1 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold">Renda Presumida</Typography>
              <Typography>
                Entre{' '}
                {criteria.presumed_revenue_range[0].toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}{' '}
                e{' '}
                {criteria.presumed_revenue_range[1].toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}{' '}
              </Typography>
            </>
          )}

        {filter_type === FilterType.NATURAL_PERSONS &&
          (!!criteria?.cbos && criteria?.cbos.length) > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                CBO's:
              </Typography>
              {criteria.cbos
                .slice(0, 1)
                .map((cbo: CBO, index: number, self: any) => {
                  return (
                    <Typography key={cbo.code}>
                      {cbo.code.replace(/([0-9]{4})([0-9]{2})/gi, '$1-$2')}
                      {index < self.length - 1 && <>, </>}
                    </Typography>
                  )
                })}
              {criteria.cbos.length > 1 && (
                <Chip
                  label={`+ ${criteria.cbos.length - 1}`}
                  variant="filled"
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: '10px'
                  }}
                />
              )}
            </>
          )}

        {filter_type === FilterType.LEGAL_PERSONS &&
          (!!criteria?.statuses && criteria?.statuses.length > 0 ? (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                Situação Cadastral:
              </Typography>
              {criteria.statuses
                .slice(0, 1)
                .map((status: string, index: number, self: any) => {
                  return (
                    <Typography key={status}>
                      {STATUSES.find((s) => s.value === status)?.label}
                      {index < self.length - 1 && <>, </>}
                    </Typography>
                  )
                })}
              {criteria.statuses.length > 1 && (
                <Chip
                  label={`+ ${criteria.statuses.length - 1}`}
                  variant="filled"
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: '10px'
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Divider orientation="vertical" flexItem />
              <Typography fontWeight="bold" marginRight="10px">
                Situação Cadastral:
              </Typography>
              <Typography>Ativa</Typography>
            </>
          ))}

        {!!criteria.age_range && criteria.age_range?.length > 1 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold">
              {filter_type === FilterType.NATURAL_PERSONS
                ? 'Idade'
                : 'Tempo de Atividade'}
              :
            </Typography>
            <Typography>
              Entre {criteria.age_range[0]} e {criteria.age_range[1]} anos
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default PersonalDetailsFilterCriteriaListItem
