import { InputAdornment } from '@mui/material'
import { Box } from '@mui/system'
import sanitize from 'diacritic'
import { TextField } from 'mui-rff'
import { Form, FormSpy } from 'react-final-form'
import { FilterType } from '../../../types/Filter'
import { NameListFilterCriteria } from '../../../types/NameListFilterCriteria'

import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'

export type EditNameListFilterCriteriaProps = {
  criteria: NameListFilterCriteria
  onChange: (changed: NameListFilterCriteria, isValid: boolean) => void
  filter_type: FilterType
  editable?: boolean
}

// We define our schema based on the same keys as our form:
const schema = Yup.object().shape({
  names: Yup.array()
    .min(1, 'Inform ao menos um nome válido')
    .max(100, 'Informe no máximo 100 nomes')
    .required('Informe uma lista de nomes válidos')
})

const validate = makeValidate(schema)

const EditNameListFilterCriteria = ({
  criteria,
  onChange,
  filter_type,
  editable = true
}: EditNameListFilterCriteriaProps) => {
  const onSubmit = async (values: any) => {}

  return (
    <Box p="30px">
      <Form
        onSubmit={onSubmit}
        initialValues={criteria}
        validate={validate}
        validateOnBlur
        render={({ handleSubmit, values, form }: any) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box
              sx={{
                marginBottom: '10px',
                marginTop: '20px'
              }}
            >
              <TextField
                disabled={!editable}
                variant="outlined"
                label={`Lista de ${
                  filter_type === FilterType.NATURAL_PERSONS
                    ? 'Nomes'
                    : 'Razões Sociais'
                }`}
                name="names"
                required={true}
                helperText={`Adicione neste campo uma lista de ${
                  filter_type === FilterType.NATURAL_PERSONS
                    ? 'nomes completos'
                    : 'razões sociais'
                } separados por vírgula. Ex.: ${
                  filter_type === FilterType.NATURAL_PERSONS
                    ? 'JOAO DA SILVA, ANTONIO MACEDO'
                    : 'ACME ACESSORIOS LTDA, ANDALUZIA MATERIAS DE CONTRUCAO ME'
                }`}
                multiline
                rows={10}
                spellCheck={false}
                onBlur={(e) => {
                  form.change(
                    'names',
                    values.names.filter((name: any) => !!name)
                  )
                }}
                onPasteCapture={(e) => {
                  let pasted = e.clipboardData.getData('text')
                  pasted = pasted.replace(/\n/g, ',')
                  const parsed = pasted
                    .split(',')
                    .map((name: string, index: number, self: any): string => {
                      name = sanitize.clean(name).toUpperCase()
                      if (index < self.length - 1 || !name.trim()) {
                        name = name.trim()
                      }

                      if (
                        index === self.length - 1 &&
                        name.trim().length >= 150
                      ) {
                        return ''
                      }

                      return name
                    })
                    .filter(
                      (name: any, index: number, self: any) =>
                        self.indexOf(name) === index
                    )
                    .filter((name: any) => !!name)

                  form.change('names', parsed)
                  e.preventDefault()
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '1rem !important'
                  }
                }}
                InputProps={{
                  sx: {
                    '& legend': {
                      fontSize: '13px'
                    }
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      variant="filled"
                      sx={{
                        position: 'absolute',
                        bottom: '-15px',
                        right: '0',
                        fontWeight: 'bold'
                      }}
                    >
                      {values?.names.length} nome(s) válido(s)
                    </InputAdornment>
                  )
                }}
                FormHelperTextProps={{
                  sx: {
                    fontSize: '12px',
                    marginLeft: 0,
                    marginTop: '5px'
                  }
                }}
                fieldProps={{
                  format: (value) => {
                    let commafied = value
                    if (Array.isArray(commafied)) {
                      commafied = commafied.join(',')
                    }
                    commafied = commafied.replace(/\n/g, ',')
                    return sanitize.clean(commafied).toUpperCase()
                  },
                  parse: (value) => {
                    let parsed = value
                    if (typeof parsed === 'string') {
                      parsed = parsed.replace(/\n/g, ',')
                      parsed = parsed
                        .split(',')
                        .map((name: string, index: number, self: any) => {
                          name = sanitize.clean(name).toUpperCase()
                          if (index < self.length - 1 || !name.trim()) {
                            name = name.trim()
                          }

                          if (
                            index === self.length - 1 &&
                            name.trim().length >= 150
                          ) {
                            return null
                          }

                          return name
                        })
                        .filter(
                          (name: any, index: number, self: any) =>
                            self.indexOf(name) === index
                        )

                      if (parsed.length === 1) {
                        parsed = parsed.filter((name: any) => !!name)
                      }

                      return parsed
                    }
                  }
                }}
              />
            </Box>
            {/* <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
            <FormSpy
              subscription={{ values: true, hasValidationErrors: true }}
              onChange={({ values, hasValidationErrors }) => {
                onChange(values as NameListFilterCriteria, !hasValidationErrors)
              }}
            />
          </form>
        )}
      />
    </Box>
  )
}

export default EditNameListFilterCriteria
