import { CookiesProvider } from 'react-cookie'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import 'setimmediate'
import Extraction from './components/ui/Extraction'
import Extractions from './components/ui/Extractions'
import Filter from './components/ui/Filter'
import Login from './components/ui/Login'
import Subscriptions from './components/ui/Subscriptions'
import AuthenticationProvider from './providers/authentication/AuthenticationProvider'
import UserProvider from './providers/users/UserProvider'
import WebSocketsProvider from './providers/websockets/WebSocketsProvider'
import './styles/globals.css'
import TermsAndConditions from './components/ui/TermsAndConditions'
import { useEffect } from 'react'

function App() {

  useEffect(() => {
    document.title = window.location.hostname.includes('localize') ? 'Localize - Contact Pró' : 'Gera Mailing - Contact Pró'
  }, [])

  return (
    <CookiesProvider>
      <WebSocketsProvider>
        <UserProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/filter"
                element={
                  <AuthenticationProvider>
                    <Filter />
                  </AuthenticationProvider>
                }
              />
              <Route
                path="/subscriptions"
                element={
                  <AuthenticationProvider>
                    <Subscriptions />
                  </AuthenticationProvider>
                }
              />
              <Route
                path="/terms"
                element={
                  <AuthenticationProvider>
                    <TermsAndConditions />
                  </AuthenticationProvider>
                }
              />
              <Route
                path="/extraction/:extraction_id"
                element={
                  <AuthenticationProvider>
                    <Extraction />
                  </AuthenticationProvider>
                }
              />
              <Route
                path="/"
                element={
                  <AuthenticationProvider>
                    <Extractions />
                  </AuthenticationProvider>
                }
              />
            </Routes>
          </Router>
        </UserProvider>
      </WebSocketsProvider>
    </CookiesProvider>
  )
}

export default App
