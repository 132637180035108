import {
  AccessTime,
  Business,
  Download,
  Lock,
  Person,
  Person2,
  Reply
} from '@mui/icons-material'
import AutofpsSelectIcon from '@mui/icons-material/AutofpsSelect'
import BadgeIcon from '@mui/icons-material/Badge'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GridLoader } from 'react-spinners'
import useFitText from 'use-fit-text'
import { useEntityOperations } from '../../../hooks/useEntityOperations'
import { AddressDetailsFilterCriteria } from '../../../types/AddressDetailsFilterCriteria'
import { DocumentListFilterCriteria } from '../../../types/DocumentListFilterCriteria'
import { Extraction as ExtractionType } from '../../../types/Extraction'
import { FilterType } from '../../../types/Filter'
import {
  FilterCriteria,
  FilterCriteriaType
} from '../../../types/FilterCriteria'
import { NameListFilterCriteria } from '../../../types/NameListFilterCriteria'
import { PersonalDetailsFilterCriteria } from '../../../types/PersonalDetailsFilterCriteria'
import { PhoneDetailsFilterCriteria } from '../../../types/PhoneDetailsFilterCriteria'
import EditFullScreenDialog from '../Dialog/EditFullScreenDialog'
import OutputFilePassword from '../Extractions/OutputFilePassword'
import EditAddressDetailsFilterCriteria from '../Filter/EditAddressDetailsFilterCriteria'
import EditDocumentListFilterCriteria from '../Filter/EditDocumentListFilterCriteria'
import EditNameListFilterCriteria from '../Filter/EditNameListFilterCriteria'
import EditPersonalDetailsFilterCriteria from '../Filter/EditPersonalDetailsFilterCriteria'
import EditPhoneDetailsFilterCriteria from '../Filter/EditPhoneDetailsFilterCriteria'
import FilterCriteriaList from '../Filter/FilterCriteriaList'
import Layout from '../Layout'

const ResizeableText = ({ text }: { text: string }) => {
  const { fontSize, ref } = useFitText({ maxFontSize: 1000 })

  return (
    <p
      ref={ref}
      style={{
        fontSize,
        whiteSpace: 'nowrap',
        width: 280,
        textAlign: 'center',
        color: '#666'
      }}
    >
      {text}
    </p>
  )
}

const API_URL = process.env.REACT_APP_API_URL

export default function Extraction() {
  const { loading, isReady, retrieve, subscribe } =
    useEntityOperations('extraction')
  const { request: downloadRequest } = useEntityOperations('download')
  const { extraction_id } = useParams()

  const [extraction, setExtraction] = useState<ExtractionType | null>(null)

  const [open, setOpen] = useState(false)
  const subscription = useRef<any>()
  const extract_update_interval = useRef<any>()

  const [previewing, setPreviewing] = useState<
    | FilterCriteria
    | NameListFilterCriteria
    | DocumentListFilterCriteria
    | PhoneDetailsFilterCriteria
    | AddressDetailsFilterCriteria
    | PersonalDetailsFilterCriteria
  >()

  const handlePreviewFilterCriteria = (criteria: FilterCriteria) => {
    setOpen(true)
    const copy =
      criteria.type === FilterCriteriaType.DOCUMENT_LIST
        ? (criteria as DocumentListFilterCriteria)
        : criteria.type === FilterCriteriaType.NAME_LIST
        ? (criteria as NameListFilterCriteria)
        : criteria.type === FilterCriteriaType.PHONE_DETAILS
        ? (criteria as PhoneDetailsFilterCriteria)
        : criteria.type === FilterCriteriaType.PERSONAL_DETAILS
        ? (criteria as PersonalDetailsFilterCriteria)
        : criteria

    setPreviewing({ ...copy })
  }

  const handleDownload = useCallback(async () => {
    const access_token = localStorage.getItem('access_token')
    axios({
      url: `${API_URL}extraction/${extraction_id}/`,
      method: 'GET',
      responseType: 'blob', // important
      headers: { Authorization: 'Bearer ' + access_token }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const disposition =
        response?.headers && response?.headers['content-disposition']
          ? response.headers['content-disposition'] || ''
          : ''

      const app_name = window.location.hostname.includes('localize') ? 'localize' : 'geramailing';      
      const filename = disposition.replace(/.* filename="(.*)"/, '$1')
      link.setAttribute('download', `${app_name}_${filename}`)
      document.body.appendChild(link)
      link.click()

      const download_id: string | undefined = response.headers['x-download-id']

      !!download_id && downloadRequest(download_id)
    })
  }, [extraction_id, downloadRequest])

  const fetchExtraction = useCallback(async () => {
    if (!!extraction_id && isReady) {
      const instance = await retrieve<ExtractionType>(
        Number.parseInt(extraction_id)
      )

      if (instance) {
        setExtraction(instance)

        if (
          !instance.is_extracting &&
          !!extract_update_interval &&
          !!extract_update_interval.current
        ) {
          clearInterval(extract_update_interval.current)

          if (subscription && subscription.current) {
            subscription.current.cancel()
          }
        }

        if (instance.is_extracting) {
          extract_update_interval.current = setInterval(() => {
            fetchExtraction()
          }, 5000)

          if (subscription) {
            subscription.current = await subscribe(
              instance.id,
              (changed: any, action: string) => {
                if (action === 'update') {
                  console.info(
                    `Extraction ${instance.id} was updated:`,
                    changed
                  )
                  setExtraction(changed)
                  if (
                    !changed.is_extracting &&
                    !!extract_update_interval &&
                    !!extract_update_interval.current
                  ) {
                    clearInterval(extract_update_interval.current)
                  }
                }
              }
            )
          }
        }
      }
    }
  }, [extraction_id, isReady, subscribe, extract_update_interval])

  useEffect(() => {
    if (!extraction?.id) {
      fetchExtraction()
    }
  }, [extraction?.id, subscription, retrieve, subscribe])

  return (
    <Layout>
      {!!extraction && open && previewing && (
        <EditFullScreenDialog
          open
          icon={
            previewing.type === FilterCriteriaType.DOCUMENT_LIST ? (
              <BadgeIcon />
            ) : previewing.type === FilterCriteriaType.NAME_LIST ? (
              <AutofpsSelectIcon />
            ) : previewing.type === FilterCriteriaType.PHONE_DETAILS ? (
              <ContactPhoneIcon />
            ) : previewing.type === FilterCriteriaType.ADDRESS_DETAILS ? (
              <LocationOnIcon />
            ) : previewing.type === FilterCriteriaType.PERSONAL_DETAILS ? (
              <Person />
            ) : (
              <></>
            )
          }
          color={
            extraction.filter.type === FilterType.NATURAL_PERSONS
              ? 'primary'
              : 'secondary'
          }
          title={`Visualizar ${
            previewing.type === FilterCriteriaType.DOCUMENT_LIST
              ? `Filtro de ${
                  extraction.filter.type === FilterType.NATURAL_PERSONS
                    ? "CPF's"
                    : "CNPJ's"
                }`
              : previewing.type === FilterCriteriaType.NAME_LIST
              ? `Filtro de ${
                  extraction.filter.type === FilterType.NATURAL_PERSONS
                    ? 'Nomes'
                    : 'Razões Sociais'
                }`
              : previewing.type === FilterCriteriaType.PHONE_DETAILS
              ? 'Filtro de Telefonia'
              : previewing.type === FilterCriteriaType.ADDRESS_DETAILS
              ? 'Filtro de Endereço'
              : previewing.type === FilterCriteriaType.PERSONAL_DETAILS
              ? `Filtro de Dados ${
                  extraction.filter.type === FilterType.NATURAL_PERSONS
                    ? 'Pessoais'
                    : 'Cadastrais'
                }`
              : ''
          }`}
          autoHeight
          handleClose={() => {
            setOpen(false)
            setPreviewing(undefined)
          }}
          handleSave={() => {}}
          canSave={false}
        >
          {previewing.type === FilterCriteriaType.DOCUMENT_LIST ? (
            <EditDocumentListFilterCriteria
              criteria={previewing as DocumentListFilterCriteria}
              onChange={() => {}}
              filter_type={extraction.filter.type}
              editable={false}
            />
          ) : previewing.type === FilterCriteriaType.NAME_LIST ? (
            <EditNameListFilterCriteria
              criteria={previewing as NameListFilterCriteria}
              onChange={() => {}}
              filter_type={extraction.filter.type}
              editable={false}
            />
          ) : previewing.type === FilterCriteriaType.PHONE_DETAILS ? (
            <EditPhoneDetailsFilterCriteria
              criteria={previewing as PhoneDetailsFilterCriteria}
              onChange={() => {}}
              filter_type={extraction.filter.type}
              editable={false}
            />
          ) : previewing.type === FilterCriteriaType.ADDRESS_DETAILS ? (
            <EditAddressDetailsFilterCriteria
              criteria={previewing as AddressDetailsFilterCriteria}
              onChange={() => {}}
              filter_type={extraction.filter.type}
              editable={false}
            />
          ) : previewing.type === FilterCriteriaType.PERSONAL_DETAILS ? (
            <EditPersonalDetailsFilterCriteria
              criteria={previewing as PersonalDetailsFilterCriteria}
              onChange={() => {}}
              filter_type={extraction.filter.type}
              editable={false}
            />
          ) : (
            <></>
          )}
        </EditFullScreenDialog>
      )}
      {!!extraction && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              justifyItems: 'flex-start',
              flexDirection: 'row',
              color: 'black',
              padding: '20px 20px 0 20px',
              backgroundColor: '#fff',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              flexGrow: 1,
              width: '100%'
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="row"
              alignItems="center"
              sx={{
                width: '100%'
              }}
            >
              <Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="row"
                  alignItems="center"
                >
                  {extraction.filter.type === FilterType.NATURAL_PERSONS ? (
                    <Person2 fontSize="large" />
                  ) : (
                    <Business fontSize="large" />
                  )}
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5em',
                      marginLeft: '10px'
                    }}
                  >
                    Extração - Pessoas{' '}
                    {extraction.filter.type === FilterType.NATURAL_PERSONS
                      ? 'Físicas'
                      : 'Jurídicas'}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="row"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Typography
                    display="flex"
                    justifyContent="center"
                    flexDirection="row"
                    alignItems="center"
                    sx={{
                      fontSize: '1em',
                      marginTop: '5px'
                    }}
                  >
                    <AccessTime
                      sx={{ marginRight: '3px', marginTop: '-2px' }}
                    />
                    <Typography fontWeight="bold" marginRight="5px">
                      SOLICITADA EM:
                    </Typography>
                    {new Date(extraction.created_at).toLocaleString('pt-BR', {
                      dateStyle: 'long',
                      timeStyle: 'short',
                      hour12: false
                    })}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: '1em',
                      marginTop: '5px',
                      marginLeft: '3px',
                      fontStyle: 'italic'
                    }}
                  >
                    (
                    {extraction.is_extracted
                      ? 'Arquivo gerado com sucesso!'
                      : extraction.has_error
                      ? 'Erro na geração do arquivo ...'
                      : 'Aguardando geração do arquivo de extração'}
                    )
                  </Typography>
                </Box>
              </Box>
              <Box marginLeft="auto">
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  startIcon={<Reply />}
                  onClick={() => {
                    window.location.href = '/'
                  }}
                >
                  Voltar para lista de extrações
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            height="calc(100vh - 300px)"
            width="100%"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                justifyItems: 'flex-start',
                flexDirection: 'row',
                color: 'black',
                padding: '20px',
                backgroundColor: '#fff',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                flexGrow: 1,
                width: '100%'
              }}
            >
              <FilterCriteriaList
                filter={extraction.filter}
                handleEdit={handlePreviewFilterCriteria}
                handleDelete={() => {}}
                loading={loading}
                editable={false}
              />
              <Box
                sx={{
                  minWidth: '320px',
                  marginLeft: '30px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
                <Box
                  width="320px"
                  padding="15px 10px"
                  marginTop="-10px"
                  marginBottom="10px"
                  color="white"
                  sx={{
                    borderRadius: '10px',
                    backgroundColor: `${
                      extraction.filter.type === FilterType.NATURAL_PERSONS
                        ? '#1976d2'
                        : '#9c27b0'
                    }`
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.2em',
                      marginLeft: '10px',
                      textAlign: 'center',
                      textTransform: 'uppercase'
                    }}
                  >
                    Total de Registros
                  </Typography>
                </Box>
                <Box
                  sx={{
                    minWidth: '320px',
                    marginLeft: 'auto',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    boxShadow: 3,
                    borderRadius: '10px'
                  }}
                >
                  <Box
                    width="300px"
                    height="160px"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    {extraction.is_extracting ? (
                      <GridLoader size={30} color="#ccc" />
                    ) : extraction.is_extracted && extraction.count ? (
                      <ResizeableText
                        text={new Intl.NumberFormat('pt-BR').format(
                          extraction.count
                        )}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box width="100%" padding="10px" marginTop="auto">
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      color="success"
                      startIcon={<Download />}
                      onClick={handleDownload}
                      disabled={loading || !extraction.is_extracted}
                    >
                      Baixar extração
                    </Button>
                  </Box>
                </Box>
                <Box
                  width="320px"
                  padding="10px"
                  marginTop="10px"
                  sx={{
                    backgroundColor: '#e4e4e4',
                    borderRadius: '10px'
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.2em',
                      marginLeft: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row'
                    }}
                  >
                    <Lock sx={{ marginRight: '5px' }} /> Senha do Arquivo
                  </Typography>
                  <Box padding="15px" sx={{ fontSize: '1rem' }}>
                    <OutputFilePassword
                      value={extraction.output_file_password}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Layout>
  )
}
