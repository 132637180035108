// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*:focus,\n*:focus-within {\n  outline: none !important;\n}\n\na {\n  text-decoration: none !important;\n}\n\n.MuiBox-root\n  form\n  .MuiFormControl-root.MuiTextField-root\n  .MuiInputLabel-root.MuiInputLabel-outlined[data-shrink='false'] {\n  font-size: '10px !important';\n}\n\n.extractor-fieldset:hover {\n  border: 1px solid #000 !important;\n}\n\n.extractor-fieldset:focus-within {\n  border: 2px solid #1976d2 !important;\n}\n\n.extractor-fieldset legend {\n  color: rgba(0, 0, 0, 0.6) !important;\n}\n\n.extractor-fieldset:focus-within legend {\n  color: #1976d2 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/globals.css"],"names":[],"mappings":"AAEA;;EAEE,wBAAwB;AAC1B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;;;;EAIE,4BAA4B;AAC9B;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');\n\n*:focus,\n*:focus-within {\n  outline: none !important;\n}\n\na {\n  text-decoration: none !important;\n}\n\n.MuiBox-root\n  form\n  .MuiFormControl-root.MuiTextField-root\n  .MuiInputLabel-root.MuiInputLabel-outlined[data-shrink='false'] {\n  font-size: '10px !important';\n}\n\n.extractor-fieldset:hover {\n  border: 1px solid #000 !important;\n}\n\n.extractor-fieldset:focus-within {\n  border: 2px solid #1976d2 !important;\n}\n\n.extractor-fieldset legend {\n  color: rgba(0, 0, 0, 0.6) !important;\n}\n\n.extractor-fieldset:focus-within legend {\n  color: #1976d2 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
