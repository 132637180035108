import { useEffect, useState } from "react";
import Layout from "../Layout";

import { Launch } from "@mui/icons-material";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
  ptBR,
} from "@mui/x-data-grid";
import { useEntityOperations } from "../../../hooks/useEntityOperations";
import { Extraction } from "../../../types/Extraction";
import { FilterType } from "../../../types/Filter";

const theme = createTheme({}, ptBR);

const columns = (): GridColumns<Extraction> => {
  return [
    {
      field: "id",
      headerName: "TIPO DA EXTRAÇÃO",
      flex: true,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<number>) => {
        return (
          <>
            {params.row.filter.type === FilterType.NATURAL_PERSONS
              ? "Pessoas Físicas"
              : "Pessoas Jurídicas"}
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "SOLICITADA EM",
      flex: true,
      editable: false,
      type: "dateTime",
      valueGetter: ({ value }) =>
        value &&
        new Date(value).toLocaleString("pt-BR", {
          dateStyle: "long",
          timeStyle: "short",
          hour12: false,
        }),
    },
    {
      field: "is_extracted",
      headerName: "STATUS DA EXTRAÇÃO",
      flex: true,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<number>) => {
        return (
          <>
            {params.row.has_error
              ? "ERRO NA GERAÇÃO"
              : params.row.is_extracting
              ? "GERANDO ARQUIVO"
              : "ARQUIVO GERADO"}
          </>
        );
      },
    },
    {
      field: "count",
      headerName: "NÚMERO DE REGISTROS",
      flex: true,
      minWidth: 200,
      maxWidth: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<number>) => {
        return <>{new Intl.NumberFormat("pt-BR").format(params.row.count)}</>;
      },
    },
    // {
    //   field: 'downloads',
    //   headerName: 'Último download em',
    //   flex: true,
    //   editable: false,
    //   renderCell: (params: GridRenderCellParams<number>) => (
    //     <>
    //       {params.row.downloads.length ? (
    //         <>
    //           <Typography fontSize="1rem" marginRight="10px">
    //             {new Date(
    //               params.row.downloads[
    //                 params.row.downloads.length - 1
    //               ].finished_at
    //             ).toLocaleString('pt-BR', {
    //               dateStyle: 'long',
    //               timeStyle: 'short',
    //               hour12: false
    //             })}
    //           </Typography>
    //           <i>
    //             (IP:{' '}
    //             {
    //               params.row.downloads[params.row.downloads.length - 1]
    //                 .ip_address
    //             }
    //             )
    //           </i>
    //         </>
    //       ) : (
    //         <>Nenhum download realizado</>
    //       )}
    //     </>
    //   )
    // },
    {
      field: "is_extracting",
      headerName: "",
      minWidth: 200,
      maxWidth: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<number>) => {
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Launch />}
            onClick={() => {
              window.location.href = `/extraction/${params.row.id}`;
            }}
          >
            Ver detalhes
          </Button>
        );
      },
    },
  ] as GridColDef[];
};

const pageSize = 20;

export default function Extractions() {
  const { loading, isReady, paginate } = useEntityOperations("extraction");
  const { request: downloadRequest } = useEntityOperations("download");
  const [items, setItems] = useState<Extraction[]>([]);
  const [pager, setPager] = useState<any>({
    page: 1,
    pageSize: pageSize,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "desc" },
  ]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const loadExtractions = async () => {
    const params = {
      order: sortModel.map(
        (model) => `${model.sort === "desc" ? "-" : ""}${model.field}`
      ),
      pager,
    };

    const result = await paginate<Extraction>(params);

    if (result) {
      setPager(result.pager);
      setItems(result.list);
    }
  };

  useEffect(() => {
    isReady && loadExtractions();
  }, [sortModel, isReady, pager?.page]);

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <Box height="calc(100vh - 200px)">
          <DataGrid
            sx={{
              backgroundColor: "white",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "lightgray",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              },
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
            }}
            rows={items}
            columns={columns()}
            rowsPerPageOptions={[30]}
            checkboxSelection={false}
            density="standard"
            pageSize={pageSize}
            rowCount={pager.count}
            paginationMode="server"
            onPageChange={(newPage) => {
              if (newPage + 1 !== pager.page) {
                setPager({
                  ...pager,
                  page: newPage + 1,
                });
              }
            }}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            loading={loading}
            disableColumnFilter
            disableColumnSelector
            disableSelectionOnClick
          />
        </Box>
      </ThemeProvider>
    </Layout>
  );
}
