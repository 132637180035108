import dcrf, { DCRFClient } from 'dcrf-client'
import { createContext, ReactNode, useEffect, useState } from 'react'

const WS_URL = process.env.REACT_APP_WS_URL

export type WebSocketsProviderContext = {
  client?: DCRFClient
}

const defaultContext = {
  client: undefined
}

export const WebSocketsContext =
  createContext<WebSocketsProviderContext>(defaultContext)

const WebSocketsProvider = ({ children }: { children: ReactNode }) => {
  const [client, setClient] = useState<DCRFClient>()

  useEffect(() => {
    const access_token = localStorage.getItem('access_token')
    const instance = dcrf.connect(WS_URL + '?token=' + access_token)
    instance.transport.on('connect', () => {
      setClient(instance)
    })
  }, [])

  return (
    <WebSocketsContext.Provider
      value={{
        client
      }}
    >
      {children}
    </WebSocketsContext.Provider>
  )
}

export default WebSocketsProvider
