import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Box, Divider, IconButton } from '@mui/material'
import { Stack } from '@mui/system'
import {
  FilterCriteria,
  FilterCriteriaType
} from '../../../types/FilterCriteria'

import { Preview } from '@mui/icons-material'
import { AddressDetailsFilterCriteria } from '../../../types/AddressDetailsFilterCriteria'
import { DocumentListFilterCriteria } from '../../../types/DocumentListFilterCriteria'
import { FilterType } from '../../../types/Filter'
import { NameListFilterCriteria } from '../../../types/NameListFilterCriteria'
import { PersonalDetailsFilterCriteria } from '../../../types/PersonalDetailsFilterCriteria'
import { PhoneDetailsFilterCriteria } from '../../../types/PhoneDetailsFilterCriteria'
import AddressDetailsFilterCriteriaListItem from './AddressDetailsFilterCriteriaListItem'
import DocumentListFilterCriteriaListItem from './DocumentListFilterCriteriaListItem'
import NameListFilterCriteriaListItem from './NameListFilterCriteriaListItem'
import PersonalDetailsFilterCriteriaListItem from './PersonalDetailsFilterCriteriaListItem'
import PhoneDetailsFilterCriteriaListItem from './PhoneDetailsFilterCriteriaListItem'
import LocalizeFilterCriteriaListItem from './LocalizeFilterCriteriaListItem'
import { LocalizeFilterCriteria } from '../../../types/LocalizeFilterCriteria'

export type FilterCriteriaListItemProps = {
  criteria: FilterCriteria
  handleEdit: (criteria: FilterCriteria) => void
  handleDelete: (criteria: FilterCriteria) => void
  loading: boolean
  filter_type: FilterType
  editable?: boolean
}

const FilterCriteriaListItem = ({
  criteria,
  handleEdit,
  handleDelete,
  loading,
  filter_type,
  editable = true
}: FilterCriteriaListItemProps) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="stretch"
      overflow="hidden"
      marginBottom="10px"
    >
      <Box>
        {criteria.type === FilterCriteriaType.DOCUMENT_LIST ? (
          <DocumentListFilterCriteriaListItem
            criteria={criteria as DocumentListFilterCriteria}
            filter_type={filter_type}
          />
        ) : criteria.type === FilterCriteriaType.NAME_LIST ? (
          <NameListFilterCriteriaListItem
            criteria={criteria as NameListFilterCriteria}
            filter_type={filter_type}
          />
        ) : criteria.type === FilterCriteriaType.PHONE_DETAILS ? (
          <PhoneDetailsFilterCriteriaListItem
            criteria={criteria as PhoneDetailsFilterCriteria}
            filter_type={filter_type}
          />
        ) : criteria.type === FilterCriteriaType.ADDRESS_DETAILS ? (
          <AddressDetailsFilterCriteriaListItem
            criteria={criteria as AddressDetailsFilterCriteria}
            filter_type={filter_type}
          />
        ) : criteria.type === FilterCriteriaType.PERSONAL_DETAILS ? (
          <PersonalDetailsFilterCriteriaListItem
            criteria={criteria as PersonalDetailsFilterCriteria}
            filter_type={filter_type}
          />
        ) : criteria.type === FilterCriteriaType.LOCALIZE ? (
          <LocalizeFilterCriteriaListItem
            criteria={criteria as LocalizeFilterCriteria}
            filter_type={filter_type}
          />
        ) : (
          <></>
        )}
      </Box>
      <Stack direction="row" spacing={0} marginLeft="auto !important">
        <Divider orientation="vertical" flexItem />
        <IconButton
          onClick={() => handleEdit(criteria)}
          disabled={loading}
          sx={{
            borderRadius: 0,
            '&:hover': {
              color: 'blue'
            }
          }}
        >
          {editable ? <EditIcon /> : <Preview />}
        </IconButton>
        {editable && (
          <>
            <Divider orientation="vertical" flexItem />
            <IconButton
              onClick={() => handleDelete(criteria)}
              disabled={loading}
              sx={{ borderRadius: 0, '&:hover': { color: 'red' } }}
            >
              <RemoveCircleIcon />
            </IconButton>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default FilterCriteriaListItem
