import { Person } from "@mui/icons-material";
import { Chip, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Ellipsis, EllipsisMode } from "react-simple-ellipsis";
import { CBO } from "../../../types/CBO";
import { CNAE } from "../../../types/CNAE";
import { FilterType } from "../../../types/Filter";
import {
  INFO_TYPES,
  LocalizeFilterCriteria,
  LocalizeFilterCriteriaInfoType,
} from "../../../types/LocalizeFilterCriteria";

export type LocalizeFilterCriteriaListItemProps = {
  criteria: LocalizeFilterCriteria;
  filter_type: FilterType;
};

const LocalizeFilterCriteriaListItem = ({
  criteria,
  filter_type,
}: LocalizeFilterCriteriaListItemProps) => {

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        minWidth="260px"
        sx={{
          backgroundColor:
            filter_type === FilterType.NATURAL_PERSONS ? "#1976d2" : "#9c27b0",
          minHeight: "50px",
          padding: "10px 20px 10px 10px",
          borderRadius: "10px 0 0 10px",
          borderRight: "1px solid #b4b4b4",
        }}
      >
        <Person fontSize="large" htmlColor="#fff" />
        <Typography fontWeight="bold" textTransform="uppercase" color="#fff">
          {`Critérios de Filtro`}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        {criteria.info_type === LocalizeFilterCriteriaInfoType.NAMES && criteria?.names?.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold" marginRight="10px">
              {filter_type === FilterType.NATURAL_PERSONS
                ? "Nomes"
                : "Razões Sociais"}
            </Typography>
            {criteria.names.slice(0, 1).map((name) => (
              <Typography key={name}>
                <Ellipsis
                  ellipsis="..."
                  label=""
                  id={name}
                  text={name}
                  limit={200}
                  class="more"
                  mode={EllipsisMode.After}
                />
              </Typography>
            ))}
            {criteria.names.length > 1 && (
              <Chip
                label={`+ ${criteria.names.length - 1}`}
                variant="filled"
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              />
            )}
          </>
        )}
        {criteria.info_type === LocalizeFilterCriteriaInfoType.EMAILS &&  criteria?.emails?.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold" marginRight="10px">
              E-mails
            </Typography>
            {criteria.emails.slice(0, 1).map((name) => (
              <Typography key={name}>
                <Ellipsis
                  ellipsis="..."
                  label=""
                  id={name}
                  text={name}
                  limit={200}
                  class="more"
                  mode={EllipsisMode.After}
                />
              </Typography>
            ))}
            {criteria.emails.length > 1 && (
              <Chip
                label={`+ ${criteria.emails.length - 1}`}
                variant="filled"
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              />
            )}
          </>
        )}
        {criteria.info_type === LocalizeFilterCriteriaInfoType.DOCUMENTS && criteria?.documents?.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold" marginRight="10px">
              {filter_type === FilterType.NATURAL_PERSONS
                ? "CPF's"
                : "CNPJ's"}
            </Typography>
            {criteria.documents.slice(0, 1).map((name) => (
              <Typography key={name}>
                <Ellipsis
                  ellipsis="..."
                  label=""
                  id={name}
                  text={name}
                  limit={200}
                  class="more"
                  mode={EllipsisMode.After}
                />
              </Typography>
            ))}
            {criteria.documents.length > 1 && (
              <Chip
                label={`+ ${criteria.documents.length - 1}`}
                variant="filled"
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              />
            )}
          </>
        )}
        {criteria.info_type === LocalizeFilterCriteriaInfoType.MOBILES && criteria?.mobiles?.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold" marginRight="10px">
             Celulares
            </Typography>
            {criteria.mobiles.slice(0, 1).map((name) => (
              <Typography key={name}>
                <Ellipsis
                  ellipsis="..."
                  label=""
                  id={name}
                  text={name}
                  limit={200}
                  class="more"
                  mode={EllipsisMode.After}
                />
              </Typography>
            ))}
            {criteria.mobiles.length > 1 && (
              <Chip
                label={`+ ${criteria.mobiles.length - 1}`}
                variant="filled"
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              />
            )}
          </>
        )}
        {criteria.info_type === LocalizeFilterCriteriaInfoType.LANDLINES && criteria?.landlines?.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold" marginRight="10px">
              Telefones Fixos
            </Typography>
            {criteria.landlines.slice(0, 1).map((name) => (
              <Typography key={name}>
                <Ellipsis
                  ellipsis="..."
                  label=""
                  id={name}
                  text={name}
                  limit={200}
                  class="more"
                  mode={EllipsisMode.After}
                />
              </Typography>
            ))}
            {criteria.landlines.length > 1 && (
              <Chip
                label={`+ ${criteria.landlines.length - 1}`}
                variant="filled"
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default LocalizeFilterCriteriaListItem;
