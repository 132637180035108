import { createContext, ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useWebsockets } from "../websockets";

export type AuthenticationProviderContext = {
  logout: () => void;
};

const defaultContext = {
  logout: () => {},
};

export const AuthenticationContext =
  createContext<AuthenticationProviderContext>(defaultContext);

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useWebsockets();

  const query = useQuery();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("access_token");
    // window.location.href = `https://rastreadordeprocessos.contactpro.com.br/?error=tokens_invalid&return_url=${window.location.href}`
    window.location.href = "/login";
  };

  const validateTokens = () => {
    if (!client) return;

    const token = localStorage.getItem("token");
    const access_token = localStorage.getItem("access_token");

    const params = {
      token,
      access_token,
    };

    if (access_token) {
      client
        .request("token", {
          action: "validate",
          data: params,
        })
        .then((result) => {
          if (!result.data?.valid) {
            localStorage.removeItem("token");
            localStorage.removeItem("access_token");
            // window.location.href = `https://rastreadordeprocessos.contactpro.com.br/?error=tokens_invalid&return_url=${window.location.href}`
            window.location.href = "/login";
          }
        })
        .catch((error) => {
          console.log({ error, entity: "token", action: "validate", params });
        });
    } else {
      // window.location.href = `https://rastreadordeprocessos.contactpro.com.br/?error=tokens_absent&return_url=${window.location.href}`
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    const token = query.get("t");
    const access_token = query.get("at");

    if (token && access_token) {
      localStorage.setItem("token", token);
      localStorage.setItem("access_token", access_token);
      window.location.href = "/";
    } else if (token || access_token) {
      window.location.href = "/";
    } else {
      validateTokens();
    }
  }, [query, client]);

  return (
    <AuthenticationContext.Provider
      value={{
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
