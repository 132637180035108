import ChevronLeftTwoTone from "@mui/icons-material/ChevronLeftTwoTone";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ChevronRightTwoTone from "@mui/icons-material/ChevronRightTwoTone";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Logout from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ptBR } from "@mui/material/locale";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR as ptBRLocale } from "date-fns/locale";
import * as React from "react";
import cp_logo_full_colorido from "../../../cp_logo_full_colorido.svg";
import cp_logo_full_branco_e_preto from "../../../cp_logo_full_branco_e_preto.svg";
import cp_icone_branco_e_preto from "../../../cp_icone_branco_e_preto.svg";
import cp_icone_branco_e_rosa from "../../../cp_icone_branco_e_rosa.svg";
import cp_logo_localize from "../../../cp_logo_localize_roxo.svg";
import cp_logo_geramailing from "../../../cp_logo_geramailing_roxo.svg";

import { InfoRounded } from "@mui/icons-material";
import { Link } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import { useAuthentication } from "../../../providers/authentication/useAuthentication";
import { useUser } from "../../../providers/users/useUser";
import { LayoutProps } from "../../../types/Components";
import BalanceDisplay from "../BalanceDisplay";

declare module "@mui/material/styles" {
  interface ThemeOptions {
    [key: string]: any; //
  }
}

const drawerWidth: number = 265;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const NavDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: window.location.hostname.includes("localize")
      ? "#9553a4"
      : "#1e1e2d",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const font = "Poppins,Helvetica,sans-serif";

const mdTheme = createTheme(
  {
    typography: {
      fontFamily: font,
      fontSize: 11,
    },
    pallete: {
      primary: {
        main: "#9553a4",
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          sx: {
            background: "#fff",
            "& .MuiInputLabel-root": {
              fontSize: "1.1rem",
              color: "black",
            },
            "& .MuiInputLabel-asterisk": {
              fontWeight: "bold",
              color: "black",
            },
            "& .MuiInputBase-input": {
              fontWeight: "normal",
            },
          },
        },
      },
    },
  },
  ptBR
);

export default function Layout({ children, title }: LayoutProps) {
  const [navOpen, setNavOpen] = React.useState<boolean>(false);
  const { user } = useUser();
  const { logout } = useAuthentication();

  const current_app = React.useMemo(() => {
    return window.location.hostname;
  }, []);

  const toggleNavDrawer = () => {
    const newState = !navOpen;
    setNavOpen(!navOpen);
    localStorage.setItem("navOpen", String(newState ? 1 : 0));
  };

  React.useEffect(() => {
    const navOpenStorage = Number.parseInt(
      localStorage.getItem("navOpen") || "0"
    );
    setNavOpen(Boolean(navOpenStorage));
  }, []);

  const activate_subscription = React.useMemo(() => {
    return `https://wa.me/5548984960268/?text=Olá, estou no período de testes e gostaria de ativar a minha assinatura do *${
      current_app.includes("localize") ? "Localize" : "Gera Mailing"
    }*: e-mail *${user?.email}*`;
  }, [user, current_app]);

  return (
    <ThemeProvider theme={mdTheme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ptBRLocale}
      >
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="absolute"
            open={navOpen}
            sx={{
              boxShadow: 0,
              backgroundColor: "transparent",
            }}
          >
            <Toolbar
              sx={{
                padding: "0 0 0 0 !important",
                alignItems: "flex-start",
                "& .MuiBox-root": {
                  backgroundColor: "#e4e4f4",
                },
              }}
            >
              <img
                src={
                  window.location.hostname.includes("localize")
                    ? cp_icone_branco_e_preto
                    : cp_icone_branco_e_rosa
                }
                height="45px"
                alt="logo"
                style={{
                  marginRight: "auto",
                  marginLeft: "12px",
                  marginTop: "9px",
                  ...(navOpen && { display: "none" }),
                }}
              />

              <Box
                sx={{
                  margin: "0 0 0 15px",
                  ...(navOpen && { margin: "0 0 0 0" }),
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#efeff8",
                  width: "100%",
                  padding: "10px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  aria-label="open drawer"
                  onClick={toggleNavDrawer}
                  sx={{
                    marginRight: "0",
                    padding: "0",
                    marginLeft: "-6px",
                    ...(navOpen && { display: "none" }),
                  }}
                >
                  <ChevronRightTwoTone
                    htmlColor={
                      window.location.hostname.includes("localize")
                        ? "#9553a4"
                        : "#1e1e2d"
                    }
                    fontSize="large"
                  />
                </IconButton>

                <img
                  src={
                    window.location.hostname.includes("localize")
                      ? cp_logo_localize
                      : cp_logo_geramailing
                  }
                  height="45px"
                  alt="logo"
                  style={{
                    marginRight: "auto",
                    marginLeft: navOpen ? "20px" : "0px",
                  }}
                />

                <BalanceDisplay />
              </Box>
            </Toolbar>
          </AppBar>
          <NavDrawer variant="permanent" open={navOpen}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0 25px",
              }}
            >
              {navOpen ? (
                <>
                  <img
                    src={
                      window.location.hostname.includes("localize")
                        ? cp_logo_full_branco_e_preto
                        : cp_logo_full_colorido
                    }
                    width="180"
                    className="App-logo"
                    alt="logo"
                  />
                  <IconButton
                    onClick={toggleNavDrawer}
                    sx={{
                      position: "relative",
                      right: "-5px",
                    }}
                  >
                    <ChevronLeftTwoTone htmlColor="#fff" fontSize="large" />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
            </Toolbar>
            <Divider />
            <List
              component="nav"
              sx={{
                marginLeft: "5px",
              }}
            >
              {/* <Link href="https://rastreadordeprocessos.contactpro.com.br/">
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: '35px' }}>
                    <Search htmlColor={window.location.hostname.includes('localize')? '#652b36': "#3699ff"} fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Monitoramentos"
                    sx={{
                      color: '#fff',
                      ...(!navOpen && { display: 'none' })
                    }}
                  />
                </ListItemButton>
              </Link>
              <Link href="https://rastreadordeprocessos.contactpro.com.br/relatorios">
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: '35px' }}>
                    <FileCopy htmlColor={window.location.hostname.includes('localize')? '#652b36': "#3699ff"} fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Relatorios"
                    sx={{
                      color: '#fff',
                      ...(!navOpen && { display: 'none' })
                    }}
                  />
                </ListItemButton>
              </Link> */}
              <RouterLink to="/subscriptions">
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    <SettingsIcon
                      htmlColor="#fff"
                      fontSize={navOpen ? "medium" : "large"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Minha Assinatura"
                    sx={{
                      color: "#fff",
                      "& .MuiTypography-root": {
                        fontSize: "0.9rem",
                      },
                      ...(!navOpen && { display: "none" }),
                    }}
                  />
                </ListItemButton>
              </RouterLink>
              <RouterLink to="/">
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    <ListAltIcon
                      htmlColor="#fff"
                      fontSize={navOpen ? "medium" : "large"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Minhas Extrações"
                    sx={{
                      color: "#fff",
                      "& .MuiTypography-root": {
                        fontSize: "0.9rem",
                      },
                      ...(!navOpen && { display: "none" }),
                    }}
                  />
                </ListItemButton>
              </RouterLink>
              <RouterLink to="/filter">
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    <FilterAltIcon
                      htmlColor="#fff"
                      fontSize={navOpen ? "medium" : "large"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      window.location.hostname.includes("localize")
                        ? "Nova Busca"
                        : "Nova Extração"
                    }
                    sx={{
                      color: "#fff",
                      "& .MuiTypography-root": {
                        fontSize: "0.9rem",
                      },
                      ...(!navOpen && { display: "none" }),
                    }}
                  />
                </ListItemButton>
              </RouterLink>
              <RouterLink to="/instrucoes.pdf" target="_blank">
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    <HelpCenterIcon
                      htmlColor="#fff"
                      fontSize={navOpen ? "medium" : "large"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Instruções"
                    sx={{
                      color: "#fff",
                      "& .MuiTypography-root": {
                        fontSize: "0.9rem",
                      },
                      ...(!navOpen && { display: "none" }),
                    }}
                  />
                </ListItemButton>
              </RouterLink>
              <Link onClick={logout}>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    <Logout
                      htmlColor="#fff"
                      fontSize={navOpen ? "medium" : "large"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Sair"
                    sx={{
                      color: "#fff",
                      "& .MuiTypography-root": {
                        fontSize: "0.9rem",
                      },
                      ...(!navOpen && { color: "transparent" }),
                    }}
                  />
                </ListItemButton>
              </Link>
              <Divider sx={{ my: 1 }} />
            </List>
          </NavDrawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            {user?.is_trial && (
              <Box
                textAlign="left"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                  backgroundColor: "#ffff009c",
                  marginTop: "10px",
                  marginX: "30px",
                  padding: "20px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                <InfoRounded fontSize="large" sx={{ marginRight: "10px" }} />
                {user?.first_name}, Você está no período de testes.{" "}
                <Link
                  sx={{
                    cursor: "pointer",
                  }}
                  href={activate_subscription}
                  target="_blank"
                  color="primary"
                  fontWeight="bold"
                  marginLeft="10px"
                >
                  Solicite já a ativação da sua assinatura!
                </Link>
              </Box>
            )}
            <Box>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ m: 4, width: "100%", position: "relative" }}>
                  {children}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
