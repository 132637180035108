import { Box } from '@mui/system'
import { Autocomplete, Select } from 'mui-rff'
import { useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import {
  PhoneDetailsFilterCriteria,
  PhoneDetailsFilterCriteriaType
} from '../../../types/PhoneDetailsFilterCriteria'

import { MenuItem } from '@mui/material'
import { useEntityOperations } from '../../../hooks/useEntityOperations'
import { AreaCode } from '../../../types/AreaCode'
import { FilterType } from '../../../types/Filter'
import { PhoneOperator } from '../../../types/PhoneOperator'

import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'

export type EditPhoneDetailsFilterCriteriaProps = {
  criteria: PhoneDetailsFilterCriteria
  onChange: (changed: PhoneDetailsFilterCriteria, isValid: boolean) => void
  filter_type: FilterType
  editable?: boolean
}

// We define our schema based on the same keys as our form:
const schema = Yup.object().shape({
  phone_type: Yup.string().required('Informe um tipo de telefone válido')
})

const validate = makeValidate(schema)

const EditPhoneDetailsFilterCriteria = ({
  criteria,
  onChange,
  filter_type,
  editable = true
}: EditPhoneDetailsFilterCriteriaProps) => {
  const { list: listOperators } = useEntityOperations('contact.operators')
  const { list: listAreacodes } = useEntityOperations('contact.areacodes')

  const [operators, setOperators] = useState<PhoneOperator[]>([])
  const [areacodes, setAreacodes] = useState<AreaCode[]>([])

  const onSubmit = async (values: any) => {}

  useEffect(() => {
    const loadDependencies = async () => {
      const ops = await listOperators<PhoneOperator>()
      !!ops && setOperators(ops)

      const codes = await listAreacodes<AreaCode>()
      !!codes && setAreacodes(codes)
    }

    !operators.length && !areacodes.length && loadDependencies()
  }, [listOperators, listAreacodes, areacodes, operators])

  return (
    <Box p="30px">
      <Form
        onSubmit={onSubmit}
        initialValues={criteria}
        validate={validate}
        validateOnBlur
        render={({ handleSubmit, values, form }: any) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box
              sx={{
                marginBottom: '10px',
                marginTop: '20px'
              }}
            >
              {/* <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
              <Select
                disabled={!editable}
                variant="outlined"
                label="Tipo de Telefone"
                name="phone_type"
                required
                inputLabelProps={{
                  sx: {
                    fontSize: '1rem !important'
                  }
                }}
                sx={{
                  '& legend': {
                    fontSize: '13px !important'
                  }
                }}
                formHelperTextProps={{
                  sx: {
                    fontSize: '12px',
                    marginLeft: 0,
                    marginTop: '5px'
                  }
                }}
              >
                <MenuItem value={PhoneDetailsFilterCriteriaType.PHONE_TYPE_ANY}>
                  Qualquer um
                </MenuItem>
                <MenuItem
                  value={PhoneDetailsFilterCriteriaType.PHONE_TYPE_BOTH}
                >
                  Todos
                </MenuItem>
                <MenuItem
                  value={PhoneDetailsFilterCriteriaType.PHONE_TYPE_MOBILE}
                >
                  Celulares
                </MenuItem>
                <MenuItem
                  value={PhoneDetailsFilterCriteriaType.PHONE_TYPE_LANDLINE}
                >
                  Fixos
                </MenuItem>
              </Select>

              <Autocomplete
                sx={{
                  marginTop: '30px',
                  '& label': {
                    color: 'rgba(0, 0, 0, 0.6) !important',
                    fontSize: '1rem !important'
                  },
                  '& legend': {
                    fontSize: '13px'
                  }
                }}
                name="areacodes"
                label="Lista de DDD's"
                options={areacodes}
                fullWidth
                multiple
                getOptionLabel={(option) => `${option.code} - ${option.state}`}
                value={criteria.areacodes || []}
                disabled={!editable}
              />

              <Autocomplete
                disabled={!editable}
                sx={{
                  marginTop: '20px',
                  '& label': {
                    color: 'rgba(0, 0, 0, 0.6) !important',
                    fontSize: '1rem !important'
                  },
                  '& legend': {
                    fontSize: '13px'
                  }
                }}
                name="operators"
                label="Lista de Operadoras"
                options={operators}
                fullWidth
                multiple
                getOptionLabel={(option) => option.name}
                value={criteria.operators || []}
              />
            </Box>
            <FormSpy
              subscription={{ values: true, hasValidationErrors: true }}
              onChange={({ values, hasValidationErrors }) => {
                values.operators_ids = (
                  values as PhoneDetailsFilterCriteria
                ).operators.map((entry) => entry.id)
                values.areacodes_ids = (
                  values as PhoneDetailsFilterCriteria
                ).areacodes.map((entry) => entry.code)
                onChange(
                  values as PhoneDetailsFilterCriteria,
                  !hasValidationErrors
                )
              }}
            />
          </form>
        )}
      />
    </Box>
  )
}

export default EditPhoneDetailsFilterCriteria
