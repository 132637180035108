import { InputAdornment, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import sanitize from "diacritic";
import { Select, TextField } from "mui-rff";
import { Form, FormSpy } from "react-final-form";
import { FilterType } from "../../../types/Filter";
import {
  LocalizeFilterCriteria,
  LocalizeFilterCriteriaInfoType,
} from "../../../types/LocalizeFilterCriteria";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { validate as validate_email } from "react-email-validator";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";

import _ from "lodash";
import { makeValidate } from "mui-rff";

import * as Yup from "yup";

export type EditLocalizeFilterCriteriaProps = {
  criteria: LocalizeFilterCriteria;
  onChange: (changed: LocalizeFilterCriteria, isValid: boolean) => void;
  filter_type: FilterType;
  editable?: boolean;
};

Yup.addMethod(Yup.object, "atLeastOneOf", function () {
  return this.test({
    name: "atLeastOneOf",
    message:
      "Informe ao menos um filtro dentre as opções disponíveis no formulário",
    exclusive: true,
    test: (value) => {
      return (
        value.names.length > 0 ||
        value.age_range.length > 0 ||
        value.cnaes.length > 0 ||
        value.cbos.length > 0 ||
        value.statuses.length > 0
      );
    },
  });
});

const EditLocalizeFilterCriteria = ({
  criteria,
  onChange,
  filter_type,
  editable = true,
}: EditLocalizeFilterCriteriaProps) => {
  const validator = filter_type === FilterType.NATURAL_PERSONS ? cpf : cnpj;
  const num_characters = filter_type === FilterType.NATURAL_PERSONS ? 11 : 14;

  const onSubmit = async (values: any) => {};

  // We define our schema based on the same keys as our form:
  const schema = Yup.object().shape({
    names: Yup.array().when("info_type", {
      is: LocalizeFilterCriteriaInfoType.NAMES,
      then: Yup.array()
        .min(1, "Informe ao menos um nome válido")
        .max(5000, "Informe no máximo 5000 documentos")
        .required("Informe uma lista de documentos válidos"),
    }),
    documents: Yup.array().when("info_type", {
      is: LocalizeFilterCriteriaInfoType.DOCUMENTS,
      then: Yup.array()
        .min(1, "Informe ao menos um documento válido")
        .max(5000, "Informe no máximo 5000 documentos")
        .required("Informe uma lista de documentos válidos"),
    }),
    emails: Yup.array().when("info_type", {
      is: LocalizeFilterCriteriaInfoType.EMAILS,
      then: Yup.array()
        .min(1, "Informe ao menos um e-mail válido")
        .max(5000, "Informe no máximo 5000 e-mails")
        .required("Informe uma lista de e-mails válidos"),
    }),
    mobiles: Yup.array().when("info_type", {
      is: LocalizeFilterCriteriaInfoType.MOBILES,
      then: Yup.array()
        .min(1, "Informe ao menos um celular válido")
        .max(5000, "Informe no máximo 5000 celulares")
        .required("Informe uma lista de celulares válidos"),
    }),
    landlines: Yup.array().when("info_type", {
      is: LocalizeFilterCriteriaInfoType.LANDLINES,
      then: Yup.array()
        .min(1, "Informe ao menos um telefone fixo válido")
        .max(5000, "Informe no máximo 5000 telefones fixos")
        .required("Informe uma lista de telefones fixos válidos"),
    }),
  });

  const validate = makeValidate(schema);

  return (
    <Box p="30px">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...criteria,
          names: criteria.names ?? [],
          documents: criteria.documents ?? [],
          emails: criteria.emails ?? [],
          mobiles: criteria.mobiles ?? [],
          landlines: criteria.landlines ?? [],
          info_type:
            criteria.info_type ?? LocalizeFilterCriteriaInfoType.DOCUMENTS,
        }}
        validate={validate}
        validateOnBlur
        render={({ handleSubmit, values, form, errors }: any) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box marginBottom="20px">
              <Select
                disabled={!editable}
                variant="outlined"
                label="Tipo de Informação"
                name="info_type"
                helperText="Selecione o tipo de informação pela qual deseja filtrar os resultados"
                inputLabelProps={{
                  sx: {
                    fontSize: "1rem !important",
                  },
                }}
                sx={{
                  "& legend": {
                    fontSize: "13px !important",
                  },
                }}
                formHelperTextProps={{
                  sx: {
                    fontSize: "12px",
                    marginLeft: 0,
                    marginTop: "5px",
                  },
                }}
              >
                <MenuItem value={LocalizeFilterCriteriaInfoType.DOCUMENTS}>
                  {filter_type === FilterType.NATURAL_PERSONS ? "CPF" : "CNPJ"}
                </MenuItem>
                <MenuItem value={LocalizeFilterCriteriaInfoType.NAMES}>
                  {filter_type === FilterType.NATURAL_PERSONS
                    ? "Nomes Completos"
                    : "Razões Sociais"}
                </MenuItem>
                <MenuItem value={LocalizeFilterCriteriaInfoType.EMAILS}>
                  E-mails
                </MenuItem>
                <MenuItem value={LocalizeFilterCriteriaInfoType.MOBILES}>
                  Celulares
                </MenuItem>
                <MenuItem value={LocalizeFilterCriteriaInfoType.LANDLINES}>
                  Telefones Fixos
                </MenuItem>
              </Select>
            </Box>
            {values.info_type === LocalizeFilterCriteriaInfoType.DOCUMENTS && (
              <Box
                sx={{
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <TextField
                  disabled={!editable}
                  variant="outlined"
                  label={`Lista de ${
                    filter_type === FilterType.NATURAL_PERSONS ? "CPF" : "CNPJ"
                  }s`}
                  name="documents"
                  required={true}
                  helperText={`Adicione neste campo uma lista de ${
                    filter_type === FilterType.NATURAL_PERSONS ? "CPF" : "CNPJ"
                  }s válidos separados por vírgula. Ex.: ${
                    filter_type === FilterType.NATURAL_PERSONS
                      ? "999.999.999-99, 000.000.000-00"
                      : "99.999.999/9999-99, 00.000.000/0000-00"
                  }`}
                  multiline
                  rows={10}
                  spellCheck={false}
                  onBlur={(e) => {
                    form.change(
                      "documents",
                      values.documents.filter((doc: any) => !!doc)
                    );
                  }}
                  onPasteCapture={(e) => {
                    let pasted = e.clipboardData.getData("text");
                    pasted = pasted.replace(/\n/g, ",");
                    const parsed: string[] = pasted
                      .split(",")
                      .map((doc): string => {
                        let clean = doc.replace(/[^0-9]/g, "");
                        clean = clean.padStart(num_characters, "0");
                        if (validator.isValid(clean)) {
                          return clean;
                        } else {
                          return "";
                        }
                      })
                      .filter((doc: any) => !!doc);

                    form.change("documents", parsed);
                    e.preventDefault();
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "1rem !important",
                    },
                  }}
                  InputProps={{
                    sx: {
                      "& legend": {
                        fontSize: "13px",
                      },
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        variant="filled"
                        sx={{
                          position: "absolute",
                          bottom: "-15px",
                          right: "0",
                          fontWeight: "bold",
                        }}
                      >
                        {values?.documents.length} documento(s) válido(s)
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    sx: {
                      fontSize: "12px",
                      marginLeft: 0,
                      marginTop: "5px",
                    },
                  }}
                  fieldProps={{
                    format: (value) => {
                      let commafied = value;
                      if (typeof commafied === "string") {
                        commafied = commafied.replace(/\n/g, ",");
                        commafied = commafied.split(",");
                      }

                      commafied = commafied
                        .map((doc: string) => {
                          let clean = doc.replace(/[^0-9]/g, "");
                          if (clean.length >= num_characters) {
                            if (validator.isValid(clean)) {
                              return validator.format(clean);
                            } else {
                              return null;
                            }
                          } else {
                            return doc;
                          }
                        })
                        .filter(
                          (doc: any, index: number, self: any) =>
                            self.indexOf(doc) === index
                        )
                        .join(",");
                      return commafied;
                    },
                    parse: (value) => {
                      let parsed = value;
                      if (typeof parsed === "string") {
                        parsed = parsed.replace(/\n/g, ",");
                        parsed = parsed
                          .split(",")
                          .map((doc: string) => doc.replace(/[^0-9]/g, ""))
                          .filter(
                            (doc: any, index: number, self: any) =>
                              self.indexOf(doc) === index
                          );

                        if (parsed.length === 1) {
                          parsed = parsed.filter((doc: any) => !!doc);
                        }
                      }

                      return parsed;
                    },
                  }}
                />
              </Box>
            )}
            {values.info_type === LocalizeFilterCriteriaInfoType.NAMES && (
              <Box
                sx={{
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                {!!errors["undefined"] && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginLeft: 0,
                      marginTop: "5px",
                      marginBottom: "20px",
                      color: "red",
                    }}
                  >
                    {errors["undefined"]}
                  </Typography>
                )}
                <TextField
                  disabled={!editable}
                  variant="outlined"
                  label={`Lista de ${
                    filter_type === FilterType.NATURAL_PERSONS
                      ? "Nomes"
                      : "Razões Sociais"
                  } (Opcional)`}
                  name="names"
                  helperText={`Adicione neste campo uma lista de ${
                    filter_type === FilterType.NATURAL_PERSONS
                      ? "nomes completos"
                      : "razões sociais"
                  } separados por vírgula. Ex.: ${
                    filter_type === FilterType.NATURAL_PERSONS
                      ? "JOAO DA SILVA, ANTONIO MACEDO"
                      : "ACME ACESSORIOS LTDA, ANDALUZIA MATERIAS DE CONTRUCAO ME"
                  }`}
                  multiline
                  rows={7}
                  spellCheck={false}
                  onBlur={(e) => {
                    form.change(
                      "names",
                      values.names.filter((name: any) => !!name)
                    );
                  }}
                  onPasteCapture={(e) => {
                    let pasted = e.clipboardData.getData("text");
                    pasted = pasted.replace(/\n/g, ",");
                    const parsed = pasted
                      .split(",")
                      .map((name: string, index: number, self: any): string => {
                        name = sanitize.clean(name).toUpperCase();
                        if (index < self.length - 1 || !name.trim()) {
                          name = name.trim();
                        }

                        if (
                          index === self.length - 1 &&
                          name.trim().length >= 150
                        ) {
                          return "";
                        }

                        return name;
                      })
                      .filter(
                        (name: any, index: number, self: any) =>
                          self.indexOf(name) === index
                      )
                      .filter((name: any) => !!name);

                    form.change("names", parsed);
                    e.preventDefault();
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "1rem !important",
                    },
                  }}
                  InputProps={{
                    sx: {
                      "& legend": {
                        fontSize: "13px",
                      },
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        variant="filled"
                        sx={{
                          position: "absolute",
                          bottom: "-15px",
                          right: "0",
                          fontWeight: "bold",
                        }}
                      >
                        {!!values?.names ? values?.names.length : 0} nome(s)
                        válido(s)
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    sx: {
                      fontSize: "12px",
                      marginLeft: 0,
                      marginTop: "5px",
                      marginBottom: "10px",
                    },
                  }}
                  fieldProps={{
                    format: (value) => {
                      let commafied = value;
                      if (Array.isArray(commafied)) {
                        commafied = commafied.join(",");
                      }
                      commafied = !!commafied
                        ? commafied.replace(/\n/g, ",")
                        : "";
                      return sanitize.clean(commafied).toUpperCase();
                    },
                    parse: (value) => {
                      let parsed = value;
                      if (typeof parsed === "string") {
                        parsed = parsed.replace(/\n/g, ",");
                        parsed = parsed
                          .split(",")
                          .map((name: string, index: number, self: any) => {
                            name = sanitize.clean(name).toUpperCase();
                            if (index < self.length - 1 || !name.trim()) {
                              name = name.trim();
                            }

                            if (
                              index === self.length - 1 &&
                              name.trim().length >= 150
                            ) {
                              return null;
                            }

                            return name;
                          })
                          .filter(
                            (name: any, index: number, self: any) =>
                              self.indexOf(name) === index
                          );

                        if (parsed.length === 1) {
                          parsed = parsed.filter((name: any) => !!name);
                        }

                        return parsed;
                      }
                    },
                  }}
                />
              </Box>
            )}
            {values.info_type === LocalizeFilterCriteriaInfoType.EMAILS && (
              <Box
                sx={{
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <TextField
                  disabled={!editable}
                  variant="outlined"
                  label={`Lista de E-mails`}
                  name="emails"
                  required={true}
                  helperText={`Adicione neste campo uma lista de e- mails válidos separados por vírgula. Ex.: joaodasilva@gmail.com, aline@hotmail.com`}
                  multiline
                  rows={10}
                  spellCheck={false}
                  onBlur={(e) => {
                    form.change(
                      "emails",
                      values.emails.filter((email: any) => !!email)
                    );
                  }}
                  onPasteCapture={(e) => {
                    let pasted = e.clipboardData.getData("text");
                    pasted = pasted.replace(/\n/g, ",");
                    const parsed: string[] = pasted
                      .split(",")
                      .map((email): string => {
                        let clean = email.trim();
                        if (validate_email(clean)) {
                          return clean;
                        } else {
                          return "";
                        }
                      })
                      .filter((email: any) => !!email);

                    form.change("emails", parsed);
                    e.preventDefault();
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "1rem !important",
                    },
                  }}
                  InputProps={{
                    sx: {
                      "& legend": {
                        fontSize: "13px",
                      },
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        variant="filled"
                        sx={{
                          position: "absolute",
                          bottom: "-15px",
                          right: "0",
                          fontWeight: "bold",
                        }}
                      >
                        {values?.emails.length} e-mail(s) válido(s)
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    sx: {
                      fontSize: "12px",
                      marginLeft: 0,
                      marginTop: "5px",
                    },
                  }}
                  fieldProps={{
                    format: (value) => {
                      let commafied = value;
                      if (typeof commafied === "string") {
                        commafied = commafied.replace(/\n/g, ",");
                        commafied = commafied.split(",");
                      }

                      commafied = commafied
                        .map((email: string) => {
                          return email.trim();
                        })
                        .filter(
                          (email: any, index: number, self: any) =>
                            self.indexOf(email) === index
                        )
                        .join(",");
                      return commafied;
                    },
                    parse: (value) => {
                      let parsed = value;
                      if (typeof parsed === "string") {
                        parsed = parsed.replace(/\n/g, ",");
                        parsed = parsed
                          .split(",")
                          .map((email: string) => email.trim())
                          .filter(
                            (email: any, index: number, self: any) =>
                              self.indexOf(email) === index
                          );

                        if (parsed.length === 1) {
                          parsed = parsed.filter((email: any) => !!email);
                        }
                      }

                      return parsed;
                    },
                  }}
                />
              </Box>
            )}
            {values.info_type === LocalizeFilterCriteriaInfoType.MOBILES && (
              <Box
                sx={{
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <TextField
                  disabled={!editable}
                  variant="outlined"
                  label={`Lista de Celulares`}
                  name="mobiles"
                  required={true}
                  helperText={`Adicione neste campo uma lista de celulares válidos separados por vírgula. Ex.: 11 99999-9999, 11 99999-9999, 11 99999-9999`}
                  multiline
                  rows={10}
                  spellCheck={false}
                  onBlur={(e) => {
                    form.change(
                      "mobiles",
                      values.mobiles.filter((mobile: any) => !!mobile)
                    );
                  }}
                  onPasteCapture={(e) => {
                    let pasted = e.clipboardData.getData("text");
                    pasted = pasted.replace(/\n/g, ",");
                    const parsed: string[] = pasted
                      .split(",")
                      .map((mobile): string => {
                        let clean = mobile.trim();
                        if (isValidPhoneNumber(clean, "BR")) {
                          return clean;
                        } else {
                          return "";
                        }
                      })
                      .filter((mobile: any) => !!mobile);

                    form.change("mobiles", parsed);
                    e.preventDefault();
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "1rem !important",
                    },
                  }}
                  InputProps={{
                    sx: {
                      "& legend": {
                        fontSize: "13px",
                      },
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        variant="filled"
                        sx={{
                          position: "absolute",
                          bottom: "-15px",
                          right: "0",
                          fontWeight: "bold",
                        }}
                      >
                        {values?.mobiles.length} celular(es) válido(s)
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    sx: {
                      fontSize: "12px",
                      marginLeft: 0,
                      marginTop: "5px",
                    },
                  }}
                  fieldProps={{
                    format: (value) => {
                      let commafied = value;
                      if (typeof commafied === "string") {
                        commafied = commafied.replace(/\n/g, ",");
                        commafied = commafied.split(",");
                      }

                      commafied = commafied
                        .map((mobile: string) => {
                          const clean = mobile.trim();
                          if (isValidPhoneNumber(clean, "BR")) {
                            const phoneNumber = parsePhoneNumber(clean, "BR");
                            return phoneNumber
                              ? phoneNumber.formatNational()
                              : clean;
                          } else {
                            return clean;
                          }
                        })
                        .filter(
                          (mobile: any, index: number, self: any) =>
                            self.indexOf(mobile) === index
                        )
                        .join(",");
                      return commafied;
                    },
                    parse: (value) => {
                      let parsed = value;
                      if (typeof parsed === "string") {
                        parsed = parsed.replace(/\n/g, ",");
                        parsed = parsed
                          .split(",")
                          .map((mobile: string) =>
                            mobile.trim().replace(/[^0-9]/g, "")
                          )
                          .filter(
                            (mobile: any, index: number, self: any) =>
                              self.indexOf(mobile) === index
                          );

                        if (parsed.length === 1) {
                          parsed = parsed.filter((mobile: any) => !!mobile);
                        }
                      }

                      return parsed;
                    },
                  }}
                />
              </Box>
            )}
            {values.info_type === LocalizeFilterCriteriaInfoType.LANDLINES && (
              <Box
                sx={{
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <TextField
                  disabled={!editable}
                  variant="outlined"
                  label={`Lista de Telefones Fixos`}
                  name="landlines"
                  required={true}
                  helperText={`Adicione neste campo uma lista de telefones fixos separados por vírgula. Ex.: 11 1234-5678, 11 1234-5678, 11 1234-5678`}
                  multiline
                  rows={10}
                  spellCheck={false}
                  onBlur={(e) => {
                    form.change(
                      "landlines",
                      values.landlines.filter((landline: any) => !!landline)
                    );
                  }}
                  onPasteCapture={(e) => {
                    let pasted = e.clipboardData.getData("text");
                    pasted = pasted.replace(/\n/g, ",");
                    const parsed: string[] = pasted
                      .split(",")
                      .map((landline): string => {
                        let clean = landline.trim();
                        if (isValidPhoneNumber(clean, "BR")) {
                          return clean;
                        } else {
                          return "";
                        }
                      })
                      .filter((landline: any) => !!landline);

                    form.change("landlines", parsed);
                    e.preventDefault();
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "1rem !important",
                    },
                  }}
                  InputProps={{
                    sx: {
                      "& legend": {
                        fontSize: "13px",
                      },
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        variant="filled"
                        sx={{
                          position: "absolute",
                          bottom: "-15px",
                          right: "0",
                          fontWeight: "bold",
                        }}
                      >
                        {values?.landlines.length} telefone(s) fixo(s) válido(s)
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    sx: {
                      fontSize: "12px",
                      marginLeft: 0,
                      marginTop: "5px",
                    },
                  }}
                  fieldProps={{
                    format: (value) => {
                      let commafied = value;
                      if (typeof commafied === "string") {
                        commafied = commafied.replace(/\n/g, ",");
                        commafied = commafied.split(",");
                      }

                      commafied = commafied
                        .map((landline: string) => {
                          const clean = landline.trim();
                          if (isValidPhoneNumber(clean, "BR")) {
                            const phoneNumber = parsePhoneNumber(clean, "BR");

                            return phoneNumber
                              ? phoneNumber.formatNational()
                              : clean;
                          } else {
                            return clean;
                          }
                        })
                        .filter(
                          (landline: any, index: number, self: any) =>
                            self.indexOf(landline) === index
                        )
                        .join(",");
                      return commafied;
                    },
                    parse: (value) => {
                      let parsed = value;
                      if (typeof parsed === "string") {
                        parsed = parsed.replace(/\n/g, ",");
                        parsed = parsed
                          .split(",")
                          .map((landline: string) =>
                            landline.trim().replace(/[^0-9]/g, "")
                          )
                          .filter(
                            (landline: any, index: number, self: any) =>
                              self.indexOf(landline) === index
                          );

                        if (parsed.length === 1) {
                          parsed = parsed.filter((landline: any) => !!landline);
                        }
                      }

                      return parsed;
                    },
                  }}
                />
              </Box>
            )}
            {/* <pre>{JSON.stringify({ errors, values }, undefined, 2)}</pre> */}
            <FormSpy
              subscription={{ values: true, hasValidationErrors: true }}
              onChange={({ values, hasValidationErrors }) => {
                onChange(
                  values as LocalizeFilterCriteria,
                  !hasValidationErrors
                );
              }}
            />
          </form>
        )}
      />
    </Box>
  );
};

export default EditLocalizeFilterCriteria;
