import {
  Business,
  Filter as FilterIcon,
  InfoOutlined,
  InfoRounded,
  Person,
  Person2,
  Refresh,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AutofpsSelectIcon from "@mui/icons-material/AutofpsSelect";
import BadgeIcon from "@mui/icons-material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ErrorIcon from "@mui/icons-material/Error";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { TransitionProps } from "@mui/material/transitions";
import { Stack } from "@mui/system";
import * as React from "react";
import GridLoader from "react-spinners/GridLoader";
import useFitText from "use-fit-text";
import { useEntityOperations } from "../../../hooks/useEntityOperations";
import { useUser } from "../../../providers/users/useUser";
import { AddressDetailsFilterCriteria } from "../../../types/AddressDetailsFilterCriteria";
import { DocumentListFilterCriteria } from "../../../types/DocumentListFilterCriteria";
import { Filter, FilterType } from "../../../types/Filter";
import {
  FilterCriteria,
  FilterCriteriaType,
} from "../../../types/FilterCriteria";
import { NameListFilterCriteria } from "../../../types/NameListFilterCriteria";
import { PersonalDetailsFilterCriteria } from "../../../types/PersonalDetailsFilterCriteria";
import { PhoneDetailsFilterCriteria } from "../../../types/PhoneDetailsFilterCriteria";
import Tooltip from "../../mui/Tooltip";
import EditFullScreenDialog from "../Dialog/EditFullScreenDialog";
import Layout from "../Layout";
import EditAddressDetailsFilterCriteria from "./EditAddressDetailsFilterCriteria";
import EditDocumentListFilterCriteria from "./EditDocumentListFilterCriteria";
import EditNameListFilterCriteria from "./EditNameListFilterCriteria";
import EditPhoneDetailsFilterCriteria from "./EditPhoneDetailsFilterCriteria";
import FilterCriteriaList from "./FilterCriteriaList";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import EditPersonalDetailsFilterCriteria from "./EditPersonalDetailsFilterCriteria";
import {
  LocalizeFilterCriteria,
  LocalizeFilterCriteriaInfoType,
} from "../../../types/LocalizeFilterCriteria";
import EditLocalizeFilterCriteria from "./EditLocalizeFilterCriteria";

const ResizeableText = ({ text }: { text: string }) => {
  const { fontSize, ref } = useFitText({ maxFontSize: 1000 });

  return (
    <p
      ref={ref}
      style={{
        fontSize,
        whiteSpace: "nowrap",
        width: 280,
        textAlign: "center",
        color: "#666",
      }}
    >
      {text}
    </p>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilterComponent() {
  const { user } = useUser();

  const current_app = React.useMemo(() => {
    return window.location.hostname;
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const [editing, setEditing] = React.useState<
    | FilterCriteria
    | NameListFilterCriteria
    | DocumentListFilterCriteria
    | PhoneDetailsFilterCriteria
    | AddressDetailsFilterCriteria
    | PersonalDetailsFilterCriteria
    | LocalizeFilterCriteria
  >();

  const {
    loading: loadingDocumentListCriteria,
    save: saveDocumentListCriteria,
  } = useEntityOperations("criteria.document_list");

  const { loading: loadingNameListCriteria, save: saveNameListCriteria } =
    useEntityOperations("criteria.name_list");

  const {
    loading: loadingPhoneDetailsCriteria,
    save: savePhoneDetailsCriteria,
  } = useEntityOperations("criteria.phone_details");

  const {
    loading: loadingAddressDetailsCriteria,
    save: saveAddressDetailsCriteria,
  } = useEntityOperations("criteria.address_details");

  const {
    loading: loadingPersonalDetailsCriteria,
    save: savePersonalDetailsCriteria,
  } = useEntityOperations("criteria.personal_details");

  const { loading: loadingLocalizeCriteria, save: saveLocalizeCriteria } =
    useEntityOperations("criteria.localize");

  const { loading: loadingCriteria, delete: deleteCriteria } =
    useEntityOperations("criteria");

  const { loading, request } = useEntityOperations("filter");
  const {
    loading: loadingNaturalPersonFilter,
    subscribe: subscribeNaturalPersonFilter,
    save: saveNaturalPersonFilter,
  } = useEntityOperations("filter.natural");
  const {
    loading: loadingLegalPersonFilter,
    subscribe: subscribeLegalPersonFilter,
    save: saveLegalPersonFilter,
  } = useEntityOperations("filter.legal");

  const [instance, setInstance] = React.useState<Filter>();

  const [tab, setTab] = React.useState<number | undefined>();

  const [canSaveFilterCriteria, setCanSaveFilterCriteria] =
    React.useState(false);

  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const [overlimitCut, setOverlimitCut] = React.useState(0);

  const subscription = React.useRef<any>();

  const count_update_interval = React.useRef<any>();

  const loadCurrentFilter = React.useCallback(async () => {
    let type = tab === 0 ? "natural" : tab === 1 ? "legal" : undefined;

    if ((!type || type === "natural") && user?.can_query_natural_persons) {
      type = "natural";
    } else if ((!type || type === "legal") && user?.can_query_legal_persons) {
      type = "legal";
    }

    const filter = await request<Filter>("current", { type });

    if (filter) {
      setInstance(filter);
      if (!tab) setTab(filter?.type === FilterType.NATURAL_PERSONS ? 0 : 1);
      if (
        !filter.is_counting &&
        !!count_update_interval &&
        !!count_update_interval.current
      ) {
        clearInterval(count_update_interval.current);
      }

      const subscribeHandler =
        filter.type === FilterType.NATURAL_PERSONS
          ? subscribeNaturalPersonFilter
          : subscribeLegalPersonFilter;

      if (subscription) {
        subscription.current = await subscribeHandler(
          filter.id,
          (changed: any, action: string) => {
            if (action === "update") {
              console.info(`Filter ${filter.id} was updated:`, changed);
              setInstance(changed);
              if (
                !changed.is_counting &&
                !!count_update_interval &&
                !!count_update_interval.current
              ) {
                clearInterval(count_update_interval.current);
              }
            }
          }
        );
      }
    }
  }, [
    request,
    count_update_interval,
    tab,
    user,
    subscribeNaturalPersonFilter,
    subscribeLegalPersonFilter,
  ]);

  const request_more_credits = React.useMemo(() => {
    return `https://wa.me/5548984960268/?text=Olá, gostaria de adquirir mais cŕeditos para a minha conta do *${
      current_app.includes("localize") ? "Localize" : "Gera Mailing"
    }*: e-mail *${user?.email}*`;
  }, [user, current_app]);

  const activate_subscription = React.useMemo(() => {
    return `https://wa.me/5548984960268/?text=Olá, estou no período de testes e gostaria de ativar a minha assinatura do *${
      current_app.includes("localize") ? "Localize" : "Gera Mailing"
    }*: e-mail *${user?.email}*`;
  }, [user, current_app]);

  React.useEffect(() => {
    if (user?.id && instance?.count) {
      setOverlimitCut(
        Math.min(
          user?.available_quota,
          user?.available_daily_quota,
          instance.count
        )
      );
    }
  }, [user, instance?.count]);

  React.useEffect(() => {
    if (!instance?.id) {
      loadCurrentFilter();
    }
  }, [
    instance?.id,
    subscription,
    request,
    subscribeLegalPersonFilter,
    subscribeNaturalPersonFilter,
  ]);

  React.useEffect(() => {
    if (instance?.extraction) {
      window.location.href = `/extraction/${instance?.extraction.id}`;
    }
  }, [instance?.extraction]);

  React.useEffect(() => {
    loadCurrentFilter();
  }, [tab]);

  const handleSaveFilter = React.useCallback(
    async (unique_contacts_only: boolean) => {
      const saveHandler =
        instance?.type === FilterType.NATURAL_PERSONS
          ? saveNaturalPersonFilter
          : saveLegalPersonFilter;

      if (!!instance?.id) {
        saveHandler<Filter>({
          ...instance,
          is_counted: false,
          is_counting: false,
          count: 0,
          unique_contacts_only: unique_contacts_only,
        });
      }
    },
    [instance, saveNaturalPersonFilter, saveLegalPersonFilter]
  );

  const handleAddFilterCriteria = React.useCallback(
    (type: FilterCriteriaType, params: any = {}) => {
      setOpen(true);
      setEditing({
        type,
        filter_id: instance?.id,
        filter: instance?.id,
        ...params,
      });
    },
    [instance?.id]
  );

  const handleEditFilterCriteria = (criteria: FilterCriteria) => {
    setOpen(true);
    const copy =
      criteria.type === FilterCriteriaType.DOCUMENT_LIST
        ? (criteria as DocumentListFilterCriteria)
        : criteria.type === FilterCriteriaType.NAME_LIST
        ? (criteria as NameListFilterCriteria)
        : criteria.type === FilterCriteriaType.PHONE_DETAILS
        ? (criteria as PhoneDetailsFilterCriteria)
        : criteria.type === FilterCriteriaType.PERSONAL_DETAILS
        ? (criteria as PersonalDetailsFilterCriteria)
        : criteria.type === FilterCriteriaType.LOCALIZE
        ? (criteria as LocalizeFilterCriteria)
        : criteria;

    setEditing({ ...copy });
  };

  const handleDeleteFilterCriteria = (criteria: FilterCriteria) =>
    criteria?.id && deleteCriteria(criteria.id);

  const handleSaveFilterCriteria = React.useCallback(async () => {
    if (instance?.id) {
      if (editing?.type === FilterCriteriaType.DOCUMENT_LIST) {
        await saveDocumentListCriteria<DocumentListFilterCriteria>(
          editing as DocumentListFilterCriteria
        );
      } else if (editing?.type === FilterCriteriaType.NAME_LIST) {
        await saveNameListCriteria<NameListFilterCriteria>(
          editing as NameListFilterCriteria
        );
      } else if (editing?.type === FilterCriteriaType.PHONE_DETAILS) {
        await savePhoneDetailsCriteria<PhoneDetailsFilterCriteria>(
          editing as PhoneDetailsFilterCriteria
        );
      } else if (editing?.type === FilterCriteriaType.ADDRESS_DETAILS) {
        await saveAddressDetailsCriteria<AddressDetailsFilterCriteria>(
          editing as AddressDetailsFilterCriteria
        );
      } else if (editing?.type === FilterCriteriaType.PERSONAL_DETAILS) {
        await savePersonalDetailsCriteria<PersonalDetailsFilterCriteria>(
          editing as PersonalDetailsFilterCriteria
        );
      } else if (editing?.type === FilterCriteriaType.LOCALIZE) {
        await saveLocalizeCriteria<LocalizeFilterCriteria>(
          editing as LocalizeFilterCriteria
        );
      }
    }

    setOpen(false);
    setEditing(undefined);
  }, [
    instance?.id,
    editing,
    saveDocumentListCriteria,
    saveNameListCriteria,
    savePhoneDetailsCriteria,
    saveAddressDetailsCriteria,
    savePersonalDetailsCriteria,
    saveLocalizeCriteria,
  ]);

  const handleCount = React.useCallback(
    () =>
      request<Filter>("count", {
        pk: instance?.id,
        id: instance?.id,
      })?.then((_) => {
        if (!!count_update_interval && !!count_update_interval.current) {
          clearInterval(count_update_interval.current);
        }

        count_update_interval.current = setInterval(loadCurrentFilter, 10000);
      }),
    [instance?.id, request, count_update_interval, loadCurrentFilter]
  );

  const handleExtract = React.useCallback(
    () =>
      request<Filter>("extract", {
        pk: instance?.id,
        id: instance?.id,
        limit: overlimitCut,
      }),
    [instance?.id, request, overlimitCut]
  );

  const isLoading = React.useMemo(() => {
    return [
      loading,
      loadingNaturalPersonFilter,
      loadingLegalPersonFilter,
      loadingDocumentListCriteria,
      loadingNameListCriteria,
      loadingPhoneDetailsCriteria,
      loadingAddressDetailsCriteria,
      loadingPersonalDetailsCriteria,
      loadingLocalizeCriteria,
      loadingCriteria,
    ].some((entry) => !!entry);
  }, [
    loading,
    loadingNaturalPersonFilter,
    loadingLegalPersonFilter,
    loadingDocumentListCriteria,
    loadingNameListCriteria,
    loadingPhoneDetailsCriteria,
    loadingAddressDetailsCriteria,
    loadingPersonalDetailsCriteria,
    loadingLocalizeCriteria,
    loadingCriteria,
  ]);

  const canConfirmExtraction = React.useMemo(() => {
    return !isLoading && (instance?.count || 0) > 0 && overlimitCut > 0;
  }, [instance?.count, isLoading, overlimitCut]);

  const canAddLocalizeCriteria = React.useMemo(() => {
    return (
      current_app.includes("localize") &&
      !isLoading &&
      !!instance?.criteria &&
      !instance?.criteria.find(
        (crit) => crit.type === FilterCriteriaType.LOCALIZE
      )
    );
  }, [instance?.criteria, isLoading, current_app]);

  const canAddDocumentListCriteria = React.useMemo(() => {
    return (
      current_app.includes("geramailing") &&
      !isLoading &&
      !!instance?.criteria &&
      !instance?.criteria.find(
        (crit) => crit.type === FilterCriteriaType.DOCUMENT_LIST
      )
    );
  }, [instance?.criteria, isLoading, current_app]);

  const canAddPhoneDetailsCriteria = React.useMemo(() => {
    return (
      current_app.includes("geramailing") &&
      !isLoading &&
      !!instance?.criteria &&
      !instance?.criteria.find(
        (crit) => crit.type === FilterCriteriaType.PHONE_DETAILS
      )
    );
  }, [instance?.criteria, isLoading, current_app]);

  const canAddAddressDetailsCriteria = React.useMemo(() => {
    return (
      current_app.includes("geramailing") &&
      !isLoading &&
      !!instance?.criteria &&
      !instance?.criteria.find(
        (crit) => crit.type === FilterCriteriaType.ADDRESS_DETAILS
      )
    );
  }, [instance?.criteria, isLoading, current_app]);

  const canAddPersonalDetailsCriteria = React.useMemo(() => {
    return (
      current_app.includes("geramailing") &&
      !isLoading &&
      !!instance?.criteria &&
      !instance?.criteria.find(
        (crit) => crit.type === FilterCriteriaType.PERSONAL_DETAILS
      )
    );
  }, [instance?.criteria, isLoading, current_app]);

  return (
    <Layout>
      {!!instance && open && editing && (
        <EditFullScreenDialog
          open
          color={
            instance?.type === FilterType.NATURAL_PERSONS
              ? "primary"
              : "secondary"
          }
          icon={
            editing.type === FilterCriteriaType.DOCUMENT_LIST ? (
              <BadgeIcon />
            ) : editing.type === FilterCriteriaType.NAME_LIST ? (
              <AutofpsSelectIcon />
            ) : editing.type === FilterCriteriaType.PHONE_DETAILS ? (
              <ContactPhoneIcon />
            ) : editing.type === FilterCriteriaType.ADDRESS_DETAILS ? (
              <LocationOnIcon />
            ) : editing.type === FilterCriteriaType.PERSONAL_DETAILS ? (
              <Person />
            ) : editing.type === FilterCriteriaType.LOCALIZE ? (
              <SearchIcon />
            ) : (
              <></>
            )
          }
          title={`${editing?.id ? "Editar" : "Adicionar"} ${
            editing.type === FilterCriteriaType.DOCUMENT_LIST
              ? `Filtro de ${
                  instance?.type === FilterType.NATURAL_PERSONS
                    ? "CPF's"
                    : "CNPJ's"
                }`
              : editing.type === FilterCriteriaType.NAME_LIST
              ? `Filtro de ${
                  instance?.type === FilterType.NATURAL_PERSONS
                    ? "Nomes"
                    : "Razões Sociais"
                }`
              : editing.type === FilterCriteriaType.PHONE_DETAILS
              ? "Filtro de Telefonia"
              : editing.type === FilterCriteriaType.ADDRESS_DETAILS
              ? "Filtro de Endereço"
              : editing.type === FilterCriteriaType.LOCALIZE
              ? "Critérios de Filtro"
              : editing.type === FilterCriteriaType.PERSONAL_DETAILS
              ? `Filtro de Dados ${
                  instance?.type === FilterType.NATURAL_PERSONS
                    ? "Pessoais"
                    : "Cadastrais"
                }`
              : ""
          }`}
          autoHeight
          handleClose={() => {
            setOpen(false);
            setEditing(undefined);
          }}
          handleSave={handleSaveFilterCriteria}
          canSave={canSaveFilterCriteria}
        >
          {editing.type === FilterCriteriaType.DOCUMENT_LIST ? (
            <EditDocumentListFilterCriteria
              criteria={editing as DocumentListFilterCriteria}
              onChange={(changed, isValid) => {
                setEditing(changed);
                setCanSaveFilterCriteria(isValid);
              }}
              filter_type={instance.type}
            />
          ) : editing.type === FilterCriteriaType.NAME_LIST ? (
            <EditNameListFilterCriteria
              criteria={editing as NameListFilterCriteria}
              onChange={(changed, isValid) => {
                setEditing(changed);
                setCanSaveFilterCriteria(isValid);
              }}
              filter_type={instance.type}
            />
          ) : editing.type === FilterCriteriaType.PHONE_DETAILS ? (
            <EditPhoneDetailsFilterCriteria
              criteria={editing as PhoneDetailsFilterCriteria}
              onChange={(changed, isValid) => {
                setEditing(changed);
                setCanSaveFilterCriteria(isValid);
              }}
              filter_type={instance.type}
            />
          ) : editing.type === FilterCriteriaType.ADDRESS_DETAILS ? (
            <EditAddressDetailsFilterCriteria
              criteria={editing as AddressDetailsFilterCriteria}
              onChange={(changed, isValid) => {
                setEditing(changed);
                setCanSaveFilterCriteria(isValid);
              }}
              filter_type={instance.type}
            />
          ) : editing.type === FilterCriteriaType.PERSONAL_DETAILS ? (
            <EditPersonalDetailsFilterCriteria
              criteria={editing as PersonalDetailsFilterCriteria}
              onChange={(changed, isValid) => {
                setEditing(changed);
                setCanSaveFilterCriteria(isValid);
              }}
              filter_type={instance.type}
            />
          ) : editing.type === FilterCriteriaType.LOCALIZE ? (
            <EditLocalizeFilterCriteria
              criteria={editing as LocalizeFilterCriteria}
              onChange={(changed, isValid) => {
                setEditing(changed);
                setCanSaveFilterCriteria(isValid);
              }}
              filter_type={instance.type}
            />
          ) : (
            <></>
          )}
        </EditFullScreenDialog>
      )}

      {!!instance && !!user && (
        <>
          <Dialog
            maxWidth="md"
            fullWidth
            open={openConfirmation}
            TransitionComponent={Transition}
            PaperProps={{
              sx: { height: "auto", minHeight: "300px" },
            }}
          >
            <DialogTitle sx={{ padding: 0 }}>
              <AppBar
                sx={{
                  position: "relative",
                  boxShadow: 0,
                  backgroundColor: "orange",
                }}
              >
                <Toolbar>
                  <>
                    {user.is_trial ? (
                      <InfoRounded fontSize="large" />
                    ) : (
                      <ErrorIcon fontSize="large" />
                    )}
                  </>

                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    {user.is_trial ? (
                      <>Período de testes</>
                    ) : user.available_daily_quota < instance.count ? (
                      <>Limite diário excedido</>
                    ) : user.available_quota < instance.count ? (
                      <>Créditos Insuficientes</>
                    ) : (
                      <>Quantidade de registros únicos</>
                    )}
                  </Typography>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenConfirmation(false)}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
            </DialogTitle>

            <DialogContent dividers sx={{ p: "30px 30px" }}>
              <>
                {user.is_trial ? (
                  <>
                    <Typography
                      fontSize="1.2rem"
                      sx={{
                        backgroundColor: "#cccccc70",
                        padding: "20px",
                      }}
                    >
                      ATENÇÃO: Extrações realizadas durante o período de testes
                      retornam no máximo 100 registros, de forma anonimizada, de
                      acordo com as regras da LGPD.
                    </Typography>

                    <Typography
                      fontSize="1rem"
                      marginTop="20px"
                      padding="0 5px"
                    >
                      Caso prefira, você pode{" "}
                      <Link
                        underline="hover"
                        sx={{
                          cursor: "pointer",
                        }}
                        href={activate_subscription}
                        target="_blank"
                        color="primary"
                        fontWeight="bold"
                      >
                        ativar a sua assinatura
                      </Link>{" "}
                      de forma definitiva ou prosseguir para a extração de
                      teste.
                    </Typography>
                  </>
                ) : (
                  <>
                    {user.available_daily_quota <= 0 ? (
                      <Typography
                        fontSize="1.2rem"
                        sx={{
                          backgroundColor: "#cccccc70",
                          padding: "20px",
                        }}
                      >
                        Desculpe, você já utilizou todo o seu limite diário de
                        <strong style={{ margin: "0 5px" }}>
                          {new Intl.NumberFormat("pt-BR").format(
                            user.daily_quota
                          )}{" "}
                        </strong>{" "}
                        contatos por dia
                      </Typography>
                    ) : user.available_daily_quota < instance.count ? (
                      <Typography
                        fontSize="1.2rem"
                        sx={{
                          backgroundColor: "#cccccc70",
                          padding: "20px",
                        }}
                      >
                        A quantidade de registros encontrados por este filtro
                        <strong style={{ margin: "0 5px" }}>
                          (
                          {new Intl.NumberFormat("pt-BR").format(
                            instance.count || 0
                          )}
                          )
                        </strong>
                        excede o seu limite diário de extrações de
                        <strong style={{ margin: "0 5px" }}>
                          {new Intl.NumberFormat("pt-BR").format(
                            user.daily_quota
                          )}{" "}
                        </strong>{" "}
                        contatos por dia
                      </Typography>
                    ) : user.available_quota < instance.count ? (
                      <Typography
                        fontSize="1.2rem"
                        sx={{
                          backgroundColor: "#cccccc70",
                          padding: "20px",
                        }}
                      >
                        A quantidade de registros encontrados por este filtro
                        <strong style={{ margin: "0 5px" }}>
                          (
                          {new Intl.NumberFormat("pt-BR").format(
                            instance.count || 0
                          )}
                          )
                        </strong>
                        excede o seu limite de créditos disponíveis
                        <strong style={{ margin: "0 5px" }}>
                          (
                          {new Intl.NumberFormat("pt-BR").format(
                            user.available_quota
                          )}
                          )
                        </strong>
                        .
                      </Typography>
                    ) : (
                      <Typography
                        fontSize="1.2rem"
                        sx={{
                          backgroundColor: "#cccccc70",
                          padding: "20px",
                        }}
                      >
                        Escolha abaixo a quantidade de registros que deseja
                        extrair utilizando este filtro
                      </Typography>
                    )}

                    {user.available_quota > 0 ? (
                      <>
                        {user.available_daily_quota <= 0 ? (
                          <></>
                        ) : user.available_daily_quota < instance.count ? (
                          <Typography
                            fontSize="1rem"
                            marginTop="20px"
                            padding="0 5px"
                          >
                            Para prosseguir com a extração, informe abaixo a
                            quantidade de registros que deseja extrair
                            utilizando o seu limite diário ainda disponível
                            <strong style={{ margin: "0 5px" }}>
                              (
                              {new Intl.NumberFormat("pt-BR").format(
                                user.available_daily_quota
                              )}
                              )
                            </strong>
                          </Typography>
                        ) : user.available_quota < instance.count ? (
                          <Typography
                            fontSize="1rem"
                            marginTop="20px"
                            padding="0 5px"
                          >
                            Para prosseguir com a extração,{" "}
                            <Link
                              underline="hover"
                              sx={{
                                cursor: "pointer",
                              }}
                              href={request_more_credits}
                              target="_blank"
                              color="primary"
                              fontWeight="bold"
                            >
                              adquira mais créditos
                            </Link>{" "}
                            ou informe abaixo a quantidade de registros que
                            deseja extrair utilizando o seu limite atual.
                          </Typography>
                        ) : (
                          <></>
                        )}

                        {user.available_daily_quota > 0 && (
                          <TextField
                            sx={{ marginTop: "20px" }}
                            fullWidth
                            variant="filled"
                            label="Quantos registros você deseja extrair?"
                            type="number"
                            value={overlimitCut || 0}
                            onChange={(e) => {
                              let value = Number.parseInt(e.target.value);

                              if (
                                value >
                                  Math.min(
                                    instance.count,
                                    user.available_quota,
                                    user.available_daily_quota
                                  ) ||
                                value <= 0
                              ) {
                                value = Math.min(
                                  instance.count,
                                  user.available_quota,
                                  user.available_daily_quota
                                );
                              }

                              if (value < 1) value = 1;
                              setOverlimitCut(value);
                            }}
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: Math.min(
                                  user.available_quota,
                                  user.available_daily_quota
                                ),
                              },
                            }}
                          />
                        )}
                        {Math.min(user.daily_quota, user.available_quota) >
                          instance.count && (
                          <>
                            {user.available_daily_quota < instance.count &&
                            user.available_quota > instance.count ? (
                              <Typography
                                fontSize="0.8rem"
                                marginTop="20px"
                                padding="0 5px"
                              >
                                Se preferir, você também pode aguardar a
                                liberação da sua cota diária completa de
                                <strong style={{ margin: "0 0 0 5px" }}>
                                  {new Intl.NumberFormat("pt-BR").format(
                                    user.monthly_subscription.pack.quota || 0
                                  )}
                                </strong>
                                , prevista para amanhã.
                              </Typography>
                            ) : user.available_quota < instance.count ? (
                              <Typography
                                fontSize="0.8rem"
                                marginTop="20px"
                                padding="0 5px"
                              >
                                Se preferir, você também pode aguardar a
                                liberação da sua próxima quota de
                                <strong style={{ margin: "0 0 0 5px" }}>
                                  {new Intl.NumberFormat("pt-BR").format(
                                    user.monthly_subscription.pack.quota || 0
                                  )}
                                </strong>
                                , prevista para
                                <strong style={{ margin: "0 5px" }}>
                                  {new Date(
                                    user.monthly_subscription.current_quota_due_date
                                  ).toLocaleString("pt-BR", {
                                    dateStyle: "long",
                                    hour12: false,
                                  })}
                                </strong>
                                (sujeito à identificação do pagamento).
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Typography
                        fontSize="1rem"
                        marginTop="20px"
                        padding="0 5px"
                      >
                        Para prosseguir com a extração,{" "}
                        <Link
                          underline="hover"
                          sx={{
                            cursor: "pointer",
                          }}
                          href={request_more_credits}
                          target="_blank"
                          color="primary"
                          fontWeight="bold"
                        >
                          adquira mais créditos
                        </Link>{" "}
                        ou aguarde a liberação da sua próxima quota de{" "}
                        <strong style={{ margin: "0 0 0 5px" }}>
                          {new Intl.NumberFormat("pt-BR").format(
                            user.monthly_subscription.pack.quota || 0
                          )}
                        </strong>
                        , prevista para
                        <strong style={{ margin: "0 5px" }}>
                          {new Date(
                            user.monthly_subscription.current_quota_due_date
                          ).toLocaleString("pt-BR", {
                            dateStyle: "long",
                            hour12: false,
                          })}
                        </strong>
                        (sujeito à identificação do pagamento).
                      </Typography>
                    )}
                  </>
                )}
              </>
            </DialogContent>
            {user.available_quota > 0 && user.available_daily_quota > 0 && (
              <DialogActions
                sx={{
                  backgroundColor: "#cccccc1c",
                  padding: "20px",
                }}
              >
                <Button
                  autoFocus
                  color="success"
                  onClick={handleExtract}
                  disabled={!canConfirmExtraction}
                  variant="contained"
                  size="large"
                >
                  CONFIRMAR EXTRAÇÃO{user.is_trial && <> DE TESTE</>}
                </Button>
              </DialogActions>
            )}
          </Dialog>
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="row"
            marginBottom="20px"
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                textColor={
                  instance.type === FilterType.NATURAL_PERSONS
                    ? "primary"
                    : "secondary"
                }
                indicatorColor={
                  instance.type === FilterType.NATURAL_PERSONS
                    ? "primary"
                    : "secondary"
                }
              >
                {user.can_query_natural_persons && (
                  <Tab
                    value={0}
                    disabled={!user.can_query_natural_persons}
                    label={
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Person2 fontSize="large" />
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.5em",
                            marginLeft: "10px",
                          }}
                        >
                          Pessoas Físicas
                        </Typography>
                      </Box>
                    }
                  />
                )}
                {user.can_query_legal_persons && (
                  <Tab
                    value={1}
                    disabled={!user.can_query_legal_persons}
                    label={
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Business fontSize="large" />
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.5em",
                            marginLeft: "10px",
                          }}
                        >
                          Pessoas Jurídicas
                        </Typography>
                      </Box>
                    }
                  />
                )}
              </Tabs>
            </Box>

            <Stack direction="row" spacing={2} marginLeft="auto">
              {current_app.includes("localize") && (
                <Tooltip
                  title={
                    !canAddLocalizeCriteria
                      ? "Já existe um critério deste tipo em utilização. Utilize o botão de editar para alterar os critérios"
                      : ""
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      color={
                        instance.type === FilterType.NATURAL_PERSONS
                          ? "primary"
                          : "secondary"
                      }
                      startIcon={<AddCircleIcon />}
                      onClick={() =>
                        handleAddFilterCriteria(FilterCriteriaType.LOCALIZE, {
                          documents: [],
                          names: [],
                          emails: [],
                          mobiles: [],
                          landlines: [],
                          info_type: LocalizeFilterCriteriaInfoType.DOCUMENTS,
                        })
                      }
                      disabled={!canAddLocalizeCriteria}
                    >
                      {`Critérios de Filtro`}
                    </Button>
                  </span>
                </Tooltip>
              )}
              {current_app.includes("geramailing") && (
                <>
                  <Tooltip
                    title={
                      !canAddDocumentListCriteria
                        ? "Já existe um critério deste tipo em utilização. Utilize o botão de editar para alterar os critérios"
                        : ""
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color={
                          instance.type === FilterType.NATURAL_PERSONS
                            ? "primary"
                            : "secondary"
                        }
                        startIcon={<AddCircleIcon />}
                        onClick={() =>
                          handleAddFilterCriteria(
                            FilterCriteriaType.DOCUMENT_LIST,
                            {
                              documents: [],
                            }
                          )
                        }
                        disabled={!canAddDocumentListCriteria}
                      >
                        {`Filtro de ${
                          instance?.type === FilterType.NATURAL_PERSONS
                            ? "CPF's"
                            : "CNPJ's"
                        }`}
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      !canAddAddressDetailsCriteria
                        ? "Já existe um critério deste tipo em utilização. Utilize o botão de editar para alterar os critérios"
                        : ""
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color={
                          instance.type === FilterType.NATURAL_PERSONS
                            ? "primary"
                            : "secondary"
                        }
                        startIcon={<AddCircleIcon />}
                        onClick={() =>
                          handleAddFilterCriteria(
                            FilterCriteriaType.ADDRESS_DETAILS,
                            {
                              states: [],
                              cities: [],
                              neighborhoods: [],
                              zipcodes: [],
                              zipcode_range: [],
                            }
                          )
                        }
                        disabled={!canAddAddressDetailsCriteria}
                      >
                        Filtro de Endereço
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      !canAddPersonalDetailsCriteria
                        ? "Já existe um critério deste tipo em utilização. Utilize o botão de editar para alterar os critérios"
                        : ""
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color={
                          instance.type === FilterType.NATURAL_PERSONS
                            ? "primary"
                            : "secondary"
                        }
                        startIcon={<AddCircleIcon />}
                        onClick={() =>
                          handleAddFilterCriteria(
                            FilterCriteriaType.PERSONAL_DETAILS,
                            {
                              names: [],
                              cnaes: [],
                              age_range: [],
                            }
                          )
                        }
                        disabled={!canAddPersonalDetailsCriteria}
                      >
                        {`Filtro de Dados ${
                          instance?.type === FilterType.NATURAL_PERSONS
                            ? "Pessoais"
                            : "Cadastrais"
                        }`}
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      !canAddPhoneDetailsCriteria
                        ? "Já existe um critério deste tipo em utilização. Utilize o botão de editar para alterar os critérios"
                        : ""
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color={
                          instance.type === FilterType.NATURAL_PERSONS
                            ? "primary"
                            : "secondary"
                        }
                        startIcon={<AddCircleIcon />}
                        onClick={() =>
                          handleAddFilterCriteria(
                            FilterCriteriaType.PHONE_DETAILS,
                            {
                              operators: [],
                              areacodes: [],
                            }
                          )
                        }
                        disabled={!canAddPhoneDetailsCriteria}
                      >
                        Filtro de Telefonia
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "stretch",
              justifyItems: "flex-start",
              flexDirection: "row",
              color: "black",
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "10px",
              flexGrow: 1,
            }}
          >
            {!!instance && instance.criteria.length > 0 ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    marginRight: "20px",
                  }}
                >
                  <Box sx={{ marginBottom: "20px" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={instance.unique_contacts_only}
                            onChange={(e) => {
                              handleSaveFilter(e.target.checked);
                            }}
                            color={
                              instance.type === FilterType.NATURAL_PERSONS
                                ? "primary"
                                : "secondary"
                            }
                          />
                        }
                        label="Filtrar apenas registros que eu ainda não extrai"
                      />
                    </FormGroup>
                  </Box>

                  <FilterCriteriaList
                    filter={instance}
                    handleEdit={handleEditFilterCriteria}
                    handleDelete={handleDeleteFilterCriteria}
                    loading={isLoading}
                  />
                </Box>
                <Box
                  sx={{
                    minWidth: "320px",
                    marginLeft: "auto",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "320px",
                      marginLeft: "auto",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      boxShadow: 3,
                      borderRadius: "10px",
                    }}
                  >
                    <Box
                      width="300px"
                      height="160px"
                      margin="10px"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {instance.is_counting ? (
                        <GridLoader size={30} color="#ccc" />
                      ) : instance.is_counted ? (
                        <ResizeableText
                          text={new Intl.NumberFormat("pt-BR").format(
                            instance.count
                          )}
                        />
                      ) : (
                        <Typography fontSize="6.5rem" color="#ccc">
                          ?
                        </Typography>
                      )}
                    </Box>
                    <Box width="100%" padding="10px" marginTop="auto">
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="success"
                        startIcon={<PersonSearchIcon />}
                        onClick={handleCount}
                        disabled={
                          isLoading ||
                          instance?.is_counting ||
                          instance?.is_counted ||
                          !instance?.criteria ||
                          instance?.criteria?.length <= 0
                        }
                      >
                        Contar Registros Únicos
                      </Button>
                    </Box>
                  </Box>
                  {instance?.is_counted &&
                    !instance?.is_counting &&
                    !!instance?.count && (
                      <Box width="100%" marginTop="auto">
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="success"
                          startIcon={<ThumbUpAltIcon />}
                          onClick={() => {
                            setOpenConfirmation(true);
                          }}
                          disabled={isLoading}
                        >
                          Solicitar extração
                        </Button>
                      </Box>
                    )}
                  {instance?.is_counting && (
                    <Box width="100%" marginTop="auto">
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="warning"
                        startIcon={<Refresh />}
                        onClick={loadCurrentFilter}
                        disabled={isLoading}
                      >
                        Atualizar contagem
                      </Button>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  minHeight: "300px",
                  opacity: 0.6,
                }}
              >
                <Typography
                  variant="h5"
                  color="GrayText"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  <InfoOutlined
                    sx={{
                      fontSize: "6rem",
                      marginBottom: "10px",
                      fontWeight: "notmal",
                    }}
                  />{" "}
                  Você ainda não adicionou nenhum critério de filtro
                </Typography>
                <Typography
                  variant="h6"
                  color="GrayText"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Utilize os botões de critério acima para iniciar a filtragem
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Layout>
  );
}
