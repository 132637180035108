import CloseIcon from '@mui/icons-material/Close'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'
import * as React from 'react'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export type EditFullScreenDialogProps = {
  open: boolean
  title?: string
  icon: React.ReactElement
  handleClose: () => void
  handleSave: () => void
  children: React.ReactElement
  autoHeight?: boolean
  canSave?: boolean
  color: 'primary' | 'secondary' | undefined
}

export default function EditFullScreenDialog({
  title,
  icon,
  handleSave,
  handleClose,
  children,
  autoHeight,
  canSave,
  color
}: EditFullScreenDialogProps) {
  const _handleClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick') {
      console.log(reason)
    } else {
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open
        onClose={_handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: { height: '85%', ...(autoHeight && { height: 'auto' }) }
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <AppBar sx={{ position: 'relative', boxShadow: 0 }} color={color}>
            <Toolbar>
              {icon}
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {title}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>

        <DialogContent dividers sx={{ p: 0 }}>
          {children}
        </DialogContent>
        {!!canSave && (
          <DialogActions
            sx={{
              backgroundColor: '#ccc'
            }}
          >
            <Button
              autoFocus
              color="success"
              onClick={handleSave}
              disabled={!canSave}
              variant="contained"
            >
              SALVAR
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}
