export enum FilterCriteriaType {
  DOCUMENT_LIST = 'DocumentListFilterCriteria',
  NAME_LIST = 'NameListFilterCriteria',
  PHONE_DETAILS = 'PhoneDetailsFilterCriteria',
  ADDRESS_DETAILS = 'AddressDetailsFilterCriteria',
  PERSONAL_DETAILS = 'PersonalDetailsFilterCriteria',
  LOCALIZE = 'LocalizeFilterCriteria'
}

export type FilterCriteria = {
  id?: number
  filter?: number
  filter_id?: number
  type: FilterCriteriaType
}
