import { Box } from '@mui/system'
import debounce from 'lodash/debounce'
import { Autocomplete, TextField } from 'mui-rff'
import { useCallback, useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { AddressDetailsFilterCriteria } from '../../../types/AddressDetailsFilterCriteria'

import {
  EntityPaginateParams,
  EntityPaginateResult,
  useEntityOperations
} from '../../../hooks/useEntityOperations'
import { FilterType } from '../../../types/Filter'

import isValidCep from '@brazilian-utils/is-valid-cep'
import { Button, InputAdornment, Stack, Typography } from '@mui/material'
import sanitize from 'diacritic'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import { City } from '../../../types/City'
import { Neighborhood } from '../../../types/Neighborhood'
import { State } from '../../../types/State'
import Fieldset from '../Fieldset'

export type EditAddressDetailsFilterCriteriaProps = {
  criteria: AddressDetailsFilterCriteria
  onChange: (changed: AddressDetailsFilterCriteria, isValid: boolean) => void
  filter_type: FilterType
  editable?: boolean
}

Yup.addMethod(Yup.object, 'atLeastOneOf', function () {
  return this.test({
    name: 'atLeastOneOf',
    message:
      'Informe ao menos um filtro dentre as opções disponíveis no formulário',
    exclusive: true,
    test: (value) => {
      return (
        value.states.length > 0 ||
        value.zipcodes.length > 0 ||
        value.zipcode_range.length > 0
      )
    }
  })
})

// We define our schema based on the same keys as our form:
const schema = Yup.object().shape({
  neighborhoods: Yup.array().max(100, 'Informe no máximo 100 bairros'),
  cities: Yup.array().max(100, 'Informe no máximo 100 cidades'),
  zipcodes: Yup.array().max(1000, 'Informe no máximo 1000 CEPs'),
  zipcode_range: Yup.array().test({
    name: 'zipcode_range_incomplete',
    message: 'Adicione um intervalo de CEPs válido',
    test: (value: any, { createError }) => {
      if (value.length > 0 && value.length < 2) {
        return createError({
          path: 'zipcode_range',
          message: 'Adicione um intervalo de CEPs válido'
        })
      } else if (value.length === 2) {
        let initial = String(value[0])
        let final = String(value[1])

        if (
          Number.parseInt(initial.replace(/[^0-9]/g, '')) >
          Number.parseInt(final.replace(/[^0-9]/g, ''))
        ) {
          return createError({
            path: 'zipcode_range',
            message: 'O CEP inicial deve ser menor ou igual ao CEP final'
          })
        }
        return true
      } else {
        return true
      }
    }
  })
})

const validate = makeValidate(schema)

const EditAddressDetailsFilterCriteria = ({
  criteria,
  onChange,
  filter_type,
  editable = true
}: EditAddressDetailsFilterCriteriaProps) => {
  const { list: listStates } = useEntityOperations('contact.states')
  const { paginate: paginateCities } = useEntityOperations('contact.cities')
  const { paginate: paginateNeighborhoods } = useEntityOperations(
    'contact.neighborhoods'
  )

  const [states, setStates] = useState<State[]>([])
  const [cities, setCities] = useState<City[]>([])
  const [neighborhoods, setNeighborhoods] = useState<Neighborhood[]>([])

  const [useZipcodeList, setUseZipcodeList] = useState<boolean>(
    criteria.zipcodes.length > 0
  )

  const [citySearch, setCitySearch] = useState<string>()
  const [neighborhoodSearch, setNeighborhoodSearch] = useState<string>()

  const onSubmit = async (values: any) => {}

  useEffect(() => {
    const loadDependencies = async () => {
      const entries = await listStates<State>()
      !!entries && setStates(entries)
    }

    !states.length && loadDependencies()
  }, [listStates, states])

  const searchCities = useCallback(
    debounce((query, callback) => {
      let params: EntityPaginateParams = {
        pager: {
          page: 1,
          pageSize: 10
        },
        filters: {
          state_id__in: criteria.states.map((state) => state.code)
        }
      }
      if (!!query) {
        params.search = {
          lookups: ['name__icontains'],
          query
        }
      }
      paginateCities<City>(params)?.then(callback)
    }, 200),
    [criteria.states]
  )

  const searchNeighborhoods = useCallback(
    debounce((query, callback) => {
      let params: EntityPaginateParams = {
        pager: {
          page: 1,
          pageSize: 10
        },
        filters: {
          city_id__in: criteria.cities.map((city) => city.id)
        }
      }

      if (!!query) {
        params.search = {
          lookups: ['name__icontains'],
          query
        }
      }
      paginateNeighborhoods<Neighborhood>(params)?.then(callback)
    }, 200),
    [criteria.cities]
  )

  useEffect(() => {
    searchCities(citySearch, (result: EntityPaginateResult<City>) => {
      !!result && setCities(result.list)
    })
  }, [citySearch, searchCities])

  useEffect(() => {
    searchNeighborhoods(
      neighborhoodSearch,
      (result: EntityPaginateResult<Neighborhood>) => {
        !!result && setNeighborhoods(result.list)
      }
    )
  }, [neighborhoodSearch, searchNeighborhoods])

  return (
    <Box p="30px">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...criteria,
          zipcode_range: criteria.zipcode_range ?? []
        }}
        validate={validate}
        validateOnBlur
        render={({ handleSubmit, values, form, errors }: any) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box
              sx={{
                marginBottom: '10px'
              }}
            >
              <>
                {/* <pre>{JSON.stringify(errors, undefined, 2)}</pre> */}
                {!!errors['undefined'] && (
                  <Typography
                    sx={{
                      fontSize: '14px',
                      marginLeft: 0,
                      marginTop: '5px',
                      marginBottom: '20px',
                      color: 'red'
                    }}
                  >
                    {errors['undefined']}
                  </Typography>
                )}
                <Autocomplete
                  disabled={!editable}
                  sx={{
                    '& label': {
                      color: 'rgba(0, 0, 0, 0.6) !important',
                      fontSize: '1rem !important'
                    },
                    '& legend': {
                      fontSize: '13px'
                    }
                  }}
                  name="states"
                  label="Lista de UF's"
                  helperText="(Opcional) - Selecione as UF's que deseja filtrar"
                  options={states}
                  fullWidth
                  multiple
                  aria-autocomplete="none"
                  textFieldProps={{
                    FormHelperTextProps: {
                      sx: {
                        fontSize: '12px',
                        marginLeft: 0,
                        marginTop: '5px'
                      }
                    },
                    'aria-autocomplete': 'none',
                    autoComplete: 'none',
                    InputProps: {
                      'aria-autocomplete': 'none',
                      autoComplete: 'none'
                    }
                  }}
                  getOptionLabel={(option) => option.code}
                  value={criteria.states || []}
                />
              </>

              {criteria.states.length > 0 && (
                <>
                  <Autocomplete
                    disabled={!editable}
                    sx={{
                      marginTop: '20px',
                      '& label': {
                        color: 'rgba(0, 0, 0, 0.6) !important',
                        fontSize: '1rem !important'
                      },
                      '& legend': {
                        fontSize: '13px'
                      }
                    }}
                    name="cities"
                    label="Lista de Cidades"
                    options={cities}
                    fullWidth
                    multiple
                    textFieldProps={{
                      FormHelperTextProps: {
                        sx: {
                          fontSize: '12px',
                          marginLeft: 0,
                          marginTop: '5px'
                        }
                      }
                    }}
                    helperText="(Opcional) - Selecione as cidades que deseja filtrar"
                    onInputChange={(event, value) => {
                      setCitySearch(sanitize.clean(value).toUpperCase())
                    }}
                    getOptionLabel={(option) =>
                      `${option.state.code} - ${option.name}`
                    }
                    value={criteria.cities || []}
                  />

                  {criteria.cities.length > 0 && (
                    <>
                      <Autocomplete
                        disabled={!editable}
                        sx={{
                          marginTop: '20px',
                          '& label': {
                            color: 'rgba(0, 0, 0, 0.6) !important',
                            fontSize: '1rem !important'
                          },
                          '& legend': {
                            fontSize: '13px'
                          }
                        }}
                        name="neighborhoods"
                        label="Lista de Bairros"
                        options={neighborhoods}
                        helperText="(Opcional) - Selecione os bairros que deseja filtrar"
                        fullWidth
                        multiple
                        textFieldProps={{
                          FormHelperTextProps: {
                            sx: {
                              fontSize: '12px',
                              marginLeft: 0,
                              marginTop: '5px'
                            }
                          }
                        }}
                        onInputChange={(event, value) => {
                          setNeighborhoodSearch(
                            sanitize.clean(value).toUpperCase()
                          )
                        }}
                        getOptionLabel={(option) =>
                          `${option.city.state.code} - ${option.city.name} - ${option.name}`
                        }
                        value={criteria.neighborhoods || []}
                      />
                    </>
                  )}
                </>
              )}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-end"
                justifyContent="flex-end"
                width="100%"
                marginTop="30px"
              >
                <Button
                  disabled={!editable}
                  variant="outlined"
                  sx={{ margin: '0' }}
                  size="small"
                  onClick={() => {
                    if (useZipcodeList) {
                      form.change('zipcode_range', [])
                    } else {
                      form.change('zipcodes', [])
                    }
                    setUseZipcodeList(!useZipcodeList)
                  }}
                >
                  {useZipcodeList
                    ? "Trocar para Intervalo de CEP's"
                    : "Trocar para Lista de CEP's"}
                </Button>
              </Box>
              {useZipcodeList ? (
                <TextField
                  disabled={!editable}
                  variant="outlined"
                  label={`Lista CEP's`}
                  name="zipcodes"
                  helperText={`(Opcional) - Adicione neste campo uma lista de CEP's válidos separados por vírgula. Ex.: 00000-000, 99999-999`}
                  multiline
                  rows={3}
                  spellCheck={false}
                  onBlur={(e) => {
                    form.change(
                      'zipcodes',
                      values.zipcodes.filter((cep: any) => !!cep)
                    )
                  }}
                  sx={{
                    marginTop: '10px'
                  }}
                  onPasteCapture={(e) => {
                    let pasted = e.clipboardData.getData('text')
                    pasted = pasted.replace(/\n/g, ',')
                    const parsed: string[] = pasted
                      .split(',')
                      .map((cep): string => {
                        let clean = cep.replace(/[^0-9]/g, '')
                        clean = clean.padStart(8, '0')
                        if (isValidCep(clean)) {
                          return clean.replace(/([0-9]{5})([0-9]{3})/, '$1-$2')
                        } else {
                          return ''
                        }
                      })
                      .filter((cep: any) => !!cep)

                    form.change('zipcodes', parsed)
                    e.preventDefault()
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '1rem !important'
                    }
                  }}
                  InputProps={{
                    sx: {
                      '& legend': {
                        fontSize: '13px'
                      }
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        variant="filled"
                        sx={{
                          position: 'absolute',
                          bottom: '-15px',
                          right: '0',
                          fontWeight: 'bold'
                        }}
                      >
                        {values?.zipcodes.length} cep(s) válido(s)
                      </InputAdornment>
                    )
                  }}
                  FormHelperTextProps={{
                    sx: {
                      fontSize: '12px',
                      marginLeft: 0,
                      marginTop: '5px'
                    }
                  }}
                  fieldProps={{
                    format: (value) => {
                      let commafied = value
                      if (typeof commafied === 'string') {
                        commafied = commafied.replace(/\n/g, ',')
                        commafied = commafied.split(',')
                      }

                      commafied = commafied
                        .map((cep: string) => {
                          let clean = cep.replace(/[^0-9]/g, '')
                          if (clean.length >= 8) {
                            if (isValidCep(clean)) {
                              return clean.replace(
                                /([0-9]{5})([0-9]{3})/,
                                '$1-$2'
                              )
                            } else {
                              return null
                            }
                          } else {
                            return cep
                          }
                        })
                        .filter(
                          (doc: any, index: number, self: any) =>
                            self.indexOf(doc) === index
                        )
                        .join(',')
                      return commafied
                    },
                    parse: (value) => {
                      let parsed = value
                      if (typeof parsed === 'string') {
                        parsed = parsed.replace(/\n/g, ',')
                        parsed = parsed
                          .split(',')
                          .map((cep: string) => cep.replace(/[^0-9]/g, ''))
                          .filter(
                            (doc: any, index: number, self: any) =>
                              self.indexOf(doc) === index
                          )

                        if (parsed.length === 1) {
                          parsed = parsed.filter((doc: any) => !!doc)
                        }
                      }

                      return parsed
                    }
                  }}
                />
              ) : (
                <>
                  <Fieldset legend="Intervalo de CEP's">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TextField
                        disabled={!editable}
                        variant="filled"
                        label={`CEP Inicial`}
                        name="zipcode_range[0]"
                        FormHelperTextProps={{
                          sx: {
                            display: 'none'
                          }
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: '1rem !important'
                          }
                        }}
                        onBlur={(e) => {
                          form.change(
                            'zipcode_range',
                            values.zipcode_range.filter((zip: any) => !!zip)
                          )
                        }}
                        InputProps={{
                          sx: {
                            '& legend': {
                              fontSize: '13px'
                            }
                          },
                          disableUnderline: true,
                          autoComplete: 'none'
                        }}
                        fieldProps={{
                          format: (value) => {
                            let commafied = String(value || '')
                            commafied = commafied.replace(/\n/g, '')
                            commafied = commafied.replace(/[^0-9]/g, '')
                            commafied = !!commafied
                              ? commafied.padStart(8, '0')
                              : ''

                            return commafied.replace(
                              /([0-9]{5})([0-9]{3})/,
                              '$1-$2'
                            )
                          },
                          parse: (value) => {
                            let parsed = String(value || '')
                            parsed = parsed.replace(/\n/g, ',')
                            return !!parsed
                              ? Number.parseInt(parsed.replace(/[^0-9]/g, ''))
                              : parsed
                          }
                        }}
                      />
                      <TextField
                        disabled={!editable}
                        variant="filled"
                        label={`CEP Final`}
                        name="zipcode_range[1]"
                        error={!!errors['zipcode_range']}
                        InputLabelProps={{
                          sx: {
                            fontSize: '1rem !important'
                          }
                        }}
                        FormHelperTextProps={{
                          sx: {
                            display: 'none'
                          }
                        }}
                        InputProps={{
                          sx: {
                            '& legend': {
                              fontSize: '13px'
                            }
                          },
                          disableUnderline: true,
                          autoComplete: 'none'
                        }}
                        fieldProps={{
                          format: (value) => {
                            let commafied = String(value || '')
                            commafied = commafied.replace(/\n/g, '')
                            commafied = commafied.replace(/[^0-9]/g, '')
                            commafied = !!commafied
                              ? commafied.padStart(8, '0')
                              : ''
                            return commafied.replace(
                              /([0-9]{5})([0-9]{3})/,
                              '$1-$2'
                            )
                          },
                          parse: (value) => {
                            let parsed = String(value || '')
                            parsed = parsed.replace(/\n/g, ',')
                            return !!parsed
                              ? Number.parseInt(parsed.replace(/[^0-9]/g, ''))
                              : parsed
                          }
                        }}
                      />
                    </Stack>
                  </Fieldset>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      marginLeft: 0,
                      marginTop: '5px',
                      color: !!errors['zipcode_range']
                        ? 'red'
                        : 'rgba(0, 0, 0, 0.6) !important'
                    }}
                  >
                    {!!errors['zipcode_range'] ? (
                      <>{errors['zipcode_range']}</>
                    ) : (
                      <>
                        (Opcional) - Informe um intervalo de CEP's para filtrar
                        os registros
                      </>
                    )}
                  </Typography>
                </>
              )}
            </Box>
            {/* <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
            <FormSpy
              subscription={{ values: true, hasValidationErrors: true }}
              onChange={({ values, hasValidationErrors }) => {
                values.states_ids = (
                  values as AddressDetailsFilterCriteria
                ).states.map((entry) => entry.code)
                values.cities_ids = (
                  values as AddressDetailsFilterCriteria
                ).cities.map((entry) => entry.id)
                values.neighborhoods_ids = (
                  values as AddressDetailsFilterCriteria
                ).neighborhoods.map((entry) => entry.id)
                onChange(
                  values as AddressDetailsFilterCriteria,
                  !hasValidationErrors
                )
              }}
            />
          </form>
        )}
      />
    </Box>
  )
}

export default EditAddressDetailsFilterCriteria
