import { Extraction } from './Extraction'
import { FilterCriteria } from './FilterCriteria'

export enum FilterType {
  NATURAL_PERSONS = 'NaturalPersonFilter',
  LEGAL_PERSONS = 'LegalPersonFilter'
}

export type Filter = {
  id: number
  count: number
  is_counted: boolean
  is_counting: boolean
  count_error: string
  has_error: boolean
  criteria: FilterCriteria[]
  unique_contacts_only: boolean
  extraction?: Extraction
  type: FilterType
  user: number
}
