import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import { Chip, Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { AreaCode } from '../../../types/AreaCode'
import { FilterType } from '../../../types/Filter'
import { PhoneDetailsFilterCriteria } from '../../../types/PhoneDetailsFilterCriteria'
import { PhoneOperator } from '../../../types/PhoneOperator'

export type PhoneDetailsFilterCriteriaListItemProps = {
  criteria: PhoneDetailsFilterCriteria
  filter_type: FilterType
}

const PhoneDetailsFilterCriteriaListItem = ({
  criteria,
  filter_type
}: PhoneDetailsFilterCriteriaListItemProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        minWidth="260px"
        sx={{
          backgroundColor:
            filter_type === FilterType.NATURAL_PERSONS ? '#1976d2' : '#9c27b0',
          minHeight: '50px',
          padding: '10px 20px 10px 10px',
          borderRadius: '10px 0 0 10px',
          borderRight: '1px solid #b4b4b4'
        }}
      >
        <ContactPhoneIcon htmlColor="#fff" fontSize="large" />
        <Typography fontWeight="bold" textTransform="uppercase" color="#fff">
          Filtro de Telefonia
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <>
          <Typography fontWeight="bold">Tipo de Telefone:</Typography>
          <Typography>{criteria.phone_type_display}</Typography>
        </>
        {criteria.areacodes.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold">Lista de DDDs:</Typography>
            {criteria.areacodes
              .slice(0, 5)
              .map((entry: AreaCode, index: number, self: any) => {
                return (
                  <Typography key={entry.code}>
                    {entry.code}
                    {index < self.length - 1 && <>, </>}
                  </Typography>
                )
              })}
            {criteria.areacodes.length > 5 && (
              <Chip
                label={`+ ${criteria.areacodes.length - 5}`}
                variant="filled"
                sx={{
                  fontWeight: 'bold',
                  marginLeft: '10px'
                }}
              />
            )}
          </>
        )}
        {criteria.operators.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography fontWeight="bold">Lista de Operadoras:</Typography>
            {criteria.operators
              .slice(0, 3)
              .map((entry: PhoneOperator, index: number, self: any) => {
                return (
                  <Typography key={entry.name}>
                    {entry.name}
                    {index < self.length - 1 && <>, </>}
                  </Typography>
                )
              })}
            {criteria.operators.length > 3 && (
              <Chip
                label={`+ ${criteria.operators.length - 3}`}
                variant="filled"
                sx={{
                  fontWeight: 'bold',
                  marginLeft: '10px'
                }}
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default PhoneDetailsFilterCriteriaListItem
