import { FilterCriteria } from './FilterCriteria'

export enum PersonalDetailsFilterCriteriaGender {
  GENDER_MALE = 'M',
  GENDER_FEMALE = 'F'
}

export enum PersonalDetailsFilterCriteriaStatus {
  STATUS_NULL = '1',
  STATUS_ACTIVE = '2',
  STATUS_SUSPENDED = '3',
  STATUS_UNFIT = '4',
  STATUS_CLOSED = '5'
}

export enum PersonalDetailsFilterCriteriaNamesMatchStrategy {
  NAMES_MATCH_STRATEGY_EXACT = 'exact',
  NAMES_MATCH_STRATEGY_STARTS_WITH = 'starts_with',
  STATUS_SUNAMES_MATCH_STRATEGY_CONTAINSSPENDED = 'contains'
}

export const STATUSES = [
  { value: PersonalDetailsFilterCriteriaStatus.STATUS_NULL, label: 'Nula' },
  { value: PersonalDetailsFilterCriteriaStatus.STATUS_ACTIVE, label: 'Ativa' },
  {
    value: PersonalDetailsFilterCriteriaStatus.STATUS_SUSPENDED,
    label: 'Suspensa'
  },
  { value: PersonalDetailsFilterCriteriaStatus.STATUS_UNFIT, label: 'Inapta' },
  { value: PersonalDetailsFilterCriteriaStatus.STATUS_CLOSED, label: 'Baixada' }
]

export const NAMES_MATCH_STRATEGIES = [
  {
    value:
      PersonalDetailsFilterCriteriaNamesMatchStrategy.NAMES_MATCH_STRATEGY_EXACT,
    label: 'Exatamente iguais'
  },
  {
    value:
      PersonalDetailsFilterCriteriaNamesMatchStrategy.NAMES_MATCH_STRATEGY_STARTS_WITH,
    label: 'Iniciam com'
  },
  {
    value:
      PersonalDetailsFilterCriteriaNamesMatchStrategy.STATUS_SUNAMES_MATCH_STRATEGY_CONTAINSSPENDED,
    label: 'Contém'
  }
]

export type PersonalDetailsFilterCriteria = FilterCriteria & {
  names: string[]
  gender?: PersonalDetailsFilterCriteriaGender
  names_match_strategy?: PersonalDetailsFilterCriteriaNamesMatchStrategy
  age_range?: number[]
  cnaes: any[]
  cnaes_ids: number[]
  legal_natures: any[]
  legal_natures_ids: number[]
  tax_regimes: any[]
  tax_regimes_ids: number[]
  company_sizes: any[]
  company_sizes_ids: number[]
  statuses?: any[]
  has_email?: boolean
  is_mei?: boolean
  is_simples?: boolean
  presumed_revenue_range: number[]
  presumed_revenue_ranges: any[]
  presumed_revenue_ranges_ids: number[]
  cbos: any[]
  cbo_ids: number[]
}
