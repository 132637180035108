import { FilterCriteria } from './FilterCriteria'

export enum PhoneDetailsFilterCriteriaType {
  PHONE_TYPE_MOBILE = 'mobile',
  PHONE_TYPE_LANDLINE = 'landline',
  PHONE_TYPE_BOTH = 'both',
  PHONE_TYPE_ANY = 'any'
}

export type PhoneDetailsFilterCriteria = FilterCriteria & {
  phone_type: PhoneDetailsFilterCriteriaType
  phone_type_display?: string
  operators: any[]
  areacodes: any[]
  operators_ids?: number[]
  areacodes_ids?: number[]
}
